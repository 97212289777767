import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import {
  Size as ContainerSize,
} from "apps/website/components/layout/Container/Container.map";
import Container from "apps/website/components/layout/Container/Container";

export interface IFreeFlowImage {
  image: IImageProps;
  alt?: string;
  container?: ContainerSize,
}

const FreeFlowImage: FC<IFreeFlowImage> = ({ image, alt, container = "default" }) => (
  <div data-component={FreeFlowImage.name}>
    <Container size={container}>
      <Image image={image} alt={alt ?? image.alt ?? ""} />
    </Container>
  </div>
);

export default FreeFlowImage;
