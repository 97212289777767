import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";

import SectionTagList from "../../section/SectionTagList/SectionTagList";
import { Display } from "../../base/Text/Text.map";

interface IStoryBlokSectionTagListItem {
  tag_text: string;
  tag_theme: Theme;
  text: string;
  display: Display;
}

export interface IStoryBlokTagList {
  items: IStoryBlokSectionTagListItem[];
}

interface IStoryBlokSectionTagList {
  tag_list: [IStoryBlokTagList];
}

export const StoryBlokTagList = ({ blok }: IStoryBlokComponent<IStoryBlokSectionTagList>) => (
  <SectionTagList
    items={blok.tag_list[0].items.map((item) => ({
      tag: {
        children: item.tag_text,
        theme: item.tag_theme || "green",
        align: "center",
      },
      text: item.text,
    }))}
  />
);
