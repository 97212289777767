import { FC } from "react";

import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import Splash from
  "apps/website/components/section/SectionSplash/SectionSplash";
import {
  ISplashSubtitle,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";
import { Theme } from "apps/website/maps/Theme.map";

export interface ISectionTextSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  body?: string;
  ctas?: ILink[];
  theme?: Theme;
}

const SectionTextSplash: FC<ISectionTextSplash> = ({ title, subtitle, ctas, body, theme = "default" }) => (
  <Splash
    component="SectionTextSplash"
    title={title}
    subtitle={subtitle}
    body={body}
    theme={ theme }
    ctas={ctas}
    size="auto"
    className="![--color-text-secondary:var(--color-text-default)]"
  />
);

export default SectionTextSplash;
