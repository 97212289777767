import { FC } from "react";

export interface IYoutube {
  id: string;
  videoTitle?: string;
}

const Youtube: FC<IYoutube> = ({ id, videoTitle = "Youtube video" }) => (
  <div data-component={Youtube.name} className="relative w-full pb-[56.25%]">
    <iframe
      className="w-full h-full absolute top-0 left-0 bottom-0"
      src={`https://www.youtube.com/embed/${id}`}
      title={videoTitle}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
    />
  </div>
);

export default Youtube;
