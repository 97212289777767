export const BRANDS = {
  KATKIN: "KatKin",
  LILYS_KITCHEN: "Lily's Kitchen",
  GORMET: "Purina Gourmet",
  SHEBA: "Sheba",
  WHISKAS: "Whiskas",
  FELIX: "Purina Felix",
};

export const BRAND_IMAGES: Record<keyof typeof BRANDS, string> = {
  KATKIN: "/images/competitors/logos/katkin.webp",
  LILYS_KITCHEN: "/images/competitors/logos/lilys-kitchen.webp",
  GORMET: "/images/competitors/logos/purina-gormet.webp",
  SHEBA: "/images/competitors/logos/sheba.webp",
  WHISKAS: "/images/competitors/logos/whiskas.webp",
  FELIX: "/images/competitors/logos/purina-felix.webp",
};

export const BRAND_TITLES: Record<string, keyof typeof BRAND_IMAGES> = {
  "[[brand:katkin]]": "KATKIN",
  "[[brand:lilysKitchen]]": "LILYS_KITCHEN",
  "[[brand:gormet]]": "GORMET",
  "[[brand:sheba]]": "SHEBA",
  "[[brand:whiskas]]": "WHISKAS",
  "[[brand:felix]]": "FELIX",
};
