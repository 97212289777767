import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import ConditionalCheckList, {
  IConditionalCheckListProps,
} from "../../feature/ConditionalCheckList/ConditionalCheckList";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Spacer from "../../layout/Spacer/Spacer";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

export interface ISectionConditionalCheckListProps {
  title: string;
  checklist: IConditionalCheckListProps;
  theme?: Theme;
}

const SectionConditionalCheckList: FC<ISectionConditionalCheckListProps> = ({ title, checklist, theme = "default" }) => (
  <Section component={SectionConditionalCheckList.name} theme={theme} size={theme !== "default" ? "xl" : "none"}>
    <Container size="sm">
      <Grid>
        <Column align="center">
          <Text tag="h2" display="subtitle" size={legacySizeCollectionMap.titleSm} align="center" data-testid="SectionConditionalCheckListTitle">
            { title }
          </Text>
          <Spacer size="lg" />
          <div className="w-fit">
            <ConditionalCheckList {...checklist} />
          </div>
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionConditionalCheckList;
