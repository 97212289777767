import { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import Youtube, {
  IYoutube,
} from "apps/website/components/feature/Youtube/Youtube";
import { Theme } from "apps/website/maps/Theme.map";

export interface ISectionYoutube extends IYoutube {
  theme?: Theme
}

const SectionYoutube: FC<ISectionYoutube> = ({ id, videoTitle, theme = "default" }) => (
  <Section component="SectionYoutube" theme={theme}>
    <Container>
      <Youtube
        id={id}
        videoTitle={videoTitle}
      />
    </Container>
  </Section>
);

export default SectionYoutube;
