import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import ImageAccordionList, {
  IImageAccordionList,
} from "apps/website/components/feature/ImageAccordionList/ImageAccordionList";
import Text from "apps/website/components/base/Text/Text";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import {
  borderlessSectionBackgroundClassMap,
} from "../SectionIconTextList/Section.map";

export interface ISectionImageAccordionListSection extends IImageAccordionList {
  theme?: Theme;
}

const SectionImageAccordionList: FC<ISectionImageAccordionListSection> = ({ title, body, image, direction = "default", accordionList, theme = "default" }) => {

  const isThemeDefault = theme === "default";

  return (
    <Section component="SectionImageAccordionList" size="none">
      <Container theme={theme} className={`${themeRootClassMap[theme]} ${borderlessSectionBackgroundClassMap[theme]}`} padding={!isThemeDefault ? "large" : "default"}>
        { !isThemeDefault && (
          <Spacer size="xl"/>
        ) }
        <Grid>
          <Column>
            <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">{ title }</Text>
            <Spacer size="xl" />
          </Column>
        </Grid>
        <ImageAccordionList
          title={title}
          body={body}
          image={image}
          direction={direction}
          accordionList={accordionList}
        />
        { !isThemeDefault && (
          <Spacer size="xl"/>
        ) }
      </Container>
    </Section>
  );
};

export default SectionImageAccordionList;
