import { FC, ReactNode } from "react";

import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

interface IFieldsetContainer {
  themeClass?: string;
  className?: string;
  children: ReactNode;
  id?: string;
}

export const FieldsetBackgroundScreen: FC<IFieldsetContainer> = ({ themeClass, className, children, id }) => (
  <>
    <Grid id={id} component={FieldsetBackgroundScreen.name} className={`container mx-auto relative ${themeClass} ${className}`}>
      <>
        <div className={`${themeClass} w-[200vw] h-full absolute top-0 left-1/2 -translate-x-1/2 z-0`}></div>
        <Column spans={{ lg: 6 }} offset={{ lg: 4 }} className="relative">
          <>
            <Spacer size="xl" />
            { children }
          </>
        </Column>
      </>
    </Grid>
  </>
);
