import { FC } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import Container from "apps/website/components/layout/Container/Container";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import RowColumnTable, {
  IRowColumnTable,
} from "apps/website/components/feature/Table/RowColumnTable/RowColumnTable";
import { Theme } from "apps/website/maps/Theme.map";
import Button from "apps/website/components/base/Button/Button";

export interface ISectionRowColumnTable {
  title: string;
  table: IRowColumnTable;
  cta?: ILink[];
  source?: string;
  theme?: Theme;
}

const SectionRowColumnTable: FC<ISectionRowColumnTable> = ({ title, table, cta, source, theme = "light-grey" }) => (
  <Section component="SectionRowColumnTable" theme={theme}>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
            { title }
          </Text>
          <Spacer size="2xl" />
        </>
      ) }
      <div className="overflow-x-auto w-full">
        <Grid>
          <Column justify={{ md: "center" }} align={{ md: "center" }}>
            <RowColumnTable titles={table.titles} rows={table.rows} />
          </Column>
          <>
            { source && (
              <>
                <Spacer size="md" />
                <Grid>
                  <Column>
                    <Text>
                      <Text tag="span" display="subtitle">Source:</Text>
                    &nbsp;{ source }
                    </Text>
                  </Column>
                </Grid>
              </>
            ) }
          </>
        </Grid>
      </div>
      { cta && (
        <>
          <Spacer size="xl" />
          <Grid>
            <Column justify="center" align="center" direction={{ default: "column", lg: "row" }}>
              { cta.map((ctaButton) => (
                <Button
                  key={ctaButton.id}
                  to={ctaButton.to}
                  design={ctaButton.design}
                  color={ctaButton.color}
                  data-testid={ctaButton.testId}
                  className="mb-4 last:mb-0 lg:mb-0 lg:mr-4 lg:last:mr-0"
                >
                  { ctaButton.name }
                </Button>
              )) }
            </Column>
          </Grid>
        </>
      ) }
    </Container>
  </Section>
);

export default SectionRowColumnTable;
