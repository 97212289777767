import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { rotationMap } from "apps/website/maps/Rotation.map";

import Image, { IImageProps } from "../../../base/Image/Image";
import Text from "../../../base/Text/Text";
import { legacySizeCollectionMap } from "../../../base/Text/Text.map";

export interface IQuoteCalloutSecondary {
  quote: string;
  author: string;
  image: IImageProps;
  imageTheme?: Theme;
  theme?: Theme;
}

const QuoteCalloutSecondary: FC<IQuoteCalloutSecondary> = ({ quote, author, image, theme = "green", imageTheme }) => (
  <div data-component={QuoteCalloutSecondary.name} className={"w-full p-2"}>
    <figure>
      <div className="flex flex-col w-full relative">
        <div className={`items-center justify-center aspect-[3/4] w-20 flex-none absolute left-0 z-10 top-1/2 transform -translate-y-[calc(50%+8px)] ${rotationMap[-2]} ${themeRootClassMap[imageTheme ?? "default"]}`}>
          <Image image={image} alt={image.alt ?? ""} className={"absolute bottom-0 left-0"}/>
          <Image image={{
            src: "/images/features/team/frame.png",
            height: 232,
            width: 183,
          }} alt=""
          className="absolute top-0 left-0"
          />
        </div>
        <div data-theme={theme} className={`h-fit w-full pt-4 pr-4 pl-24 pb-6 ${themeRootClassMap[theme]}`}>
          <Text tag="figcaption" size={legacySizeCollectionMap.titleSm} display="subtitle">{ author }</Text>
        </div>
      </div>
      <div data-theme="light" className={`px-4 pt-6 pb-6 border-2 border-solid transform rotate-1 -translate-y-2 flex justify-between ${themeRootClassMap.light}`}>
        <Text tag="span" display="title" size={legacySizeCollectionMap.titleLg} className="transform -translate-y-4">&ldquo;</Text>
        <Text tag="blockquote" size={legacySizeCollectionMap.bodySm} className="pb-0 px-4 w-fit">{ quote }</Text>
        <Text tag="span" display="title" size={legacySizeCollectionMap.titleLg} className="transform translate-y-4 self-end">&rdquo;</Text>
      </div>
    </figure>
  </div>
);

export default QuoteCalloutSecondary;
