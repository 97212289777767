import { Theme } from "apps/website/maps/Theme.map";
import {
  getStoryBlokAccordions,
} from "apps/website/utils/storyblok/accordions";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  IRichTextOptions,
  renderStoryBlokRichText,
} from "apps/website/utils/storyblok/text";
import {
  Direction,
} from "apps/website/components/feature/ImageAccordionList/ImageAccordionList";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import ImageAccordionList from
  "apps/website/components/section/SectionImageAccordionList/SectionImageAccordionList";

import { AccordionType } from "../../feature/Accordion/Accordion.map";

// TODO: Move and combine with IStoryBlokFaq
export interface IStoryBlokAccordion {
  title: string;
  content: string;
  theme: Theme;
  design?: AccordionType;
}

export interface IImagePropsAccordionList {
  title: string;
  body: string;
  accordion_list: IStoryBlokAccordion[];
  image: IStoryBlokImage;
}

export interface IStoryBlokImageAccordionList {
  image_accordion_list: [IImagePropsAccordionList]
  direction: Direction
  theme: Theme;
  richtextOptions?: IRichTextOptions
}

export const StoryBlokImageAccordionList = (
  { blok }: IStoryBlokComponent<IStoryBlokImageAccordionList>,
) => (
  <>
    { blok.image_accordion_list[0] && (
      <ImageAccordionList
        title={blok.image_accordion_list[0].title}
        body={renderStoryBlokRichText(blok.image_accordion_list[0].body, blok.richtextOptions)}
        accordionList={getStoryBlokAccordions(blok.image_accordion_list[0].accordion_list, blok.richtextOptions)}
        image={getStoryBlokImage(blok.image_accordion_list[0].image, true, ImageMax.HALF_CONTAINER_2XL)}
        direction={blok.direction}
        theme={blok.theme}
      />
    ) }
  </>
);
