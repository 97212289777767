import { FC, ReactNode } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

export interface ISectionHorizontalRuleProps {
  children: ReactNode;
  theme: Theme;
}

const SectionHorizontalRule: FC<ISectionHorizontalRuleProps> = ({ children, theme }) => (
  <Section component={SectionHorizontalRule.name} theme={theme} size="none">
    <Container>
      <Grid>
        <Column justify="center" align="center" className="relative" spans={{ lg: 6 }} offset={{ lg: 4 }}>
          <div className="w-full h-1 bg-[var(--color-text-default)] absolute left-0 transform top-1/2 -translate-y-1/2"></div>
          <Text
            size={legacySizeCollectionMap.titleSm}
            display="subtitle"
            className={`p-2 relative z-10 ${themeRootClassMap[theme]}`}
          >
            { children }
          </Text>
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionHorizontalRule;
