import { SbReactComponentsMap } from "@storyblok/react";

import {
  StoryBlokColorSplash,
  StoryBlokHeroSplash,
  StoryBlokImageSplash,
  StoryBlokPreCancellationSplash,
  StoryBlokTextSplash,
  StoryBlokVideoSplash,
} from "apps/website/components/storyblok/sections/StoryBlokSplash";
import {
  StoryBlokCarouselArticleCards,
  StoryBlokCarouselReviewExternal,
  StoryBlokCarouselTestimonials,
  StoryBlokCarouselTrustPilot,
  StoryBlokCarouselCondensedImageCards,
  StoryBlokCarouselFreshPlanOverview,
} from "apps/website/components/storyblok/sections/StoryBlokCarousel";
import {
  StoryBlokImageText,
} from "apps/website/components/storyblok/sections/StoryBlokImageText";
import {
  StoryBlokImageTextList,
} from "apps/website/components/storyblok/sections/StoryBlokImageTextList";
import {
  StoryBlokFeatureWrapper,
  StoryBlokFeatureWrapperGroup,
} from "apps/website/components/storyblok/sections/StoryBlokFeatureWrapper";
import {
  StoryBlokArticleRichText,
} from "apps/website/components/storyblok/article/StoryBlokArticleRichText";
import {
  StoryBlokArticleImage,
} from "apps/website/components/storyblok/article/StoryBlokArticleImage";
import {
  StoryBlokArticlesCarouselRelatedArticles,
} from "apps/website/components/storyblok/sections/articles/StoryBlokArticleCarousel";
import {
  StoryBlokArticleTitle,
} from "apps/website/components/storyblok/article/StoryBlokArticleTitle";
import {
  StoryBlokArticleYoutube,
} from "apps/website/components/storyblok/article/StoryBlokArticleYoutube";
import {
  StoryBlokHardcoreTruth,
} from "apps/website/components/storyblok/sections/StoryBlokHardcoreTruth";
import {
  StoryBlokSplitSplash,
} from "apps/website/components/storyblok/sections/StoryBlokSplitSplash";
import {
  StoryBlokTeamCarousel,
} from "apps/website/components/storyblok/sections/StoryBlokTeamMemberCarousel";
import {
  StoryBlokProductList,
} from "apps/website/components/storyblok/sections/StoryBlokProductList";
import {
  StoryBlokImageAccordionList,
} from "apps/website/components/storyblok/sections/StoryBlokImageAccordionList";
import {
  StoryBlokStoryBlokStickyNoteList,
} from "apps/website/components/storyblok/sections/StoryBlokStickyNoteList";
import {
  StoryBlokTrustPilotReviewWall,
} from "apps/website/components/storyblok/sections/StoryBlokTrustPilotReviewWall";
import {
  StoryBlokAdvert,
} from "apps/website/components/storyblok/sections/StoryBlokAdvert";
import {
  StoryBlokAnimatedSplit,
} from "apps/website/components/storyblok/sections/StoryBlokAimatedSplit";
import {
  StoryBlokBrandComparisonTable,
} from "apps/website/components/storyblok/sections/StoryBlokBrandComparisonTable";
import {
  StoryBlokHoverList,
} from "apps/website/components/storyblok/sections/StoryBlokHoverList";
import {
  StoryBlokCondensedList,
} from "apps/website/components/storyblok/sections/StoryBlokCondensedList";
import {
  StoryBlokQuote,
} from "apps/website/components/storyblok/sections/StoryBlokQuote";
import {
  StoryBlokLabelText,
} from "apps/website/components/storyblok/sections/StoryBlokLabelText";
import {
  StoryBlokIconVideo,
} from "apps/website/components/storyblok/sections/StoryBlokIconVideo";
import {
  StoryBlokIconTextList,
} from "apps/website/components/storyblok/sections/StoryBlokIconTextList";
import {
  StoryBlokReviewOverview,
} from "apps/website/components/storyblok/sections/StoryBlokReviewOverview";
import {
  StoryBlokColumnTable,
} from "apps/website/components/storyblok/sections/StoryBlokColumnTable";
import {
  StoryBlokRowColumnTable,
} from "apps/website/components/storyblok/sections/StoryBlokRowColumnTable";
import {
  StoryBlokFreeFlow,
} from "apps/website/components/storyblok/sections/StoryBlokFreeFlow";
import {
  StoryBlokSpacer,
} from "apps/website/components/storyblok/sections/StoryBlokSpacer";
import {
  StoryBlokSocialFeed,
} from "apps/website/components/storyblok/sections/StoryBlokSocialFeed";
import Page from "apps/website/components/storyblok/page";
import Article from "apps/website/components/storyblok/article";
import Customer from "apps/website/components/storyblok/customer";
import {
  MentionMeLanding,
} from "apps/website/components/integrations/mentionme/MentionMeLanding";
import {
  StoryBlokCatList,
} from "apps/website/components/storyblok/sections/StoryBlokCatList";
import {
  StoryBlokNewsletterSignUp,
} from "apps/website/components/storyblok/sections/StoryBlokNewsletterSignUp";

import SectionDiscountCodeContent from "../../section/SectionDiscountCodeContent/SectionDiscountCodeContent";

import { StoryBlokYoutube } from "./StoryBlokYoutube";
import { StoryBlokSectionImage } from "./StoryBlokImage";
import {
  StoryBlokReviewTrustpilotFeature,
} from "./StoryBlokReviewTrustpilotFeature";
import {
  StoryBlokBatchTestResultLookup,
} from "./StoryBlokBatchTestResultLookup";
import { StoryBlokAccordion } from "./StoryBlokAccordion";
import { StoryBlokAccordionList } from "./StoryBlokAccordionList";
import { StoryBlokTrustPilotReviewForm } from "./StoryBlokTrustPilotReviewForm";
import { StoryBlokStickyCTA } from "./StoryBlokStickyCTA";
import { StoryBlokOneClickPurchase } from "./StoryBlokOneClickPurchase";
import { StoryBlokOneClickPurchaseB } from "./StoryBlokOneClickPurchaseB";
import { StoryBlokQuoteCallout } from "./StoryBlokQuoteCallout";
import { StoryBlokIconCallout } from "./StoryBlokIconCallout";
import { StoryBlokImageCondensedList } from "./StoryBlokImageCondensedList";
import { StoryBlokInstructionCardList } from "./StoryBlokInstructionCardList";
import { StoryBlokSummaryFuture } from "./StoryBlokSummaryFuture";
import { StoryBlokMicroGallery } from "./StoryBlokMicroGallery";
import {
  StoryBlokAddProductCardPreviewList,
} from "./StoryBlokAddProductCardPreviewList";
import { StoryBlokQueryStringBanner } from "./StoryBlokQueryStringBanner";
import { StoryBlokConditionalCheckList } from "./StoryBlokConditionalCheckList";
import {
  StoryBlokStoryBlokComparisonCardList,
} from "./StoryBlokComparisonCardList";
import { StoryBlokTagList } from "./StoryBlokTagList";
import { StoryBlokClusterTagList } from "./StoryBlokClusterTagList";
import { StoryBlokCondensedReviewList } from "./StoryBlokCondensedReviewList";
import { StoryBlokAlertPrompt } from "./StoryBlokAlertPrompt";
import { StoryBlokHorizontalRule } from "./StoryBlokHorizontalRule";
import { StoryBlokWarningCard } from "./StoryBlokWarningCard";
import { StoryBlokImageTagList } from "./StoryBlokImageTagList";
import {
  StoryBlokFoodPortionCalculator,
} from "./StoryBlokFoodPortionCalculator";
import { StoryBlokEmpty } from "./StoryBlokEmpty";
import { StoryBlokPriceCalculator } from "./StoryBlokPriceCalculator";
import { StoryBlokProductOverviewTabs } from "./StoryBlokProductOverviewTabs";
import { StoryBlokStepByStep } from "./StoryBlokStepByStep";
import { StoryBlokKatkinVsCompetitor } from "./StoryBlokKatkinVersesCompetitor";
import { StoryBlokArrow } from "./StoryBlokSectionArrow";
import { StoryBlokStandAloneReview } from "./StoryBlokStandAloneReview";
import { StoryBlokLogo } from "./StoryBlokLogo";
import { StoryBlokTimeToComplete } from "./StoryBlokTimeToComplete";
import { StoryBlokHighlighterCallout } from "./StoryBlokHighlighterCallout";
import { StoryBlokDivider } from "./StoryBlokSectionDivider";
import { StoryBlokImageCarousel } from "./StoryBlokImageCarousel";

export const components: SbReactComponentsMap = {
  article: Article,
  article_image: StoryBlokArticleImage,
  article_rich_text: StoryBlokArticleRichText,
  article_title: StoryBlokArticleTitle,
  article_youtube: StoryBlokArticleYoutube,
  customer: Customer,
  newsletter_sign_up_form: StoryBlokNewsletterSignUp,
  page: Page,
  section_accordion: StoryBlokAccordion,
  section_accordion_list: StoryBlokAccordionList,
  section_add_product_card_preview_list: StoryBlokAddProductCardPreviewList,
  section_advert: StoryBlokAdvert,
  section_animated_split: StoryBlokAnimatedSplit,
  section_alert_prompt: StoryBlokAlertPrompt,
  section_arrow: StoryBlokArrow,
  section_articles_carousel_related_articles:
    StoryBlokArticlesCarouselRelatedArticles, // Different defaults
  section_batch_testing_result_lookup: StoryBlokBatchTestResultLookup,
  section_brand_comparison_table: StoryBlokBrandComparisonTable,
  section_carousel_article_cards: StoryBlokCarouselArticleCards,
  section_carousel_review_external: StoryBlokCarouselReviewExternal,
  section_carousel_review_testimonials: StoryBlokCarouselTestimonials,
  section_carousel_review_trust_pilot: StoryBlokCarouselTrustPilot,
  section_condensed_image_card_carousel: StoryBlokCarouselCondensedImageCards,
  section_fresh_plan_overview_carousel: StoryBlokCarouselFreshPlanOverview,
  section_carousel_team_members: StoryBlokTeamCarousel,
  section_cat_list: StoryBlokCatList,
  section_cluster_tag_list: StoryBlokClusterTagList,
  section_column_table: StoryBlokColumnTable,
  section_comparison_card_list: StoryBlokStoryBlokComparisonCardList,
  section_condensed_list: StoryBlokCondensedList,
  section_condensed_review_list: StoryBlokCondensedReviewList,
  section_conditional_check_list: StoryBlokConditionalCheckList,
  section_discount_code_content: SectionDiscountCodeContent,
  section_feature_wrapper: StoryBlokFeatureWrapper,
  section_feature_wrapper_group: StoryBlokFeatureWrapperGroup,
  section_free_flow: StoryBlokFreeFlow,
  section_highlighter_callout: StoryBlokHighlighterCallout,
  section_hover_list: StoryBlokHoverList,
  section_horizontal_rule: StoryBlokHorizontalRule,
  section_icon_callout: StoryBlokIconCallout,
  section_icon_text_list: StoryBlokIconTextList,
  section_icon_video: StoryBlokIconVideo,
  section_image: StoryBlokSectionImage,
  section_image_accordion_list: StoryBlokImageAccordionList,
  section_image_condensed_list: StoryBlokImageCondensedList,
  section_image_carousel: StoryBlokImageCarousel,
  section_image_text: StoryBlokImageText,
  section_image_text_list: StoryBlokImageTextList,
  section_image_tag_list: StoryBlokImageTagList,
  section_instruction_card_list: StoryBlokInstructionCardList,
  section_katkin_vs_competitor: StoryBlokKatkinVsCompetitor,
  section_label_text: StoryBlokLabelText,
  section_logo: StoryBlokLogo,
  section_mentionme_landing: MentionMeLanding,
  section_micro_gallery: StoryBlokMicroGallery,
  section_one_click_purchase: StoryBlokOneClickPurchase,
  section_one_click_purchase_b: StoryBlokOneClickPurchaseB,
  section_price_calculator: StoryBlokPriceCalculator,
  section_product_list: StoryBlokProductList,
  section_product_overview_tabs: StoryBlokProductOverviewTabs,
  section_quote: StoryBlokQuote,
  section_quote_callout: StoryBlokQuoteCallout,
  section_query_string_banner: StoryBlokQueryStringBanner,
  section_retail_portion_calculator: StoryBlokFoodPortionCalculator,
  section_review_overview: StoryBlokReviewOverview,
  section_review_trustpilot_feature: StoryBlokReviewTrustpilotFeature,
  section_row_column_table: StoryBlokRowColumnTable,
  section_social_feed: StoryBlokSocialFeed,
  section_spacer: StoryBlokSpacer,
  section_splash_color: StoryBlokColorSplash,
  section_splash_hero: StoryBlokHeroSplash,
  section_splash_image: StoryBlokImageSplash,
  section_splash_pre_cancellation: StoryBlokPreCancellationSplash,
  section_splash_text: StoryBlokTextSplash,
  section_splash_video: StoryBlokVideoSplash,
  section_split_splash: StoryBlokSplitSplash,
  section_stand_alone_review: StoryBlokStandAloneReview,
  section_step_by_step: StoryBlokStepByStep,
  section_sticky_cta: StoryBlokStickyCTA,
  section_sticky_note_list: StoryBlokStoryBlokStickyNoteList,
  section_summary_future: StoryBlokSummaryFuture,
  section_tag_list: StoryBlokTagList,
  section_time_to_complete: StoryBlokTimeToComplete,
  section_trust_pilot_review_form: StoryBlokTrustPilotReviewForm,
  section_trust_pilot_review_wall: StoryBlokTrustPilotReviewWall,
  section_truth: StoryBlokHardcoreTruth,
  section_warning_card: StoryBlokWarningCard,
  section_youtube: StoryBlokYoutube,
  collection: StoryBlokEmpty,
  product: StoryBlokEmpty,
  section_divider: StoryBlokDivider,
};
