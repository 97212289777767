import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import SectionIconCallout from "apps/website/components/section/SectionIconCallout/SectionIconCallout";
import {
  IRichTextOptions,
  renderStoryBlokRichText,
} from "apps/website/utils/storyblok/text";

import { ImageSize } from "../../feature/IconCallout/IconCallout.map";
import {
  TIconCalloutWidth,
} from "../../section/SectionIconCallout/SectionIconCallout.map";

export interface IStoryBlokIconCalloutCallout {
  title: string;
  body: string;
  image: IStoryBlokImage;
  image_size: ImageSize;
  theme: Theme;
}

export interface IStoryBlokIconCallout {
  callout: [IStoryBlokIconCalloutCallout];
  theme: Theme
  width: TIconCalloutWidth;
  richtextOptions?: IRichTextOptions;
}

export const StoryBlokIconCallout = ({ blok }: IStoryBlokComponent<IStoryBlokIconCallout>) => (
  <>
    <SectionIconCallout
      callout={{
        title: blok.callout[0].title,
        body: renderStoryBlokRichText(blok.callout[0].body, blok.richtextOptions || { unwrapResolvers: true, links: { display: "default" } }),
        image: getStoryBlokImage(blok.callout[0].image, true, ImageMax.HALF_CONTAINER_SM),
        imageSize: blok.callout[0].image_size || "default",
        theme: blok.callout[0].theme,
      }}
      width={blok.width}
      theme={blok.theme}
    />
  </>
);
