import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import SectionAccordion from "apps/website/components/section/SectionAccordion/SectionAccordion";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { Theme } from "apps/website/maps/Theme.map";
import FreeFlowRichText from "apps/website/components/feature/Freeflow/FreeFlowRichText/FreeFlowRichText";
import {
  AccordionType,
} from "apps/website/components/feature/Accordion/Accordion.map";
import CondensedList from "apps/website/components/feature/CondensedList/CondensedList";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import {
  Animation,
} from "../../section/SectionAnimatedSplit/SectionAnimatedSplit.map";
import FreeFlowTitle from "../../feature/Freeflow/FreeFlowTitle/FreeFlowTitle";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";

import {
  IStoryBlokFreeFlowRichText,
  IStoryBlokFreeFlowTitle,
} from "./StoryBlokFreeFlow";
import { IStoryBlokCondensedList } from "./StoryBlokCondensedList";
import { IStoryBlokSpacer } from "./StoryBlokSpacer";

export interface IStoryBlokAdvancedAccordion {
  title: string;
  content: (IStoryBlokFreeFlowTitle | IStoryBlokFreeFlowRichText | IStoryBlokCondensedList | IStoryBlokSpacer)[];
  design?: AccordionType;
  is_open?: boolean;
  ctas?: IStoryBlokLink[];
  theme?: Theme;
  bottom_animation?: Animation;
}

export interface IStoryBlokAccordionSection {
  title: string;
  accordion: [IStoryBlokAdvancedAccordion];
  theme?: Theme;
  ctas?: IStoryBlokCta[];
}

export const StoryBlokAccordionContent = (
  contentPart: IStoryBlokFreeFlowRichText | IStoryBlokCondensedList | IStoryBlokSpacer | IStoryBlokFreeFlowTitle,
) => (
  <>
    { contentPart.component === "section_spacer" && (
      <Spacer size={contentPart.size} />
    ) }
    { contentPart.component === "free_flow_title" && (
      <>
        <FreeFlowTitle
          tag={contentPart.tag}
          key={contentPart._uid}
          align={contentPart.align}
          display="subtitle"
        >
          { contentPart.text }
        </FreeFlowTitle>
      </>
    ) }
    { contentPart.component === "free_flow_rich_text" && (
      <>
        <FreeFlowRichText
          align={contentPart.align}
          withSpacer={!contentPart.disable_spacer}
          size={legacySizeCollectionMap.base}
        >
          { renderStoryBlokRichText(contentPart.content) }
        </FreeFlowRichText>
      </>
    ) }
    { contentPart.component === "condensed_list" && (
      <>
        <CondensedList
          key={contentPart._uid}
          items={contentPart.items.map((item) => ({
            ...item,
            image: getStoryBlokImage(item.image, true, ImageMax.ICON_SM),
          }))}
          imageSize={contentPart.image_size}
          listType={contentPart.list_type}
        />
      </>
    ) }
  </>
);

export const StoryBlokAccordion = ({ blok }: IStoryBlokComponent<IStoryBlokAccordionSection>) => {

  const Content = () => (
    <>
      { blok.accordion[0].content.map((contentPart) => (
        <StoryBlokAccordionContent key={contentPart._uid} {...contentPart} />
      )) }
    </>
  );

  return (
    <>
      <SectionAccordion
        sectionTitle={blok.title}
        theme={blok.theme}
        type={blok.accordion[0].design}
        ctas={getStoryBlokCtas(blok.ctas ?? []) ?? []}
        state={blok.accordion[0].is_open ? "open" : "closed"}
        title={blok.accordion[0].title}
        Content={<Content />}
        bottomAnimation={blok.accordion[0].bottom_animation}
      />
    </>
  );
};
