import { FC, ReactNode } from "react";

import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";

interface IFieldsetContainer {
  themeClass?: string
  className?: string;
  children: ReactNode;
  id?: string;
}

export const FieldsetContainer: FC<IFieldsetContainer> = ({ themeClass, className, children, id }) => (
  <Grid id={id} component={FieldsetContainer.name} className={`container mx-auto px-4 ${themeClass} ${className}`}>
    <Column>
      { children }
    </Column>
  </Grid>
);
