import { FC, useEffect, useState } from "react";

import Text from "../../base/Text/Text";

export interface IFakeLoadingProps {
  onTimerReady?: () => void;
  loadingText: string;
  tickInterval?: number;
  loadingTime?: number;
}

const FakeLoading: FC<IFakeLoadingProps> = ({
  loadingText,
  onTimerReady,
  loadingTime = 8000,
  tickInterval = 500,
}) => {

  const [ ticks, setTicks ] = useState(1);
  const [ loadingTimeComplete, setLoadingTimeComplete ] = useState(false);

  useEffect(() => {

    // This interval sets tick to go between 1 to 3
    const tickTimer = setInterval(() => setTicks((t) => (t % 3) + 1), tickInterval);

    // This interval is to run the loading finished callback
    const loadingTimer = setTimeout(() => {
      if (onTimerReady) onTimerReady();
      setLoadingTimeComplete(true);
    }, loadingTime);

    return () => {
      clearInterval(tickTimer);
      clearTimeout(loadingTimer);
    };
  }, [ setTicks, tickInterval, onTimerReady, loadingTime ]);

  return (
    <div data-component={FakeLoading.name}>
      <Text tag="h3" size="sm" align="center" className={`transition-opacity ease-in-out duration-300 ${loadingTimeComplete ? "opacity-0" : "opacity-100"}`}>
        <span data-loading-text>{ loadingText }{ ".".repeat(ticks) }</span>
      </Text>
    </div>
  );
};

export default FakeLoading;
