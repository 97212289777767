import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

import { TControlTag } from "../types";

import textControlStyles, { TextControlVariantsProps } from "./styles";

export interface IControlProps extends WithDataAttr, TextControlVariantsProps {
  id?: string;
  tag?:TControlTag;
  children: ReactNode;
  className?: string;
}

const TextControl: FC<IControlProps> = ({
  id,
  tag: Tag = "p",
  children,
  "data-testid": testId,
  size,
  align,
  whitespace,
  color,
  weight,
  className,
}) => (
  <Tag
    id={id}
    data-testid={testId}
    className={cx(textControlStyles({ size, align, whitespace, color, weight }), className)}
  >
    { children }
  </Tag>
);

export default memo(TextControl);
