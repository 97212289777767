import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";

import SectionClusterTagList from "../../section/SectionTagCluster/SectionTagCluster";
import { Span } from "../../layout/Column/Column.map";
import { Display } from "../../base/Text/Text.map";

interface IStoryBlokSectionTagListItem {
  tag_text: string;
  tag_theme: Theme;
  text: string;
  spans: string;
  display: Display;
}

interface IStoryBlokClusterTagList {
  items: IStoryBlokSectionTagListItem[];
}

export interface IStoryBlokSectionClusterTagList {
  title: string;
  theme: Theme;
  tag_list: [IStoryBlokClusterTagList];
  contain?: boolean;
}

export const StoryBlokClusterTagList = ({ blok }: IStoryBlokComponent<IStoryBlokSectionClusterTagList>) => (
  <SectionClusterTagList
    title={blok.title}
    theme={blok.theme}
    tags={blok.tag_list[0].items.map((item) => ({
      children: item.tag_text,
      spans: Number(item.spans) as Span,
      theme: item.tag_theme,
    }))}
    hasBorders={!blok.contain}
  />
);
