import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionDiscountCodeContent, {
  DiscountCodeMatchRule,
} from "../../section/SectionDiscountCodeContent/SectionDiscountCodeContent";

import { IStoryBlokFreeFlow, StoryBlokFreeFlow } from "./StoryBlokFreeFlow";

type IStoryBlokDiscountCodeContentChildren = IStoryBlokFreeFlow;

export interface IStoryBlokDiscountCodeContent {
  code_to_match: string;
  code_match_rule: DiscountCodeMatchRule;
  content: IStoryBlokDiscountCodeContentChildren[];
}

export interface IStoryBlokDiscountCodeContentWithCode extends IStoryBlokComponent<IStoryBlokDiscountCodeContent> {
  discountCode?: string
}

export const StoryBlokDiscountCodeContent = ({ flowId, blok, discountCode }: IStoryBlokDiscountCodeContentWithCode) => (
  <SectionDiscountCodeContent
    discountCode={discountCode ?? ""}
    codeMatchRule={blok.code_match_rule}
    codeToMatch={blok.code_to_match}
  >
    { blok.content?.map((child) => (
      <>
        { child.component === "section_free_flow" && (
          <StoryBlokFreeFlow flowId={flowId} blok={{
            items: child.items,
          }}
          />
        ) }
      </>
    )) }
  </SectionDiscountCodeContent>
);
