import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionProductOverviewTabs from "../../section/SectionProductOverviewTabs/SectionProductOverviewTabs";

export interface IStoryBlokProductOverviewTabs {
  title: string;
  theme: Theme
}

export const StoryBlokProductOverviewTabs = ({ blok }: IStoryBlokComponent<IStoryBlokProductOverviewTabs>) => (
  <SectionProductOverviewTabs
    title={blok.title}
    theme={blok.theme}
  />
);
