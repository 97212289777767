import { FC } from "react";

import LabelText, {
  ILabelText,
} from "apps/website/components/feature/LabelText/LabelText";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";

export interface ISectionLabelText extends ILabelText {} // eslint-disable-line

const SectionLabelText: FC<ISectionLabelText> = ({ title, body, labelPrefix, label, labelSuffix, theme = "dark" }) => (
  <Section component={SectionLabelText.name} theme={theme}>
    <Container>
      <LabelText
        title={title}
        body={body}
        labelPrefix={labelPrefix}
        label={label}
        labelSuffix={labelSuffix}
        theme={theme}
      />
    </Container>
  </Section>
);

export default SectionLabelText;
