import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import Image from "apps/website/components/base/Image/Image";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  IStoryBlokImage,
  IStoryBlokComponent,
} from "apps/website/components/page/api.types";

export interface IStoryBlokArticleImage {
  image: IStoryBlokImage
}

export const StoryBlokArticleImage = ({ blok }: IStoryBlokComponent<IStoryBlokArticleImage>) => {// eslint-disable-line
  return (
    <>
      <Image image={{ ...getStoryBlokImage(blok.image, true, ImageMax.CONTAINER_XL) }} alt={blok.image.alt} />
      <Spacer size="xl" />
    </>
  );
};
