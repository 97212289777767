import { FC } from "react";

import ColumnRowTable from
  "apps/website/components/feature/Table/ColumnRowTable/ColumnRowTable";
import { getBrandImage } from "apps/website/utils/content/brands";
import { BRANDS } from "apps/website/constants/brands";

import { brandComparisonTableData } from "./shared/data";

export interface IBrandComparisonTable {
  highlightKatKin?: boolean;
}

const BrandComparisonTable: FC<IBrandComparisonTable> = ({ highlightKatKin }) => {

  const titles = brandComparisonTableData.titles.map((title) => ({
    ...title,
    image: getBrandImage(title.id as keyof typeof BRANDS),
  }));

  return (
    <ColumnRowTable
      component="BrandComparisonTable"
      highlightColumn={highlightKatKin ? 0 : undefined}
      columnTitles={titles}
      rows={brandComparisonTableData.rows}
    />
  );
};

export default BrandComparisonTable;
