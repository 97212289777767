import SectionRowColumnTable from
  "apps/website/components/section/SectionTable/SectionRowColumnTable/SectionRowColumnTable";
import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import {
  getStoryBlokTableTitle,
  getStoryBlokTableValue,
} from "apps/website/utils/storyblok/table";
import {
  IRowColumnTableRow,
} from "apps/website/components/feature/Table/RowColumnTable/RowColumnTable";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

export interface IStoryBlokTableHead {
  _uid: string;
  value: string;
}

export interface IStoryBlokTableBodyValue {
  _uid: string;
  value: string;
}

export interface IStoryBlokTableBody {
  body: IStoryBlokTableBodyValue[]
}

export interface IStoryBlokTable {
  thead: IStoryBlokTableHead[];
  tbody: IStoryBlokTableBody[];
}

export interface IStoryBlokColumnTable {
  title: string;
  table: IStoryBlokTable;
  cta?: IStoryBlokCta[];
  theme?: Theme;
  source?: string;
}

export const StoryBlokRowColumnTable = ({ blok }: IStoryBlokComponent<IStoryBlokColumnTable>) => (
  <SectionRowColumnTable
    title={blok.title}
    table={{
      titles: blok.table.thead.map((title) => getStoryBlokTableTitle(title.value)),
      rows: blok.table.tbody.map((row) => ({
        values: row.body.map((cell) => (
          getStoryBlokTableValue(cell.value)
        )),
      })) as IRowColumnTableRow[],
    }}
    cta={getStoryBlokCtas(blok.cta ?? [])}
    theme={blok.theme}
    source={blok.source && renderStoryBlokRichText(blok.source, { unwrapResolvers: true, links: { display: "default" } })}
  />
);
