import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import SectionYoutube from
  "apps/website/components/section/SectionYoutube/SectionYoutube";

export interface IStoryBlokYoutube {
  id: string;
  theme: Theme
  video_title: string;
}

export const StoryBlokYoutube = ({ blok }: IStoryBlokComponent<IStoryBlokYoutube>) => (
  <SectionYoutube
    id={blok.id}
    theme={blok.theme}
    videoTitle={blok.video_title}
  />
);
