import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import ImageTextList, {
  Flow,
  IImageTextList,
} from "apps/website/components/feature/ImageTextList/ImageTextList";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { AvailableTag } from "apps/website/components/base/List/List.map";

export interface ISectionImageTextList extends IImageTextList {
  title?: string;
  theme?: Theme;
  flow?: Flow;
  listType?: AvailableTag
}

const SectionImageTextList: FC<ISectionImageTextList> = (props) => (
  <Section
    component={ SectionImageTextList.name }
    theme={ props.theme }
    size={ props.theme === "default" ? "none" : "xl" }
  >
    <Container>
      { props.title && (
        <>
          <Text tag="h2" display="title" size={ legacySizeCollectionMap.titleLg } align="center">{ props.title }</Text>
          <Spacer size="xl" />
        </>
      ) }
      <ImageTextList items={ props.items } flow={ props.flow } listType={ props.listType } columns={ props.columns } />
    </Container>
  </Section>
);

export default SectionImageTextList;
