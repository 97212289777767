import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import { TDisplay } from "../../section/SectionLogo/SectionLogo.map";
import SectionLogo from "../../section/SectionLogo/SectionLogo";

export interface IStoryBlokLogo {
  theme: Theme;
  display: TDisplay;
}

export const StoryBlokLogo = ({ blok }: IStoryBlokComponent<IStoryBlokLogo>) => (
  <SectionLogo
    display={ blok.display || "default" }
    theme={ blok.theme }
  />
);
