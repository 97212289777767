import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Video, { IVideo } from "apps/website/components/base/Video/Video";
import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  PlayPausePosition,
} from "apps/website/components/base/Video/Video.map";

import {
  borderlessSectionBackgroundClassMap,
} from "../SectionIconTextList/Section.map";

export interface ISectionIconVideo {
  title: string;
  icon: IImageProps;
  video: IVideo;
  playPausePosition?: PlayPausePosition
  theme?: Theme;
  hasBorders?: boolean;
}

const SectionIconVideo: FC<ISectionIconVideo> = ({ title, icon, video, playPausePosition, theme = "orange", hasBorders = true }) => (
  <Section component={SectionIconVideo.name} theme={hasBorders ? theme : "default"} size={hasBorders ? "xl" : "none"}>
    <Container theme={theme} className={`${themeRootClassMap[theme]} ${!hasBorders && borderlessSectionBackgroundClassMap[theme]}`}>
      { !hasBorders && (
        <Spacer size="xl" />
      ) }
      <Grid gap="lg">
        <Column spans={{ lg: 6 }} justify="around" align="center">
          <Grid>
            <Column spans={6} offset={4}>
              <Image image={icon} alt={title} />
              <Spacer size="md" />
              <Text display="title" size={legacySizeCollectionMap.titleMd} align="center">{ title }</Text>
            </Column>
          </Grid>
        </Column>
        <Column spans={{ lg: 5 }} className="transform lg:rotate-1" justify="center" align="center">
          <Video
            video={video.video}
            poster={video.poster}
            controls={false}
            playPausePosition={playPausePosition}
            loading="onPlay"
          />
        </Column>
      </Grid>
      { !hasBorders && (
        <Spacer size="xl" />
      ) }
    </Container>
  </Section>
);

export default SectionIconVideo;
