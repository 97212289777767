import Text from "apps/website/components/base/Text/Text";
import { AvailableTag, Size } from "apps/website/components/base/Text/Text.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { slugify } from "apps/website/utils/content/text";

type StoryBlockTitleSizeMap = {
  [key in AvailableTag]?: Size;
};

export interface IStoryBlokArticleTitle {
  tag: AvailableTag;
  text: string;
}

export const StoryBlokArticleTitle = ({ blok }: IStoryBlokComponent<IStoryBlokArticleTitle>) => {// eslint-disable-line
  const titleSize: StoryBlockTitleSizeMap = {
    h2: "lg",
    h3: "md",
    h4: "default",
  };
  return (
    <>
      <Text id={slugify(blok.text)} tag={blok.tag} display="title" size={titleSize[blok.tag]}>{ blok.text }</Text>
      <Spacer size="lg" />
    </>
  );
};
