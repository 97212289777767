import { FC, ReactNode } from "react";

import Icon from "apps/website/components/base/Icon/Icon";
import Text from "apps/website/components/base/Text/Text";

type IconWrapperBackground = "POSITIVE" | "NEGATIVE";

const IconWrapper: FC<{
  children: ReactNode, background?: IconWrapperBackground, highlight?: boolean
}> = ({ children, highlight, background = "NEGATIVE" }) => (
  <div className="flex justify-center" data-theme={background === "NEGATIVE" ? "dark" : "green"}>
    <div className={`rounded-full ${background === "POSITIVE" ? "bg-green" : "bg-black"} ${highlight ? "h-10 w-10 lg:h-12 lg:w-12" : "h-6 w-6 lg:h-8 lg:w-8"}`}>
      { children }
    </div>
  </div>
);

export const renderTableValue = (value: string | boolean | [string, boolean], highlight?: boolean) => {
  if (typeof value === "string") return value;

  if (typeof value === "object") {
    const icon = value[1] ? "tickHeavy" : "crossHeavy";
    return <>
      <IconWrapper highlight={highlight} background={icon === "tickHeavy" ? "POSITIVE" : "NEGATIVE"}>
        <Icon icon={icon} size="fit" />
      </IconWrapper>
      <Text tag="span" whitespace={{ lg: "preWrap" }}>{ value[0] }</Text>
    </>;
  }

  if (value) return <IconWrapper highlight={highlight} background="POSITIVE"><Icon icon="tickHeavy" size="fit" /></IconWrapper>;

  return <IconWrapper highlight={highlight}><Icon icon="crossHeavy" size="fit"/></IconWrapper>;

};
