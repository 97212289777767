import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  Flow,
} from "apps/website/components/feature/ImageTextList/ImageTextList";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import ImageTextList from
  "apps/website/components/section/SectionImageTextList/SectionImageTextList";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import {
  Columns,
} from "apps/website/components/feature/ImageTextList/ImageTextListItem/ImageTextListItemColumn.map";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";

interface IStoryBlokImageTextListItems {
  items: IStoryBlokImageTextListItem[];
  list_type: AvailableTag;
  columns: Columns
}

export interface IStoryBlokImageTextListItem {
  image: IStoryBlokImage;
  is_image_animated: boolean;
  title: string;
  body: string;
  cta: [IStoryBlokCta]
}

export interface IStoryBlokImageTextList {
  title?: string;
  flow: Flow;
  items: [IStoryBlokImageTextListItems];
  theme: Theme
}

export const StoryBlokImageTextList = ({ blok }: IStoryBlokComponent<IStoryBlokImageTextList>) => (
  <>
    <ImageTextList
      title={ blok.title }
      flow={ blok.flow }
      items={ blok.items[0]?.items.map((item) => ({
        image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_SM),
        title: item.title,
        body: item.body,
        cta: item.cta?.[0] ? getStoryBlokCta(item.cta[0]) : undefined,
      })) }
      listType={ blok.items[0].list_type || "ul" }
      columns={ blok.items[0].columns || "[2,4]" }
      theme={ blok.theme }
    />
  </>
);
