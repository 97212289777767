import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import Youtube, {
  IYoutube,
} from "apps/website/components/feature/Youtube/Youtube";

type IStoryBlokArticleYoutube = IStoryBlokComponent<IYoutube>;

export const StoryBlokArticleYoutube = ({ blok }: IStoryBlokArticleYoutube) => {// eslint-disable-line
  return (
    <>
      <Youtube
        id={blok.id}
        videoTitle={blok.videoTitle}
      />
      <Spacer size="xl" />
    </>
  );
};
