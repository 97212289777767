import { FC } from "react";

import ListItem from "apps/website/components/base/ListItem/ListItem";
import Icon from "apps/website/components/base/Icon/Icon";
import Text from "apps/website/components/base/Text/Text";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface IConditionalCheckLisItemCondition {
  key: string;
  value: string;
}

export interface IConditionalCheckLisItemProps {
  condition: IConditionalCheckLisItemCondition;
  text: string;
  display?: Display;
}

const ConditionalCheckListItem: FC<IConditionalCheckLisItemProps> = ({ text, display }) => (
  <ListItem component={ConditionalCheckListItem.name} className="mb-4">
    <div className="rounded-full bg-green mr-2 w-6 h-6">
      <Icon icon="tickHeavy" size="xsmall"/>
    </div>
    <Text tag="span" display={display || "default"} size={legacySizeCollectionMap.base}>
      { text }
    </Text>
  </ListItem>
);

export default ConditionalCheckListItem;
