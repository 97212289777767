import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

import SectionStepByStep from "../../section/SectionStepByStep/SectionStepByStep";
import { TStepOptionImageSize } from "../../feature/StepByStep/StepByStep";

interface IStoryBlokStepByStepStep {
  image: IStoryBlokImage;
  title: string;
  body: string;
}

export interface IStoryBlokStepByStep {
  hero?: IStoryBlokImage;
  title: string;
  steps: IStoryBlokStepByStepStep[];
  theme: Theme
  options_step_image_size?: TStepOptionImageSize;
}

export const StoryBlokStepByStep = ({ blok }: IStoryBlokComponent<IStoryBlokStepByStep>) => (
  <SectionStepByStep
    stepByStep={ {
      hero: blok.hero ? getStoryBlokImage(blok.hero) : undefined,
      title: blok.title,
      options: {
        imageSize: blok.options_step_image_size,
      },
      steps: blok.steps.map((step) => ({
        ...step,
        body: renderStoryBlokRichText(step.body),
        image: getStoryBlokImage(step.image),
      })),
    } }
    theme={ blok.theme }
  />
);
