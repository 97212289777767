import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import {
  AlertPrompt,
  IAlertPromptProps,
} from "../../feature/AlertPrompt/AlertPrompt";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionAlertPromptProps {
  alert: IAlertPromptProps;
  theme: Theme;
}

const SectionAlertPrompt: FC<ISectionAlertPromptProps> = ({ alert, theme }) => (
  <Section component={ SectionAlertPrompt.name } theme={ theme } size={ getSectionSize(theme) }>
    <Container>
      <AlertPrompt { ...alert } />
    </Container>
  </Section>
);

export default SectionAlertPrompt;
