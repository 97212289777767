import { useSearchParams } from "next/navigation";

/**
 * Returns the path stored in the query param `returnToPath`
 * Used in forms, to control navbar back button behaviour
 * when on the first section of that form
 */
export function useReturnToPath(): string | undefined {
  const query = useSearchParams();
  return query.get("returnToPath") as string | undefined;
}
