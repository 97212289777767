import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";

import SectionAddProductCardPreviewList, {
  TitleDisplay,
} from "../../section/SectionAddProductCardPreviewList/SectionAddProductCardPreviewList";

export interface IStoryBlokAddProductCardPreviewListItem {
  title: string;
  title_display: TitleDisplay;
  image: IStoryBlokImage;
  body: string;
  button_text: string;
  button_link: IStoryBlokLink;
  tag: string;
  tag_theme: Theme;
}

export interface IStoryBlokAddProductCardPreviewList {
  title: string;
  title_display: TitleDisplay;
  theme: Theme
  products: IStoryBlokAddProductCardPreviewListItem[];
}

export const StoryBlokAddProductCardPreviewList = (
  { blok }: IStoryBlokComponent<IStoryBlokAddProductCardPreviewList>,
) => (
  <SectionAddProductCardPreviewList
    title={blok.title}
    titleDisplay={blok.title_display}
    products={blok.products.map((product) => ({
      title: product.title,
      tag: {
        theme: product.tag_theme,
        text: product.tag,
      },
      image: getStoryBlokImage(product.image, true, ImageMax.HALF_CONTAINER_LG),
      body: product.body,
      buttonText: product.button_text,
      buttonLink: getStoryBlokLink(product.button_link),
    }))}
    theme={blok.theme}
  />
);
