import { Theme } from "apps/website/maps/Theme.map";

type ReviewCardTheme = {
  [key in Theme]?: Theme;
};
export const reviewCardThemeMap: ReviewCardTheme = {
  default: "blue",
  light: "blue",
  "light-grey": "blue",
};
