import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

import {
  IStoryBlokSectionHighlighterCallout,
  StoryBlokHighlighterCallout,
} from "../StoryBlokHighlighterCallout";

export const StoryBlokFormHighlighterCallout = (
  component: IStoryBlokComponent<IStoryBlokSectionHighlighterCallout>,
) => {

  const formComponent: IStoryBlokComponent<IStoryBlokSectionHighlighterCallout> = {
    blok: {
      ...component.blok,
      callout: [
        {
          ...component.blok.callout[0],
          title: interpolateString(
            component.blok.callout[0].title,
            getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
            Interpolator.FRONTEND,
          ),
        },
      ],
      richtextOptions: {
        interpolation: {
          values: getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        },
        tags: {
          MARK_CODE: {
            display: "default",
          },
        },
      },
    },
  };

  return (
    <>
      <StoryBlokHighlighterCallout { ...formComponent } />
    </>
  );
};
