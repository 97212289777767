import Script from "next/script";
import React from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import { IStoryBlokComponent } from "../../page/api.types";

interface IStoryBlokTrustPilotReviewForm {
  theme?: Theme;
}

export const StoryBlokTrustPilotReviewForm = ({ blok }: IStoryBlokComponent<IStoryBlokTrustPilotReviewForm>) => (
  <>
    <Script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
    <Section theme={blok.theme}>
      <div className="trustpilot-widget" data-locale="en-US" data-template-id="530d0eaf748a510e2093cf9b" data-businessunit-id="635594a214446de64f7f5bf1" data-style-height="850px" data-style-width="100%">
        <a href="https://www.trustpilot.com/review/katkin.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
      </div>
    </Section>
  </>
);
