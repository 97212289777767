import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { Theme } from "apps/website/maps/Theme.map";
import {
  Direction,
} from "apps/website/components/feature/ImageTextList/ImageTextListItem/ImageTextListItemRow";
import {
  IStoryBlokAdvancedImages,
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  FontSize,
} from "apps/website/components/feature/ImageText/ImageText.map";
import { AvailableTag } from "apps/website/components/base/Text/Text.map";

import SectionImageTagList from "../../section/SectionImageTagList/SectionImageTagList";

import { IStoryBlokTagList } from "./StoryBlokTagList";

export interface IStoryBlokImageTagListProps {
  title: string;
  title_tag: AvailableTag;
  font_size?: FontSize;
  subtitle?: string;
  body: string;
  tag_list: [IStoryBlokTagList]
  image: IStoryBlokImage;
  advanced_images?: IStoryBlokAdvancedImages;
  direction: Direction;
}

export interface IStoryBlokImageText {
  theme: Theme;
  image_tag_list: [IStoryBlokImageTagListProps];
}

export const StoryBlokImageTagList = ({ blok }: IStoryBlokComponent<IStoryBlokImageText>) => (
  <SectionImageTagList
    imageTagList={{
      title: blok.image_tag_list[0]?.title,
      titleTag: blok.image_tag_list[0]?.title_tag || "h2",
      fontSize: blok.image_tag_list[0]?.font_size || "default",
      subtitle: blok.image_tag_list[0]?.subtitle,
      body: renderStoryBlokRichText(blok.image_tag_list[0]?.body),
      image: getStoryBlokImage(blok.image_tag_list[0]?.image, true, ImageMax.HALF_CONTAINER_2XL),
      direction: blok.image_tag_list[0]?.direction,
      tagList: blok.image_tag_list[0].tag_list[0].items.map((item) => ({
        tag: {
          children: item.tag_text,
          theme: item.tag_theme || "green",
          align: item.tag_theme === blok.theme ? "default" : "center",
        },
        display: item.display,
        text: item.text,
      })),
    }}
    theme={blok.theme}
  />
);
