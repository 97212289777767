import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import TeamMemberCarouselSection
  from "apps/website/components/section/SectionCarousel/SectionTeamMemberCarousel/SectionTeamMemberCarousel";

export interface IStoryBlokTeamMember {
  member_name: string;
  member_image: IStoryBlokImage;
  pet_name: string;
  pet_image: IStoryBlokImage;
  theme?: Theme;
}
export interface IStoryBlokTeamMemberCarousel {
  title: string;
  subtitle: string;
  body: string;
  members: { content: IStoryBlokTeamMember }[];
  theme: Theme;
}

export const StoryBlokTeamCarousel = ({ blok }: IStoryBlokComponent<IStoryBlokTeamMemberCarousel>) => (
  <TeamMemberCarouselSection
    title={blok.title}
    subtitle={blok.subtitle}
    body={blok.body}
    items={blok.members.map(((member) => ({
      memberName: member.content?.member_name,
      memberImage: getStoryBlokImage(member.content?.member_image, true, ImageMax.HALF_CONTAINER_SM),
      petName: member.content?.pet_name,
      petImage: getStoryBlokImage(member.content?.pet_image, true, ImageMax.HALF_CONTAINER_SM),
      theme: member.content?.theme,
    }))) || []}
    theme={blok.theme}
  />
);
