import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import { IVideoPath } from "apps/website/components/base/Video/Video";
import Splash from
  "apps/website/components/section/SectionSplash/SectionSplash";
import {
  ISplashSubtitle,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";

export interface ISectionVideoSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  body?: string;
  ctas?: ILink[];
  theme?: Theme;
  video: IVideoPath;
  videoPoster: string;
}

const SectionVideoSplash: FC<ISectionVideoSplash> = ({ title, subtitle, body, ctas, video, videoPoster, theme = "brand-alt" }) => (
  <Splash
    component="SectionVideoSplash"
    title={title}
    subtitle={subtitle}
    body={body}
    theme={theme}
    videoPoster={videoPoster}
    video={video}
    ctas={ctas}
  />
);

export default SectionVideoSplash;
