import { FC, Suspense } from "react";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

import AddProductCardPreview, {
  IAddProductCardPreviewProps,
} from "./AddProductCardPreview/AddProductCardPreview";
import { offsetMap } from "./AddProductCardPreviewList.map";

export interface IAddProductCardPreviewListProps {
  products: IAddProductCardPreviewProps[];
}

const AddProductCardPreviewList: FC<IAddProductCardPreviewListProps> = ({ products }) => (
  <Grid component={AddProductCardPreviewList.name} tag="ul">
    { products.map((product, index) => (
      <Column
        tag="li"
        spans={{ default: 12, lg: 4 }}
        offset={{ lg: (index === 0 && products.length < 3) ? (offsetMap[products.length]) : undefined }}
        key={product.buttonLink}
      >
        <Suspense>
          <AddProductCardPreview {...product} />
        </Suspense>
      </Column>
    )) }
  </Grid>
);

export default AddProductCardPreviewList;
