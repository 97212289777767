import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  IRichTextOptions,
  renderStoryBlokRichText,
} from "apps/website/utils/storyblok/text";

import SectionHighlighterCallout from "../../section/SectionHighlighterCallout/SectionHighlighterCallout";

export interface IStoryBlokHighlighterCallout {
  title: string;
  body: string;
  theme: Theme;
}

export interface IStoryBlokSectionHighlighterCallout {
  callout: [IStoryBlokHighlighterCallout];
  theme: Theme
  richtextOptions?: IRichTextOptions;
}

export const StoryBlokHighlighterCallout = ({ blok }: IStoryBlokComponent<IStoryBlokSectionHighlighterCallout>) => (
  <SectionHighlighterCallout
    callout={{
      title: blok.callout[0].title,
      body: renderStoryBlokRichText(blok.callout[0].body, blok.richtextOptions),
      theme: blok.callout[0].theme,
    }}
    theme={blok.theme}
  />
);
