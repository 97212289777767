import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import SectionBatchTestResultLookup, {
  ISectionBatchTestResultLookup,
} from "apps/website/components/section/SectionBatchTestResultLookup/SectionBatchTestResultLookup";

export type IStoryBlokBatchTestResultLookup = ISectionBatchTestResultLookup;

export const StoryBlokBatchTestResultLookup = ({ blok }: IStoryBlokComponent<IStoryBlokBatchTestResultLookup>) => (
  <SectionBatchTestResultLookup
    title={blok.title}
    body={blok.body}
    note={blok.note}
  />
);
