import { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import Advert, { IAdvert } from "apps/website/components/feature/Advert/Advert";

export interface ISectionAdvert extends IAdvert {} // eslint-disable-line

const SectionAdvert: FC<ISectionAdvert> = ({ title, body, image, cta, theme = "default" }) => (
  <Section component="SectionAdvert" theme="light-grey">
    <Container>
      <Advert
        title={title}
        body={body}
        image={image}
        cta={cta}
        theme={theme}
      />
    </Container>
  </Section>
);

export default SectionAdvert;
