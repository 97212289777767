import clsx from "clsx";
import { FC } from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { defaultJustifyClassMap } from "apps/website/maps/Flex.map";

import { sizeClassMap, directionClassMap } from "./SectionArrow.map";
import { borderClassByTheme, ISectionArrowProps } from "./shared/types";

const SectionArrow: FC<ISectionArrowProps> = ({ direction = "DOWN", backgroundTheme = "default", arrowTheme = "brand", size = "default", justify = "center", className }) => (
  <div data-component={ SectionArrow.name } className={ `w-full flex ${defaultJustifyClassMap[justify]} ${themeRootClassMap[backgroundTheme]}` }>
    <div
      className={
        clsx(
          className,
          sizeClassMap[size],
          directionClassMap[direction],
          `border-transparent ${borderClassByTheme[arrowTheme]}`,
          `${direction === "UP" ? "" : "rotate-[180deg]"}`,
        )
      }
    />
  </div>
);

export default SectionArrow;
