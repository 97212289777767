import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";
import Container from "../../layout/Container/Container";
import HighlighterCallout, {
  IHighlighterCalloutProps,
} from "../../feature/HighlighterCallout/HighlighterCallout";

export interface ISectionHighlighterCalloutProps {
  theme: Theme;
  callout: IHighlighterCalloutProps;
}

const SectionHighlighterCallout: FC<ISectionHighlighterCalloutProps> = ({ theme, callout }) => (
  <Section component={SectionHighlighterCallout.name} theme={theme} size={getSectionSize(theme)}>
    <Container>
      <HighlighterCallout { ...callout } />
    </Container>
  </Section>
);

export default SectionHighlighterCallout;
