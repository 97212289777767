import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import {
  AvailableTag,
  BreakpointListColumns,
  ListColumns,
} from "apps/website/components/base/List/List.map";
import SectionCondensedList from "apps/website/components/section/SectionCondensedList/SectionCondensedList";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  ImageSize,
} from "apps/website/components/feature/CondensedList/CondensedListItem/CondensedListItem.map";

import {
  TCondensedListStyle,
} from "../../feature/CondensedList/CondensedList.map";

export interface IStoryBlokCondensedListItem {
  title: string;
  body: string;
  image: IStoryBlokImage;
  theme: Theme;
}

type StoryBlokCondensedListColumns = "[1,1]" | "[1,2]" | "[2,2]" | "[2,4]";

export interface IStoryBlokCondensedList {
  _uid: string;
  component: "condensed_list";
  items: IStoryBlokCondensedListItem[];
  list_type?: AvailableTag;
  image_size?: ImageSize;
  columns?: StoryBlokCondensedListColumns;
  style?: TCondensedListStyle;
}

export interface IStoryBlokSectionCondensedList {
  title: string;
  condensed_list: [IStoryBlokCondensedList];
  theme?: Theme;
  ctas?: IStoryBlokCta[];
}

export const getColumns = (SBColumns: StoryBlokCondensedListColumns) => {
  const columnMap: Record<StoryBlokCondensedListColumns, ListColumns | BreakpointListColumns> = {
    "[1,1]": "default",
    "[1,2]": { default: "default", lg: 2 },
    "[2,2]": 2,
    "[2,4]": { default: 2, lg: 4 },
  };

  return columnMap[SBColumns];
};

export const getStoryBlokCondensedListItems = (items: IStoryBlokCondensedListItem[]) => items.map((item) => ({
  ...item,
  image: getStoryBlokImage(item.image, true, ImageMax.ICON_SM),
}));

export const StoryBlokCondensedList = ({ blok }: IStoryBlokComponent<IStoryBlokSectionCondensedList>) => (
  <SectionCondensedList
    title={blok.title}
    items={getStoryBlokCondensedListItems(blok.condensed_list[0].items)}
    imageSize={blok.condensed_list[0].image_size}
    theme={blok.theme}
    style={blok.condensed_list[0].style || "default"}
    listType={blok.condensed_list[0].list_type}
    ctas={getStoryBlokCtas(blok.ctas ?? [])}
    columns={blok.condensed_list[0].columns && getColumns(blok.condensed_list[0].columns)}
  />
);
