import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import {
  INewsletterSignUp,
} from "apps/website/components//feature/NewsletterSignUp/NewsletterSignUp";
import {
  Layout,
} from "apps/website/components//feature/NewsletterSignUp/NewsletterSignUp.map";

export interface ISectionNewsletterSignUp extends INewsletterSignUp {
  theme?: Theme
  layout?: Layout;
}

const SectionNewsletterSignUp: FC<ISectionNewsletterSignUp> = () => (<></>);

// const SectionNewsletterSignUp: FC<ISectionNewsletterSignUp> = ({
//   title, completeTitle, body, completeBody, completeIcon, buttonText, theme = "default", layout = "secondary",
// }) => (
//   <>
//     { /* { [ "A", "B" ].includes(experiments[EXPERIMENT_KEYS.NEWSLETTER_SIGN_UP_PROMPT] ?? "") && (
//         <Section component="SectionNewsletterSignUp">
//           <Container className={themeRootClassMap[theme]}>
//             <Spacer size="2xl" />
//             <Grid>
//               <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
//                 <NewsletterSignUp
//                   title={title}
//                   completeTitle={completeTitle}
//                   body={body}
//                   completeBody={completeBody}
//                   completeIcon={completeIcon}
//                   buttonText={buttonText}
//                   layout={layout}
//                   size="lg"
//                   source="Section"
//                 />
//               </Column>
//             </Grid>
//             <Spacer size="2xl" />
//           </Container>
//         </Section>
//       ) } */ } { /* Looks like it was part of an unused experiment - Leaving here for now - 2023-01-10 */ }
//   </>
// );

export default SectionNewsletterSignUp;
