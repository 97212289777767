import {
  ITableTitle,
} from "apps/website/components/feature/Table/ColumnRowTable/ColumnRowTable";
import { BRAND_TITLES } from "apps/website/constants/brands";

import { getBrandImage } from "../content/brands";

export const getStoryBlokTableTitle = (tableTitle: string): ITableTitle => {
  for (const title of Object.keys(BRAND_TITLES)) {
    if (tableTitle.includes(title)) {
      const name = tableTitle.replace(title, "");
      return {
        id: title,
        name,
        image: getBrandImage(BRAND_TITLES[title]),
      };
    }
  }
  return {
    id: tableTitle,
    name: tableTitle,
  };
};

export const getStoryBlokTableValue = (value: string | boolean) => {
  if (typeof value === "boolean") return value;

  const trueString = "[[true]]";
  const falseString = "[[false]]";
  const trimmedValue = value.trim();

  if (trimmedValue.startsWith(trueString) || trimmedValue.endsWith(trueString)) {
    return [ value.replace(trueString, "").trim(), true ];
  }
  if (trimmedValue.startsWith(falseString) || trimmedValue.endsWith(falseString)) {
    return [ value.replace(falseString, "").trim(), false ];
  }
  return value;
};
