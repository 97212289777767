import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { IStoryBlokImage } from "@forms/schema";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { BreakpointDirection } from "apps/website/maps/Flex.map";

import SectionInstructionCardsList from "../../section/SectionInstructionCardsList/SectionInstructionCardsList";
import {
  BreakpointSize,
  legacySizeCollectionMap,
} from "../../base/Text/Text.map";
import { AvailableTag } from "../../base/List/List.map";

type Direction = "default";

const directionMap: Record<Direction, BreakpointDirection> = {
  default: { default: "column", lg: "row" },
};

export interface IIStoryBlokInstructionCard {
  title: string;
  body: string;
  image: IStoryBlokImage;
  theme: Theme;
}

export interface IStoryBlokInstructionCardList {
  title: string;
  titleSize?: BreakpointSize;
  theme: Theme;
  cards: IIStoryBlokInstructionCard[];
  list_tag: AvailableTag;
  direction: Direction;
}

export const StoryBlokInstructionCardList = (
  { blok }: IStoryBlokComponent<IStoryBlokInstructionCardList>,
) => (
  <>
    <SectionInstructionCardsList
      title={blok.title}
      titleTag="h2"
      titleSize={legacySizeCollectionMap.titleLg}
      theme={blok.theme}
      listTag={blok.list_tag ?? "ul"}
      direction={directionMap[blok.direction]}
      items={blok.cards.map((card) => ({
        title: card.title,
        body: renderStoryBlokRichText(card.body),
        image: getStoryBlokImage(card.image, true, ImageMax.HALF_CONTAINER_2XL),
        theme: card.theme,
      }))}
    />
  </>
);
