import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

export interface IStoryBlokSpacer {
  _uid: string;
  component: "section_spacer";
  size: Size;
  theme: Theme;
}

export const StoryBlokSpacer = ({ blok }: IStoryBlokComponent<IStoryBlokSpacer>) => (
  <Spacer size={blok.size} className={themeRootClassMap[blok.theme]} />
);
