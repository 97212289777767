import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import MicroGallery, {
  IMicroGallery,
} from "../../feature/MicroGallery/MicroGallery";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionMicroGallery extends IMicroGallery {
  theme: Theme;
}

const SectionMicroGallery: FC<ISectionMicroGallery> = ({ preText, images, title, body, theme }) => (
  <Section component={ SectionMicroGallery.name } theme={ theme } size={ getSectionSize(theme) }>
    <Container>
      <Grid>
        <Column spans={ { lg: 6 } } offset={ { lg: 4 } }>
          <MicroGallery
            preText={ preText }
            images={ images }
            title={ title }
            body={ body }
          />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionMicroGallery;
