import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import Splash from
  "apps/website/components/section/SectionSplash/SectionSplash";
import {
  ISplashSubtitle,
  Size,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";

export interface ISectionColorSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  body?: string;
  ctas?: ILink[];
  theme?: Theme;
  hasBorders?: boolean;
  size?: Size;
}

const SectionColorSplash: FC<ISectionColorSplash> = ({ title, subtitle, body, ctas, theme = "brand", hasBorders = false, size = "auto" }) => (
  <Splash
    component="SectionColorSplash"
    title={title}
    subtitle={subtitle}
    body={body}
    theme={theme}
    ctas={ctas}
    size={size}
    hasBorders={hasBorders}
  />
);

export default SectionColorSplash;
