import { FC } from "react";

export interface IFreeFlowRawHtml {
  html: string;
}

const FreeFlowRawHtml: FC<IFreeFlowRawHtml> = ({ html }) => (
  <div data-component={FreeFlowRawHtml.name} dangerouslySetInnerHTML={{ __html: html }} />
);

export default FreeFlowRawHtml;
