import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import ComparisonCardList, {
  IComparisonCardListProps,
} from "../../feature/ComparisonCardList/ComparisonCardList";
import Text from "../../base/Text/Text";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Container from "../../layout/Container/Container";
import Spacer from "../../layout/Spacer/Spacer";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionComparisonCardListProps extends IComparisonCardListProps {
  title?: string;
  theme?: Theme;
}

const SectionComparisonCardList: FC<ISectionComparisonCardListProps> = ({ cards, title, theme = "light-grey" }) => (
  <Section component={ SectionComparisonCardList.name } theme={ theme } size={ getSectionSize(theme) }>
    { title && (
      <>
        <Container>
          <Grid>
            <Column justify="center" align="center" spans={ { lg: 6 } } offset={ { lg: 4 } }>
              <Text tag="h3" display="title" size={ legacySizeCollectionMap.titleMd } align="center">{ title }</Text>
            </Column>
          </Grid>
        </Container>
        <Spacer size="xl" />
      </>
    ) }
    <ComparisonCardList cards={ cards } />
  </Section>
);

export default SectionComparisonCardList;
