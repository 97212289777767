import { FC } from "react";

import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Tag, { ITag } from "apps/website/components/base/Tag/Tag";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Icon from "apps/website/components/base/Icon/Icon";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

interface IComparisonCardListItem {
  icon: IconSvg;
  title: string;
}

export interface IComparisonCardProps {
  theme: Theme;
  title: string;
  image: IImageProps;
  tag?: ITag;
  listItems: IComparisonCardListItem[];
}

const ComparisonCard: FC<IComparisonCardProps> = ({ theme, title, image, tag, listItems }) => (
  <div data-component={ComparisonCard.name} data-theme={theme} className={`${theme && themeRootClassMap[theme]} h-full`}>
    <Grid data-component={ComparisonCard.name} gap="none">
      <Column className="p-2 lg:p-4" align="center">
        <Text tag="h3" display="title" align="center" size={legacySizeCollectionMap.titleSm} data-testid="ComparisonCardTitle">{ title }</Text>
        <Spacer size="md" />
        <div className="px-4 lg:px-8 w-full">
          <div className="relative aspect-square w-full">
            <Image image={image} alt={title} cover data-testid="ComparisonCardImage"/>
            { tag && (
              <Tag {...tag} className="absolute top-2 left-2"/>
            ) }
          </div>
        </div>
        <Spacer size="md" />
        <List className="w-full">
          { listItems.map((item) => (
            <ListItem key={item.title} className="flex items-center">
              <Icon size="small" icon={item.icon} className="flex-none"/>
              <Text display="subtitle" size={legacySizeCollectionMap.body2Xs}>{ item.title }</Text>
            </ListItem>
          )) }
        </List>
      </Column>
    </Grid>
  </div>
);

export default ComparisonCard;
