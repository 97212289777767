import { FC, useMemo } from "react";
import sanitizeHtml from "sanitize-html";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";

export interface IHighlighterCalloutProps {
  title: string;
  body: string;
  theme?: Theme;
}

enum EHighlightLetterState {
  YES,
  NO,
}

const HighlighterCallout: FC<IHighlighterCalloutProps> = ({ title, body, theme = "light" }) => {
  const highlightedTitle = useMemo(() => {
    const sanitizedTitle = sanitizeHtml(title);
    let returnedTitle = sanitizedTitle;
    const highlightIndicator = "`";
    const spaceCharacter = "&nbsp;<wbr/>";

    const splitTitle = returnedTitle.split("").map((character) => (character === " " ? spaceCharacter : character));
    const wrappedTitle: string[] = [];

    let shouldHighlight = EHighlightLetterState.YES;
    for (let character = 0; character <= splitTitle.length - 1; character += 1) {
      const currentCharacter = splitTitle[character];
      const isCurrentCharacterHighlightIndicator = currentCharacter === highlightIndicator;

      if (isCurrentCharacterHighlightIndicator) {
        shouldHighlight = shouldHighlight === EHighlightLetterState.NO ?
          EHighlightLetterState.YES :
          EHighlightLetterState.NO;
      } else {
        const isCurrentCharacterASpace = currentCharacter === spaceCharacter;
        wrappedTitle.push(shouldHighlight ? `<span
                class='inline-block relative before:z-0 before:absolute before:left-0 before:top-0 before:bg-brand before:block before:w-full before:h-full'><span class='relative'>${isCurrentCharacterASpace ? "&nbsp;" : currentCharacter}</span>${isCurrentCharacterASpace ? "</span><wbr/>" : "</span>"}` : currentCharacter);
      }
    }
    returnedTitle = wrappedTitle.join("");

    return returnedTitle;
  }, [ title ]);
  return (
    <div data-component={ HighlighterCallout.name } className={ `${theme === "default" ? null : "p-4"} ${themeRootClassMap[theme]}` }>
      <TextSubtitle
        tag="h3"
        size={ 2 }
        whitespace="noWrap"
        data-testid="HighlighterCalloutTitle"
      >
        <span dangerouslySetInnerHTML={ { __html: highlightedTitle } }></span>
      </TextSubtitle>
      <Spacer size="md" />
      <TextBody size={ 3 } data-testid="HighlighterCalloutBody">{ body }</TextBody>
    </div>
  );
};

export default HighlighterCallout;
