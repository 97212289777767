import { FC, memo } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  AvailableTag,
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export type Direction = "default" | "reverse";

export interface IImageTextItem {
  image: IImageProps;
  title: string;
  titleDisplay?: Display;
  body?: string;
}

export interface IImageTextListItemRow {
  item: IImageTextItem;
  itemTitleTag: AvailableTag;
  direction: Direction;
}

const ImageTextListItemRow: FC<IImageTextListItemRow> = ({ item, itemTitleTag = "h3", direction = "default" }) => {

  const order = direction === "default" ? { image: "lg:order-first lg:col-start-2", text: "lg:order-last" } : { image: "lg:order-last", text: "lg:order-first lg:col-start-2" };

  return (
    <Column component="ImageTextListItemRow" tag="li" key={item.title}>
      <Grid gap="lg">
        <Column spans={{ lg: 5 }} className={order.image}>
          <Image image={item.image} alt={item.title} />
        </Column>
        <Column spans={{ lg: 5 }} className={order.text} justify="center">
          <Text tag={itemTitleTag} display={item.titleDisplay || "title"} size={legacySizeCollectionMap.titleMd}>{ item.title }</Text>
          { item.body && (
            <>
              <Spacer size="md" />
              <Text size={legacySizeCollectionMap.bodyLg}>{ item.body }</Text>
            </>
          ) }
          <Spacer size="xl" className="lg:hidden" />
        </Column>
      </Grid>
    </Column>
  );
};

export default memo(ImageTextListItemRow);
