import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";

export interface ISectionQuote {
  quote: string;
  author: string;
  cite?: string;
  theme?: Theme;
  ctas?: ILink[];
}

const SectionQuote: FC<ISectionQuote> = ({ quote, author, cite, theme = "light-grey", ctas }) => (
  <Section component="SectionQuote" theme={theme} size="xl">
    <Container>
      <Grid>
        <Column>
          <blockquote>
            <Text display="subtitle" align="center" size={legacySizeCollectionMap.titleMd} whitespace={{ lg: "preWrap" }}>&ldquo;{ quote }&rdquo;</Text>
            <Spacer size="xl" />
            <footer>
              <Text size={legacySizeCollectionMap.bodyLg} align="center">
                { author } { cite && (<><cite title={cite} className="not-italic">{ cite }</cite></>) }
              </Text>
            </footer>
          </blockquote>
          { !!ctas?.length && (
            <>
              <Spacer size="xl" />
              <div className="flex justify-center">
                { ctas.map((cta) => (
                  <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2">
                    { cta.name }
                  </Button>
                )) }
              </div>
            </>
          ) }
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionQuote;
