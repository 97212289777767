import { FC, memo } from "react";

import { AvailableTag } from "apps/website/components/base/List/List.map";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";

import StickyNoteListItem, {
  IStickyNoteListItem,
} from "./StickyNoteListItem/StickyNoteListItem";
import { reduceGapClassMap } from "./StickyNoteList.map";

export interface IStickyNoteList {
  tag?: AvailableTag;
  items: IStickyNoteListItem[];
}

const StickyNoteList: FC<IStickyNoteList> = ({ tag = "ul", items }) => (
  <Grid component="StickyNoteList" tag={tag} gap="none">
    { items.map((item, index) => {
      const titlePrefix = tag === "ol" ? `${index + 1}.` : "";
      return (
        <Column tag="li" key={item.title} className={`transform ${index > 0 && (reduceGapClassMap[item.reduceGap ?? "default"])} ${index % 2 === 0 ? "-rotate-2 lg:translate-x-4" : "rotate-2 lg:-translate-x-4"}`}>
          <Grid gap="none">
            <Column spans={{ lg: 6 }} offset={{ lg: index % 2 === 0 ? undefined : 7 }}>
              <StickyNoteListItem
                title={`${titlePrefix} ${item.title}`}
                body={item.body}
                theme={item.theme}
                image={item.image}
              />
            </Column>
          </Grid>
        </Column>
      );
    }) }
  </Grid>

);

export default memo(StickyNoteList);
