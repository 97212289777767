import {
  BreakpointSpan,
} from "apps/website/components/layout/Column/Column.map";

export type Columns = "[1,3]" | "[1,5]" | "[2,4]" | "[2,6]" | "[3,3]";

export const columnSpanObjectMap: Record<Columns, BreakpointSpan> = {
  "[1,3]": { default: 12, lg: 4 },
  "[1,5]": { default: 12, lg: 6 }, // It's a 12 column grid
  "[2,4]": { default: 6, lg: 3 },
  "[2,6]": { default: 6, lg: 2 },
  "[3,3]": { default: 4, lg: 4 },
};
