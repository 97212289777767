import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import CatCard, {
  ICatCard,
} from "apps/website/components/feature/Card/CatCard/CatCard";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";

export interface ICatList {
  title?: string;
  subtitle?: string;
  theme?: Theme;
  items: ICatCard[];
  cta?: ILink;
}

const SectionCatList: FC<ICatList> = ({ title, subtitle, theme = "default", items, cta }) => (
  <Section component="SectionCatList" theme={theme}>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
            { title }
          </Text>
          <Spacer size="xl" />
        </>
      ) }
      <Grid>
        <Column spans={{ lg: 10 }} offset={{ lg: 2 }}>
          { subtitle && (
            <Text display="subtitle" size={legacySizeCollectionMap.titleMd} align="center" whitespace={{ lg: "preWrap" }}>{ subtitle }</Text>
          ) }
          <Spacer size="xl" />
        </Column>
      </Grid>
      <Grid>
        <>
          { items.map((cat) => (
            <Column key={cat.title} spans={{ default: 6, lg: 3 }}>
              <CatCard {...cat} />
            </Column>
          )) }
          { cta && (
            <Column align="center">
              <Spacer size="xl" />
              <Button to={cta.to} design={cta.design} color={cta.color} data-testid={cta.testId}>{ cta.name }</Button>
            </Column>
          ) }
        </>
      </Grid>
    </Container>
  </Section>
);

export default SectionCatList;
