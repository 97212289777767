export type TDirection = "UP" | "DOWN";
export type TSize = "default" | "xs";

export const directionClassMap: Record<TDirection, string> = {
  UP: "",
  DOWN: "rotate-[180deg]",
};
export const sizeClassMap: Record<TSize, string> = {
  default: "border-r-[16px] border-b-[12px] border-l-[16px] lg:border-r-[40px] lg:border-b-[30px] lg:border-l-[40px]",
  xs: "border-r-[8px] border-b-[6px] border-l-[8px] lg:border-r-[16px] lg:border-b-[12px] lg:border-l-[16px] ",
};
