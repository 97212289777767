import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export interface ILabelText {
  title: string;
  body: string;
  labelPrefix?: string;
  label: string;
  labelSuffix?: string;
  theme?: Theme;
}

const LabelText: FC<ILabelText> = ({ title, body, labelPrefix, label, labelSuffix, theme = "light-grey" }) => {

  const getLabelTheme = (): Theme => {
    if ([ "dark", "footer" ].includes(theme)) return "light-grey";
    if ([ "dark-alt" ].includes(theme)) return "light";
    return "dark";
  };

  return (
    <Grid component={LabelText.name} gap="lg">
      <Column spans={{ lg: 6 }}>
        <Text tag="h2" size={legacySizeCollectionMap.titleLg} display="title">{ title }</Text>
        <Spacer size="lg" />
        <Text size={legacySizeCollectionMap.bodyLg}>{ body }</Text>
      </Column>
      <Column spans={{ lg: 6 }}>
        { labelPrefix && (
          <>
            <Grid>
              <Column spans={6} align="center" offset={4}>
                <Text tag="h3" size={legacySizeCollectionMap.titleSm} display="subtitle" align="center">{ labelPrefix }</Text>
              </Column>
            </Grid>
            <Spacer size="lg" />
          </>
        ) }
        <div data-theme={getLabelTheme()} className={`flex h-full justify-center items-center p-4 transform -rotate-2 ${themeRootClassMap[getLabelTheme()]}`}>
          <Text size={legacySizeCollectionMap.base} className="uppercase" align="center">{ label }</Text>
        </div>
        { labelSuffix && (
          <>
            <Spacer size="lg" />
            <Grid>
              <Column spans={6} align="center" offset={4}>
                <Text tag="p" size={legacySizeCollectionMap.titleSm} display="subtitle" align="center">{ labelSuffix }</Text>
              </Column>
            </Grid>
          </>
        ) }
      </Column>
    </Grid>
  );
};

export default LabelText;
