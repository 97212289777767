import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

export interface IStoryBlokArticleRichText {
  content: string;
}

export const StoryBlokArticleRichText = ({ blok }: IStoryBlokComponent<IStoryBlokArticleRichText>) => (
  <>
    <Text tag="div">{ renderStoryBlokRichText(blok.content) }</Text>
    <Spacer size="xl" />
  </>
);
