import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import Logo from "apps/website/components/base/Icon/Logo/Logo";
import Text from "apps/website/components/base/Text/Text";
import { Justify } from "apps/website/maps/Flex.map";
import Splash from
  "apps/website/components/section/SectionSplash/SectionSplash";
import {
  IAdvancedImages,
  IImageProps,
} from "apps/website/components/base/Image/Image";
import {
  SplashLayout,
  ISplashSubtitle,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";

export interface ISectionHeroSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  ctas?: ILink[];
  theme?: Theme;
  image?: IImageProps;
  advancedImages?: IAdvancedImages;
  disableImageLazyLoading?: boolean;
  withLogo?: boolean;
  justify?: Justify;
  layout?: SplashLayout;
  logoBackground?: Theme;
}

const SectionHeroSplash: FC<ISectionHeroSplash> = ({ title, subtitle, ctas, image, advancedImages, disableImageLazyLoading = false, theme = "brand-alt", withLogo = false, justify = "center", layout, logoBackground = "default" }) => (
  <div data-component="SectionHeroSplash" data-theme={theme} className={`${withLogo ? `${themeRootClassMap[logoBackground]}` : undefined}`}>
    <Splash
      title={title}
      subtitle={subtitle}
      theme={theme}
      containImage={false}
      image={image}
      advancedImages={advancedImages}
      disableImageLazyLoading={disableImageLazyLoading}
      ctas={ctas}
      justify={justify}
      size="fullScreen"
      layout={layout}
    />
    { withLogo && (
      <Text tag="div" color="secondary" className="-mt-[12%] relative z-10">
        <Logo />
      </Text>
    ) }
  </div>
);

export default SectionHeroSplash;
