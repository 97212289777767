import {
  BreakpointSize,
  legacySizeCollectionMap,
} from "../../base/Text/Text.map";

export const sizeClassMap = {
  auto: "lg:h-auto",
  halfScreen: "lg:min-h-[50vh]",
  fullScreen: "lg:min-h-[85vh]",
};

interface FontSizeCollection {
  title: BreakpointSize;
  subtitle: BreakpointSize;
  body: BreakpointSize;
}

export type Direction = "default" | "reverse" | "reverseDesktop" | "reverseMobile";
export type Layout = "default" | "secondary" | "tertiary";

interface IImageTextOrder {
  image: string;
  text: string;
}

export const fontSizeMap: Record<FontSize, FontSizeCollection> = {
  default: {
    title: legacySizeCollectionMap.titleLg,
    subtitle: legacySizeCollectionMap.titleMd,
    body: legacySizeCollectionMap.bodyLg,
  },
  medium: {
    title: legacySizeCollectionMap.titleMd,
    subtitle: legacySizeCollectionMap.titleSm,
    body: { default: "xs" },
  },
  small: {
    title: legacySizeCollectionMap.titleMd,
    subtitle: legacySizeCollectionMap.titleSm,
    body: legacySizeCollectionMap.base,
  },
};

export const orderMap: Record<Direction, IImageTextOrder> = {
  default: { image: "order-first", text: "order-last" },
  reverse: { image: "order-last", text: "order-first" },
  reverseDesktop: { image: "order-first xl:order-last", text: "order-last xl:order-first" },
  reverseMobile: { image: "xl:order-first order-last", text: "xl:order-last order-first" },
};

export const absoluteImageMap: Record<Direction, string> = {
  default: "left-0",
  reverse: "right-0",
  reverseDesktop: "xl:right-0",
  reverseMobile: "left-0 xl:right-0",
};

export type Size = keyof typeof sizeClassMap;
export type FontSize = "default" | "medium" | "small";
