import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme } from "apps/website/maps/Theme.map";

export interface IHoverListItem {
  title: string;
  body: string;
  image: IImageProps;
  theme?: Theme;
}

const HoverListItem: FC<IHoverListItem> = ({ title, body, image, theme = "brand" }) => (
  <Column
    component="HoverListItem"
    tag="li"
    data-theme={theme}
    className="p-8 relative group overflow-hidden bg-brand"
    spans={{ lg: 4 }}
  >
    <div className="transform scale-150 group-hover:scale-100 opacity-0 group-hover:opacity-100 transition-all duration-500 origin-top-left" data-theme="theme-inherit">
      <Text tag="h3" display="subtitle" size={legacySizeCollectionMap.titleMd} className="text-inherit transition-all duration-500">{ title }</Text>
    </div>
    <Spacer size="md" />
    <Text size={legacySizeCollectionMap.bodyLg} className="opacity-0 group-hover:opacity-100 transition-all duration-500">{ body }</Text>
    <div className="absolute h-full w-full top-0 left-0 opacity-100 group-hover:opacity-0 transition-opacity duration-500">
      <Image image={image} alt={title} cover />
      <div className="absolute bg-black bg-opacity-20 top-0 left-0 h-full w-full" />
    </div>
    <div className="text-brand group-hover:text-black absolute top-0 left-0 p-8 transform scale-100 group-hover:scale-0 transition-all opacity-100 group-hover:opacity-0 duration-500 origin-top-left" data-theme="theme-inherit">
      <Text tag="span" display="title" size={legacySizeCollectionMap.titleLg} className="relative text-inherit">{ title }</Text>
    </div>
  </Column>
);

export default HoverListItem;
