import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import TextBody from "../../base/Text/TextBody/TextBody";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import Spacer from "../../layout/Spacer/Spacer";
import TrustPilotStars from "../../misc/TrustPilotStars";

export interface IStandAloneReviewProps {
  theme?: Theme;
  author: string;
  body: string;
}

const StandAloneReview: FC<IStandAloneReviewProps> = ({ theme = "dark", author, body }) => (
  <div data-component={ StandAloneReview.name } className={ `pb-2 -rotate-1 ${themeRootClassMap.brand}` }>
    <Grid gap="none">
      <Column>
        <div
          data-theme={ theme }
          className={ `p-4 ${themeRootClassMap[theme]}` }
        >
          <TextBody align="center" size={ 2 } data-testid="StandAloneReviewBody">
            &ldquo;{ body }&rdquo;
          </TextBody>
          <Spacer size="md" />
          <TextSubtitle tag="h3" size={ 2 } align="center" data-testid="StandAloneReviewAuthor">
            { author }
          </TextSubtitle>
          <Spacer size="md" />
          <div className="max-w-[140px] max-w-[220px] mx-auto">
            <TrustPilotStars rating={ 5 } />
          </div>
        </div>
      </Column>
    </Grid>
  </div>
);

export default StandAloneReview;
