import { FC, memo } from "react";

import { WithTestID } from "apps/website/types";
import {
  AvailableTag as TitleTag,
} from "apps/website/components/base/Text/Text.map";
import { Theme } from "apps/website/maps/Theme.map";
import { BreakpointDirection, Direction } from "apps/website/maps/Flex.map";

import { AvailableTag } from "../../base/List/List.map";

import {
  IInstructionCardItem,
  getCardLayoutDirection,
  getPartClassesFromDirection,
  listClasses,
} from "./InstructionCardList.map";
import InstructionCardItem from "./InstructionCardItem/InstructionCardItem";

export interface IInstructionCardsListProps extends WithTestID {
  arrows?: boolean;
  items: IInstructionCardItem[];
  itemTitleTag?: TitleTag;
  direction?: Direction | BreakpointDirection;
  listTag?: AvailableTag;
  cardTheme?: Theme;
}

const InstructionCardsList: FC<IInstructionCardsListProps> = ({
  "data-testid": testId,
  arrows = true,
  items,
  itemTitleTag,
  listTag = "ul",
  cardTheme,
  direction = "row",
}) => {

  const Tag = listTag;

  return (
    <div
      data-testid={testId}
      data-component={InstructionCardsList.name}
    >
      <Tag className={`flex ${getPartClassesFromDirection(listClasses, direction)}`}>
        { items.map((item, index) => (
          <InstructionCardItem
            theme={cardTheme}
            key={`${index}${item.title}`}
            index={listTag === "ol" ? (index + 1) : undefined}
            titleTag={itemTitleTag}
            showArrow={arrows && index !== items.length - 1}
            direction={getCardLayoutDirection(direction)}
            reverse={index % 2 !== 0 && listTag === "ul"}
            {...item}
          />
        )) }
      </Tag>
    </div>
  );
};

export default memo(InstructionCardsList);
