import { FC } from "react";

import { WithTestID } from "apps/website/types";

import MessageBox, {
  IMessageBoxProps,
} from "../../feature/MessageBox/MessageBox";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Section from "../../layout/Section/Section";

export interface ISectionMessageBoxProps extends IMessageBoxProps, WithTestID {}

const SectionMessageBox: FC<ISectionMessageBoxProps> = ({
  "data-testid": testId,
  ...otherProps
}) => (
  <Section
    component={SectionMessageBox.name}
    data-testid={testId}
    size="none"
  >
    <Container>
      <Grid>
        <MessageBox
          {...otherProps}
        />
      </Grid>
    </Container>
  </Section>
);

export default SectionMessageBox;
