import { Theme } from "apps/website/maps/Theme.map";
import {
  getStoryBlokAccordions,
} from "apps/website/utils/storyblok/accordions";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import ImageAccordionList from
  "apps/website/components/feature/ImageAccordionList/ImageAccordionList";
import ImageTextList from
  "apps/website/components/feature/ImageTextList/ImageTextList";
import {
  Columns,
} from "apps/website/components/feature/ImageTextList/ImageTextListItem/ImageTextListItemColumn.map";
import Image from "apps/website/components/base/Image/Image";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import FeatureWrapper from
  "apps/website/components/section/SectionFeatureWrapper/SectionFeatureWrapper";
import FeatureWrapperGroup from
  "apps/website/components/section/SectionFeatureWrapper/SectionFeatureWrapperGroup/SectionFeatureWrapperGroup";
import { ITitle } from "apps/website/components/page/component.types";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import ImageText from "apps/website/components/feature/ImageText/ImageText";
import IconTextList from
  "apps/website/components/feature/IconTextList/IconTextList";
import { getStoryBlokTableValue } from "apps/website/utils/storyblok/table";

import SectionColumnTable from "../../section/SectionTable/SectionColumnTable/SectionColumnTable";
import { IColumnTableRow } from "../../feature/Table/ColumnTable/ColumnTable";
import SectionRowColumnTable from "../../section/SectionTable/SectionRowColumnTable/SectionRowColumnTable";
import {
  IRowColumnTableRow,
} from "../../feature/Table/RowColumnTable/RowColumnTable";
import ImageCondensedList from "../../feature/ImageCondensedList/ImageCondensedList";

import { IStoryBlokImageTextListItem } from "./StoryBlokImageTextList";
import { IStoryBlokTable } from "./StoryBlokColumnTable";
import { IStoryBlokCondensedListItem } from "./StoryBlokCondensedList";

interface IStoryBlokFeatureWrapperChildren {
  component: "image_text_list_items" | "image" | "image_accordion_list_feature" | "image_text_feature" | "tables_feature";
  items?: IStoryBlokImageTextListItem[];
  columns: Columns;
}

interface IStoryBlokFeatureWrapper {
  theme: Theme;
  title: ITitle;
  body?: string;
  body_suffix?: string;
  subtitle: string;
  children: [IStoryBlokFeatureWrapperChildren]
  footer_title_prefix?: string;
  footer?: string;
  footer_body?: string;
  cta: IStoryBlokCta[];
  contain?: boolean;
  toggle_next_text?: string;
}

interface IStoryBlokFeatureWrapperGroup {
  feature_wrappers: IStoryBlokFeatureWrapper[];
  disable_toggle: boolean;
}

// TODO: Move this
export interface IStoryBlokIconTextListItem {
  image: IStoryBlokImage;
  title: string;
  body: string;
  cta: IStoryBlokCta[];
}

export interface IStoryBlokTableFeature {
  title: string;
  type: "column_table" | "column_row_table";
  table: IStoryBlokTable;
}

type TFeatureWrapperImagePadding = "default" | "medium" | "large";

const FEATURE_WRAPPER_IMAGE_PADDING_MAP: Record<TFeatureWrapperImagePadding, string> = {
  default: "lg:px-20",
  medium: "lg:px-36",
  large: "lg:px-36 xl:px-48",
};

// TODO: better typing
const getFeatureWrapperChildren = (child: any) => { // eslint-disable-line
  if (child?.component === "image_text_list_items" && !!child.items) {
    return (
      <ImageTextList items={child.items.map((item: IStoryBlokImageTextListItem) => ({
        image: getStoryBlokImage(item.image, !item.is_image_animated, ImageMax.HALF_CONTAINER_SM),
        title: item.title,
        body: item.body,
        cta: getStoryBlokCta(item.cta?.[0]),
      }))}
      listType={child.list_type || "ul"}
      columns={child.columns}
      />
    );
  }
  if (child?.component === "image_condensed_list") {
    return (
      <ImageCondensedList
        title={child.title}
        body={renderStoryBlokRichText(child.body)}
        image={getStoryBlokImage(child.image, true, ImageMax.HALF_CONTAINER_2XL)}
        direction={child.direction}
        condensedList={child.condensed_list[0].items.map((listItem: IStoryBlokCondensedListItem) => ({
          ...listItem,
          image: getStoryBlokImage(listItem.image, true, ImageMax.ICON_SM),
        }))}
      />
    );
  }
  if (child?.component === "icon_text_list_items" && !!child.items) {
    return (
      <IconTextList items={child.items.map((item: IStoryBlokIconTextListItem) => ({
        image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_SM),
        title: item.title,
        body: item.body,
        cta: getStoryBlokCta(item.cta?.[0]),
      }))}
      listType={child.list_type || "ul"}
      columns={child.columns}
      />
    );
  }
  if (child?.component === "image") {
    return (
      <div className={FEATURE_WRAPPER_IMAGE_PADDING_MAP[(child.padding || "default") as TFeatureWrapperImagePadding] || FEATURE_WRAPPER_IMAGE_PADDING_MAP.default }>
        <Image image={getStoryBlokImage(child.image, true, ImageMax.CONTAINER_2XL)} alt={child.image.alt} />
      </div>
    );
  }
  if (child?.component === "image_accordion_list_feature") {
    return (
      <ImageAccordionList
        title={child.title}
        image={getStoryBlokImage(child.image, true, ImageMax.HALF_CONTAINER_2XL)}
        direction={child.direction}
        accordionList={getStoryBlokAccordions(child.accordion_list)}
      />
    );
  }
  if (child?.component === "image_text_feature") {
    return (
      <ImageText
        title={child.title}
        subtitle={child.subtitle}
        body={renderStoryBlokRichText(child.body)}
        footer={child.footer}
        cta={child.ctas.map((cta: IStoryBlokCta) => getStoryBlokCta(cta))}
        image={getStoryBlokImage(child.image, true, ImageMax.HALF_CONTAINER_2XL)}
        direction={child.direction}
      />
    );
  }
  if (child?.component === "tables") {
    return (
      <div className="lg:px-20">
        { child.tables.map((table: IStoryBlokTableFeature) => (
          <>
            { table.type === "column_table" && (
              <>
                <SectionColumnTable
                  theme="default"
                  title={table.title}
                  table={{
                    titles: table.table.thead.map((title) => ({
                      id: title._uid,
                      name: title.value,
                    })),
                    rows: table.table.tbody.map((row) => ({
                      values: row.body.map((cell) => (
                        getStoryBlokTableValue(cell.value)
                      )),
                    })) as IColumnTableRow[],
                  }}
                />
              </>
            ) }
            { table.type === "column_row_table" && (
              <>
                <SectionRowColumnTable
                  title={table.title}
                  table={{
                    titles: table.table.thead.map((title) => ({
                      id: title._uid,
                      name: title.value,
                    })),
                    rows: table.table.tbody.map((row) => ({
                      values: row.body.map((cell) => (
                        getStoryBlokTableValue(cell.value)
                      )),
                    })) as IRowColumnTableRow[],
                  }}
                />
              </>
            ) }
          </>
        )) }
      </div>
    );
  }
};

export const StoryBlokFeatureWrapper = ({ blok }: IStoryBlokComponent<IStoryBlokFeatureWrapper>) => {

  const { children } = blok;
  const child = children[0];

  return (
    <>
      <FeatureWrapper
        title={blok.title}
        subtitle={blok.subtitle}
        body={blok.body}
        bodySuffix={blok.body_suffix}
        theme={blok.theme}
        footerTitlePrefix={blok.footer_title_prefix}
        footer={blok.footer}
        footerBody={blok.footer_body}
        cta={getStoryBlokCta(blok.cta?.[0])}
      >
        { getFeatureWrapperChildren(child) }
      </FeatureWrapper>
    </>
  );
};

export const StoryBlokFeatureWrapperGroup = ({ blok }: IStoryBlokComponent<IStoryBlokFeatureWrapperGroup>) => {

  const items = blok.feature_wrappers.map((featureWrapper) => ({
    title: featureWrapper.title,
    subtitle: featureWrapper.subtitle,
    body: featureWrapper.body,
    bodySuffix: featureWrapper.body_suffix,
    theme: featureWrapper.theme,
    footerPrefix: featureWrapper.footer_title_prefix,
    footer: featureWrapper.footer,
    footerBody: featureWrapper.footer_body,
    cta: getStoryBlokCta(featureWrapper.cta?.[0]),
    toggleSiblingText: featureWrapper.toggle_next_text,
    children: getFeatureWrapperChildren(featureWrapper.children[0]),
    contain: featureWrapper.contain,
    disableToggle: blok.disable_toggle,
  }));

  return (
    <FeatureWrapperGroup
      items={items}
    />
  );
};
