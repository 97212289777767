import { FC } from "react";
import Link from "next/link";

import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Icon from "apps/website/components/base/Icon/Icon";
import ExternalReviewCarousel, {
  IExternalReviewCarousel,
} from "apps/website/components/feature/Carousel/ExternalReviewCarousel/ExternalReviewCarousel";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import { Layout } from "apps/website/components/feature/Review/Review.map";

export interface ICarouselBanner {
  show: boolean;
  title: string;
  link: string;
  linkText: string;
  layout?: Layout;
}

export interface ISectionExternalReviewCarousel extends IExternalReviewCarousel {
  banner: ICarouselBanner
}

const SectionExternalReviewCarousel: FC<ISectionExternalReviewCarousel> = ({ items, layout, banner }) => (
  <Section component="SectionExternalReviewCarousel">
    <Container>
      <ExternalReviewCarousel items={items} layout={layout} />
    </Container>
    { banner.show && (
      <>
        <Spacer size="3xl" />
        <div className="w-full bg-brand transform -rotate-1 py-6">
          <Container>
            <Grid>
              <Column direction="row" justify="between" align="center">
                <Text display="title" tag="span" size="md">{ banner.title }</Text>
                <Text display="title" tag="span">
                  <Link href={banner.link} className="flex items-center">

                    { banner.linkText }
                    <Icon icon="arrowRight" />

                  </Link>
                </Text>
              </Column>
            </Grid>
          </Container>
        </div>
        <Spacer size="3xl" />
      </>
    ) }
  </Section>
);

export default SectionExternalReviewCarousel;
