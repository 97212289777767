import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionFoodPortionCalculator from "../../section/SectionFoodPortionCalculator/SectionFoodPortionCalculator";

export interface IStoryBlokFoodPortionCalculatorProps {
  title: string;
  body: string;
  theme: Theme;
}

export const StoryBlokFoodPortionCalculator = ({ blok }: IStoryBlokComponent<IStoryBlokFoodPortionCalculatorProps>) => (
  <SectionFoodPortionCalculator
    title={blok.title}
    body={renderStoryBlokRichText(blok.body)}
    theme={blok.theme}
  />
);
