import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export const freeFlowTitleSizeMap: Record<AvailableTag, keyof typeof legacySizeCollectionMap> = {
  h1: "titleLg",
  h2: "titleMd",
  h3: "titleSm",
  h4: "base",
};

export type Size = keyof typeof freeFlowTitleSizeMap;
export type AvailableTag = "h1" | "h2" | "h3" | "h4";
export type AvailableSize = typeof freeFlowTitleSizeMap[keyof typeof freeFlowTitleSizeMap];
