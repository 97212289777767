import { FC, memo } from "react";

import StickyNoteList, {
  IStickyNoteList,
} from "apps/website/components/feature/StickyNoteList/StickyNoteList";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";

export interface ISectionStickyNoteList extends IStickyNoteList {
  title: string;
  cta?: ILink
}

const SectionStickyNoteList: FC<ISectionStickyNoteList> = ({ title, tag, items, cta }) => (
  <Section component="SectionStickyNoteList" size="none">
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
            { title }
          </Text>
          <Spacer size="xl" />
        </>
      ) }
      <StickyNoteList tag={tag} items={items} />
      <Spacer size="xl" />
      { cta && (
        <div className="flex justify-center">
          <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId}>
            { cta.name }
          </Button>
        </div>
      ) }
    </Container>
  </Section>
);

export default memo(SectionStickyNoteList);
