import {
  IStoryBlokImage,
  IStoryBlokVideo,
} from "apps/website/components/page/api.types";

const VIDEO_FORMATS = [ "mp4" ];

export const getStoryBlokVideo = (video: IStoryBlokVideo) => video.filename;

export const isStoryBlokVideo = (asset: IStoryBlokVideo | IStoryBlokImage) => {
  const filenameParts = asset?.filename?.split(".");
  if (!filenameParts) return false;
  if (VIDEO_FORMATS.includes(filenameParts[filenameParts.length - 1])) {
    return true;
  }
  return false;
};
