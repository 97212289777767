import { Suspense } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionQueryStringBanner from "../../section/SectionQueryStringBanner/SectionQueryStringBanner";
import { IconSvg } from "../../base/Icon/Icon.map";

export interface IStoryBlokQueryBannerItem {
  icon: IconSvg;
  text: string;
  theme: Theme;
  query_value: string;
  query_key: string;
}

export interface IStoryBlokQueryBanner {
  banners: IStoryBlokQueryBannerItem[];
}

export const StoryBlokQueryStringBanner = ({ blok }: IStoryBlokComponent<IStoryBlokQueryBanner>) => (
  <Suspense>
    <SectionQueryStringBanner
      items={blok.banners.map((banner) => ({
        ...banner,
        conditionValue: banner.query_value,
        conditionKey: banner.query_key,
        children: banner.text,
      }))}
    />
  </Suspense>
);
