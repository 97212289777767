import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";

import SectionMessageBox from "../../../section/SectionMessageBox/SectionMessageBox";
import { IStoryBlokMessageBox } from "../StoryBlokMessageBox";

export const StoryBlokFormMessageBox = (
  { flowId, blok, linkingId, interpolation }: IStoryBlokComponent<IStoryBlokMessageBox>,
) => (
  <SectionMessageBox
    {...blok}
    text={interpolateString(
      blok.text,
      getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", linkingId || "0", linkingId ? interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
      Interpolator.FRONTEND,
    )}
  />
);
