import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { AvailableRotation, rotationMap } from "apps/website/maps/Rotation.map";

import { AvailableTag } from "../../base/Text/Text.map";
import Text from "../../base/Text/Text";
import Spacer from "../../layout/Spacer/Spacer";
import Column from "../../layout/Column/Column";

export interface IMessageBoxProps {
  title: string;
  titleTag?: AvailableTag;
  text: string;
  rotation?: AvailableRotation;
  theme?: Theme;
  className?: string;
}

const MessageBox: FC<IMessageBoxProps> = ({
  title,
  titleTag = "h2",
  text,
  rotation,
  theme = "default",
  className = "",
}) => (
  <Column
    component={MessageBox.name}
    className={`flex flex-col px-2 py-4 xl:py-8 xl:px-4 items-center ${themeRootClassMap[theme]} ${className}`}
    spans={{ default: 12, xl: 6 }}
    offset={{ default: 1, xl: 4 }}
  >
    <div
      className={`p-1 w-fit transform ${rotationMap[rotation ?? 0]} ${themeRootClassMap.dark}`}
      data-theme="dark"
    >
      <Text
        tag={titleTag}
        display="title"
        color="secondary"
        size={"lg"}
        align="center"
      >{ title }</Text>
    </div>
    <Spacer size="lg" />
    <Text
      align="center"
      size="sm"
    >
      { text }
    </Text>
  </Column>
);

export default MessageBox;
