import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { getFlowFieldValuesForInterpolation } from "@./state";

import {
  IStoryBlokMicroGallery,
  StoryBlokMicroGallery,
} from "../StoryBlokMicroGallery";

export const StoryBlokFormMicroGallery = (component: IStoryBlokComponent<IStoryBlokMicroGallery>) => {

  const storyBlokMicroGalleryArgs: IStoryBlokComponent<IStoryBlokMicroGallery> = {
    blok: {
      ...component.blok,
      richtextOptions: {
        interpolation: {
          values: getFlowFieldValuesForInterpolation(component.flowId ?? "UNKNOWN", component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        },
      },
    },
  };

  return (
    <StoryBlokMicroGallery {...storyBlokMicroGalleryArgs} />
  );
};
