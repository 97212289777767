import {
  ITableTitle,
} from "apps/website/components/feature/Table/ColumnRowTable/ColumnRowTable";
import { BRANDS } from "apps/website/constants/brands";

export const brandTitles: ITableTitle[] = [
  { id: "KATKIN", name: BRANDS.KATKIN },
  { id: "LILYS_KITCHEN", name: BRANDS.LILYS_KITCHEN },
  { id: "GORMET", name: BRANDS.GORMET },
  { id: "SHEBA", name: BRANDS.SHEBA },
  { id: "WHISKAS", name: BRANDS.WHISKAS },
  { id: "FELIX", name: BRANDS.FELIX },
];

export const brandComparisonTableData = {
  titles: brandTitles,
  rows: [
    {
      title: "100% Human-quality meats",
      values: [ true, false, false, false, false, false ],
    },
    {
      title: "Gently cooked for digestion",
      values: [ true, false, false, false, false, false ],
    },
    {
      title: "So fresh it's frozen",
      values: [ true, false, false, false, false, false ],
    },
    {
      title: "Free from preservatives",
      values: [ true, true, false, false, false, false ],
    },
    {
      title: "Free from sterilisation",
      values: [ true, false, false, false, false, false ],
    },
    {
      title: "Cooked in own kitchen",
      values: [ true, false, true, true, true, true ],
    },
    {
      title: "Perfectly portioned",
      values: [ true, false, false, false, false, false ],
    },
    {
      title: "From less than £2 a day",
      values: [ true, false, false, false, true, true ],
    },
  ],
};
