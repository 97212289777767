import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionConditionalCheckList from "../../section/SectionConditionalCheckList/SectionConditionalCheckList";
import { Display } from "../../base/Text/Text.map";

export interface IStoryBlokConditionalCheckListListItem {
  text: string;
  condition_key: string;
  condition_value: string;
  text_display: Display;
}

export interface IStoryBlokConditionalCheckListList {
  items: IStoryBlokConditionalCheckListListItem[];
  max_items: number;
}

export interface IStoryBlokConditionalCheckList {
  data?: Record<string, string | string[]>;
  title: string;
  checklist: [IStoryBlokConditionalCheckListList];
  theme: Theme;
}

export const StoryBlokConditionalCheckList = ({ blok }: IStoryBlokComponent<IStoryBlokConditionalCheckList>) => (
  <SectionConditionalCheckList
    title={blok.title}
    checklist={{
      data: blok.data || {},
      max: blok.checklist[0].max_items,
      items: blok.checklist[0].items.map((item) => ({
        condition: {
          key: item.condition_key,
          value: item.condition_value,
        },
        text: item.text,
        display: item.text_display || "default",
      })),
    }}
    theme={blok.theme}
  />
);
