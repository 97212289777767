import { FC } from "react";

import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";

import ComparisonCard, {
  IComparisonCardProps,
} from "./ComparisonCard/ComparisonCard";

export interface IComparisonCardListProps {
  cards: [IComparisonCardProps, IComparisonCardProps];
}

const ComparisonCardList: FC<IComparisonCardListProps> = ({ cards }) => (
  <Grid tag="ul" component={ComparisonCardList.name}>
    { cards.map((card, index) => (
      <Column key={card.title} tag="li" spans={{ default: 6, lg: 3 }} offset={index === 0 ? { lg: 4 } : undefined}>
        <ComparisonCard {...card} />
      </Column>
    )) }
  </Grid>
);

export default ComparisonCardList;
