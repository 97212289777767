import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import SectionAnimatedSplit from
  "apps/website/components/section/SectionAnimatedSplit/SectionAnimatedSplit";
import {
  Animation,
} from "apps/website/components/section/SectionAnimatedSplit/SectionAnimatedSplit.map";

export interface IStoryBlokAnimatedSplit {
  theme_top: Theme;
  theme_bottom: Theme;
  animation: Animation;
}

export const StoryBlokAnimatedSplit = ({ blok }: IStoryBlokComponent<IStoryBlokAnimatedSplit>) => (
  <SectionAnimatedSplit
    themeTop={blok.theme_top}
    themeBottom={blok.theme_bottom}
    animation={blok.animation}
  />
);
