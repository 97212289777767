import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionStandAloneReview from "../../section/SectionStandAloneReview/SectionStandAloneReview";

export interface IStoryBlokStandAloneReview {
  author: string;
  body: string;
  theme: Theme
}

export interface IStoryBlokSectionStandAloneReview {
  stand_alone_review: [IStoryBlokStandAloneReview]
  theme: Theme
}

export const StoryBlokStandAloneReview = ({ blok }: IStoryBlokComponent<IStoryBlokSectionStandAloneReview>) => (
  <SectionStandAloneReview
    review={ {
      author: blok.stand_alone_review[0].author,
      body: blok.stand_alone_review[0].body,
    } }
    theme={ blok.theme }
  />
);
