import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";

import { ImageSize, imageSizeClassMap } from "./IconCallout.map";

export interface IIconCalloutProps {
  image?: IImageProps;
  imageSize?: ImageSize;
  title?: string;
  body?: string;
  theme?: Theme;
}

const IconCallout: FC<IIconCalloutProps> = ({ image, imageSize = "default", title, body, theme = "light" }) => (
  <div data-component={IconCallout.name} data-theme={theme} className={`p-4 box-border flex justify-center items-center ${themeRootClassMap[theme]}`}>
    <>
      { image && (
        <div className={`flex flex-none justify-center items-center mr-4 ${imageSizeClassMap[imageSize]}`}>
          <Image image={image} alt={title ?? ""}/>
        </div>
      ) }
      <div className="flex-1">
        <Text tag="h2" display="title" size={legacySizeCollectionMap.titleSm}>{ title }</Text>
        <Spacer size="sm" />
        <Text size={legacySizeCollectionMap.base}>{ body }</Text>
      </div>
    </>
  </div>
);

export default IconCallout;
