import { FC } from "react";

import { AvailableTag } from "apps/website/components/base/List/List.map";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";

import HoverListItem, { IHoverListItem } from "./HoverListItem/HoverListItem";

export interface IHoverList {
  items: IHoverListItem[];
  listType?: AvailableTag;
}

const HoverList: FC<IHoverList> = ({ items, listType = "ul" }) => (
  <Container component="HoverList">
    <Grid tag={listType}>
      { items.map((item, index) => {
        const itemTitle = listType === "ol" ? `${index + 1}. ${item.title}` : item.title;
        return (
          <HoverListItem key={`hover-list-item-${index}`} title={itemTitle} body={item.body} image={item.image} />
        );
      }) }
    </Grid>
  </Container>
);

export default HoverList;
