import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  getStoryBlokImage,
  ImageMax,
} from "apps/website/utils/storyblok/image";

import SectionImageCarousel from "../../section/SectionImageCarousel/SectionImageCarousel";

export interface IStoryBlokSectionImageCarousel {
  images: IStoryBlokImage[];
}

export const StoryBlokImageCarousel = ({ blok }: IStoryBlokComponent<IStoryBlokSectionImageCarousel>) => (
  <SectionImageCarousel
    images={blok.images.map((image) => getStoryBlokImage(image, true, ImageMax.HALF_CONTAINER_MD))} />
);
