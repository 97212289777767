import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import SectionAdvert from
  "apps/website/components/section/SectionAdvert/SectionAdvert";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

export interface IStoryBlokAdvert {
  title: string;
  body: string;
  cta: [IStoryBlokCta];
  theme: Theme
  image: IStoryBlokImage;
}

export const StoryBlokAdvert = ({ blok }: IStoryBlokComponent<IStoryBlokAdvert>) => (
  <SectionAdvert
    title={blok.title}
    body={blok.body}
    cta={getStoryBlokCta(blok.cta?.[0])}
    image={getStoryBlokImage(blok.image, true, ImageMax.HALF_SCREEN)}
    theme={blok.theme}
  />
);
