import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { renderTableValue } from "apps/website/utils/content/table";
import Table from "apps/website/components/feature/Table/Table";
import {
  cellStyles,
  rowAltStyles,
  rowStyles,
  tableStyles,
  titleStyles,
  secondaryTitleStyles,
  valueStyles,
} from "apps/website/components/feature/Table/Table.map";
import Image, { IImage } from "apps/website/components/base/Image/Image";

// TODO: move ITableTitle
export interface ITableTableTitle {
  id: string;
  name: string;
  image?: IImage;
}

export interface IRowColumnTableRow {
  values:(boolean | string | [string, boolean])[];
}
export interface IRowColumnTable {
  component?: string,
  titles: ITableTableTitle[];
  rows: IRowColumnTableRow[];
}

const RowColumnTable: FC<IRowColumnTable> = ({ titles, rows, component = "RowColumnTable" }) => (
  <Table component={component} className={tableStyles}>
    <tr className={rowStyles}>
      { titles.map((title) => (
        <th key={title.id} scope="col" className={titleStyles}>
          { title.image ? (
            <div className="flex justify-center items-center w-full">
              <Image image={title.image.image} alt={title.image.alt} className="max-w-[100px]"/>
              <span className="sr-only">{ title.name }</span>
            </div>
          ) : (
            <Text tag="span" display="title" size={legacySizeCollectionMap.titleMd} whitespace={{ lg: "preWrap" }}>
              { title.name }
            </Text>
          ) }
        </th>
      )) }
    </tr>
    { rows.map((row, rowIndex) => (
      <tr key={`row-${rowIndex}`} className={`${rowStyles} ${rowIndex % 2 === 0 && rowAltStyles}`}>
        { row.values.map((value, valueIndex) => (
          <>
            { valueIndex === 0 ? (
              <th key={`title-${rowIndex}-${valueIndex}`} scope="row" className={secondaryTitleStyles}>
                <Text tag="span" display="subtitle" whitespace={{ lg: "preWrap" }}>
                  { renderTableValue(value) }
                </Text>
              </th>
            ) : (
              <td key={`value-${rowIndex}-${valueIndex}`} className={`${valueStyles} ${cellStyles}`}>
                { renderTableValue(value) }
              </td>
            ) }
          </>
        )) }
      </tr>
    )) }
  </Table>
);

export default RowColumnTable;
