"use client";

import { useCookies } from "react-cookie";
import { usePostHog } from "posthog-js/react";
import {
  LegacyRef,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DisplayState } from "@/constants/state";
import {
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  getActionTrackingInfo,
} from "apps/website/utils/tracking/getActionTrackingInfo";
import { useDiscountStore } from "@./state";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import useIntersectionObserver from "apps/website/hooks/useIntersectionObserver";
import { useDatadog } from "@auth/client-sdk-react";

import { IStoryBlokStickyCTA, StoryBlokStickyCTA } from "../StoryBlokStickyCTA";

type TIsActiveConditions = "default" | "formSectionValid" | "scrollDepthHit" | "scrollDepthHitFormSectionValid";
interface IStoryBlokFormStickyCtaBlok extends IStoryBlokStickyCTA {
  is_active_conditions?: TIsActiveConditions;
}
interface IStoryBlokFormStickyCta {
  canContinue?: boolean;
  blok: IStoryBlokFormStickyCtaBlok;
  flowId: string;
}

export const StoryBlokFormStickyCta = (component: IStoryBlokFormStickyCta) => {

  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ state, setState ] = useState<DisplayState>(DisplayState.READY);
  const [ scrollDepthHit, setScrollDepthHit ] = useState(false);
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);
  const { discountCodes } = useDiscountStore();
  const [ containerRef, isVisible ] = useIntersectionObserver();

  const formsActionsAPI = useAPI().FormActions;

  const { chainQueryParamsToUrl } = useQueryParams();

  const isDisabled = useMemo(() => {
    if (component.blok.disabled) return true;

    if (!component.blok.is_active_conditions || component.blok.is_active_conditions === "default") return false;
    if (component.blok.is_active_conditions === "formSectionValid" && !!component.canContinue) return false;
    if (component.blok.is_active_conditions === "scrollDepthHit" && !!scrollDepthHit) return false;
    if (component.blok.is_active_conditions === "scrollDepthHitFormSectionValid" && !!component.canContinue && !!scrollDepthHit) return false;

    return true;
  }, [ component.blok.disabled, component.canContinue, scrollDepthHit ]);

  const goToSuccessPage = () => {
    if (!component.blok.action_redirect?.url) return;
    window.location.href = chainQueryParamsToUrl(component.blok.action_redirect.url);
  };

  const performAction = async () => {

    if (!component.flowId || !component.blok.action) return;

    setState(DisplayState.PROCESSING);
    try {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(component.flowId);
      fieldSubmitValuesMap.set("discountCode", { value: discountCodes.find((dc) => dc.flowId === component.flowId)?.discountCode ?? "" });
      const resp = await formsActionsAPI.performAction<unknown>(
        component.blok.action,
        fieldSubmitValuesMap,
        getFlowProduct(component.flowId),
        component.flowId,
        undefined,
        featureFlags,
        getActionTrackingInfo(cookies),
        datadog.logger,
      );
      if (!resp.success) {
        setState(DisplayState.ERROR);
      } else {
        setState(DisplayState.COMPLETE);
        goToSuccessPage();
      }
    } catch (error) {
      datadog.logger.error("CTA Error", {
        component,
      }, error as Error);
      setState(DisplayState.ERROR);
    }
  };

  useEffect(() => {
    if (isVisible) {
      setScrollDepthHit(true);
    }
  }, [ isVisible ]);

  const formComponent: IStoryBlokComponent<IStoryBlokStickyCTA> = {
    blok: {
      ...component.blok,
      onClick: performAction,
      state,
      disabled: isDisabled,
    },
  };

  return (
    <>
      <div ref={ containerRef as LegacyRef<HTMLDivElement> }></div> { /* For intersection observer */ }
      <StoryBlokStickyCTA { ...formComponent } />
    </>
  );
};
