import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokAdvancedImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokPageCardPreview,
  IStoryBlokRelation,
} from "apps/website/components/page/api.types";
import CatList from
  "apps/website/components/section/SectionCatList/SectionCatList";
import {
  STORYBLOK_CAT_BREEDS_PRETTY_ROOT,
} from "apps/website/constants/storyblok";

export interface IStoryBlokCat {
  page_card_preview: IStoryBlokPageCardPreview;
}

export interface IStoryBlokCatList {
  title: string;
  subtitle: string;
  cats: IStoryBlokRelation<IStoryBlokCat>[];
  theme: Theme
  cta?: [IStoryBlokCta?];
}

export const StoryBlokCatList = ({ blok }: IStoryBlokComponent<IStoryBlokCatList>) => (
  <CatList
    title={blok.title}
    subtitle={blok.subtitle}
    items={blok.cats.map((cat) => ({
      title: cat.content.page_card_preview?.title || cat.name || "",
      image: cat.content.page_card_preview?.image
        ? getStoryBlokAdvancedImage(cat.content.page_card_preview?.image, ImageMax.HALF_CONTAINER_2XL)
        : { src: "/images/features/cat-card/no-image.webp", width: 800, height: 600 },
      link: `${STORYBLOK_CAT_BREEDS_PRETTY_ROOT}/${cat.slug}`,
    }))}
    theme={blok.theme}
    cta={blok.cta?.[0] ? getStoryBlokCta(blok.cta?.[0]) : undefined}
  />
);
