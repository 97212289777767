import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  getStoryBlokCta,
  getStoryBlokCtas,
} from "apps/website/utils/storyblok/links";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
  IStoryBlokRelation,
  IStoryBlokVideo,
} from "apps/website/components/page/api.types";
import ProductList from
  "apps/website/components/section/SectionProductList/SectionProductList";
import {
  getStoryBlokVideo,
  isStoryBlokVideo,
} from "apps/website/utils/storyblok/video";
import { CardCtaAction } from "apps/website/components/feature/Card/Card";

import { Display } from "../../base/Text/Text.map";

// TODO: This will move
export interface IStoryBlokFaq {
  title: string;
  content: string;
}

// TODO: This will move
export interface IStoryBlokCollection {
  name: string;
  theme: Theme;
}

export interface IStoryBlokAssociatedProductPage {
  full_slug: string;
}

// TODO: this should move to product slug
export interface IStoryBlokProduct {
  title: string;
  detailed_title?: string;
  tagline: string;
  description: string;
  details?: string;
  image: IStoryBlokImage;
  gallery?: IStoryBlokImage[];
  hover_media: IStoryBlokImage | IStoryBlokVideo;
  faqs: IStoryBlokFaq[];
  ctas: IStoryBlokCta[];
  ctaAction: CardCtaAction;
  associated_page: IStoryBlokAssociatedProductPage;
  collection: IStoryBlokRelation<IStoryBlokCollection>;
}

export interface IStoryBlokProductList {
  title: string;
  subtitle: string;
  subtitle_display: Display;
  products: IStoryBlokRelation<IStoryBlokProduct>[];
  theme: Theme
  cta?: [IStoryBlokCta?];
  card_cta_action?: CardCtaAction;
  card_show_detailed_title?: boolean;
}

export const StoryBlokProductListFAQContent = ({ content }: { content: string }) => renderStoryBlokRichText(content);

export const StoryBlokProductList = ({ blok }: IStoryBlokComponent<IStoryBlokProductList>) => (
  <ProductList
    title={blok.title}
    subtitle={blok.subtitle}
    subtitleDisplay={blok.subtitle_display}
    ctaAction={blok.card_cta_action}
    items={blok.products.map((product) => ({
      title: (blok.card_show_detailed_title && product.content.detailed_title)
        ? product.content.detailed_title
        : product.content.title,
      tagline: product.content.tagline,
      description: product.content.description,
      details: product.content.details,
      image: getStoryBlokImage(product.content.image, true, ImageMax.HALF_CONTAINER_2XL),
      hoverMedia: isStoryBlokVideo(product.content.hover_media)
        ? getStoryBlokVideo(product.content.hover_media)
        : getStoryBlokImage(product.content.hover_media, true, ImageMax.HALF_CONTAINER_2XL),
      gallery: product.content.gallery?.map((image) => getStoryBlokImage(image, true, ImageMax.CONTAINER_XL)),
      faqs: product.content.faqs.map((faq) => ({
        title: faq.title,
        Content: <StoryBlokProductListFAQContent content={faq.content} />,
      })),
      ctaAction: blok.card_cta_action,
      link: product.content?.associated_page?.full_slug?.replace("static/", ""),
      ctas: getStoryBlokCtas(product.content.ctas),
      tag: { text: product.content.collection.content?.name, theme: product.content.collection.content?.theme },
    }))}
    theme={blok.theme}
    cta={blok.cta?.[0] && getStoryBlokCta(blok.cta?.[0])}
  />
);
