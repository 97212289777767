import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";

import SectionQuote from "../../section/SectionQuote/SectionQuote";

interface IStoryBlokQuote {
  quote: string;
  author: string;
  cite?: string;
  theme?: Theme;
  ctas?: IStoryBlokCta[];
}

export const StoryBlokQuote = ({ blok }: IStoryBlokComponent<IStoryBlokQuote>) => (
  <SectionQuote
    quote={blok.quote}
    author={blok.author}
    cite={blok.cite}
    theme={blok.theme}
    ctas={getStoryBlokCtas(blok.ctas ?? [])}
  />
);
