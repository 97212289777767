import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionHorizontalRule from "../../section/SectionHorizontalRule/SectionHorizontalRule";

export interface IStoryHorizontalRule {
  text: string;
  theme: Theme
}

export const StoryBlokHorizontalRule = ({ blok }: IStoryBlokComponent<IStoryHorizontalRule>) => (
  <SectionHorizontalRule theme={blok.theme}>{ blok.text }</SectionHorizontalRule>
);
