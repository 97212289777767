import { Breakpoint, BreakpointMap } from "apps/website/maps/Breakpoint.map";

export const defaultSizeClassMap = {
  100: "w-[100px]",
  200: "w-[200px]",
  300: "w-[300px]",
  400: "w-[400px]",
  500: "w-[500px]",
  600: "w-[600px]",
  700: "w-[700px]",
  800: "w-[800px]",
  900: "w-[900px]",
  1000: "w-[1000px]",
  full: "w-full",
};

export const smallSizeClassMap = {
  100: "sm:w-[100px]",
  200: "sm:w-[200px]",
  300: "sm:w-[300px]",
  400: "sm:w-[400px]",
  500: "sm:w-[500px]",
  600: "sm:w-[600px]",
  700: "sm:w-[700px]",
  800: "sm:w-[800px]",
  900: "sm:w-[900px]",
  1000: "sm:w-[1000px]",
  full: "sm:w-full",
};

export const mediumSizeClassMap = {
  100: "md:w-[100px]",
  200: "md:w-[200px]",
  300: "md:w-[300px]",
  400: "md:w-[400px]",
  500: "md:w-[500px]",
  600: "md:w-[600px]",
  700: "md:w-[700px]",
  800: "md:w-[800px]",
  900: "md:w-[900px]",
  1000: "md:w-[1000px]",
  full: "md:w-full",
};

export const largeSizeClassMap = {
  100: "lg:w-[100px]",
  200: "lg:w-[200px]",
  300: "lg:w-[300px]",
  400: "lg:w-[400px]",
  500: "lg:w-[500px]",
  600: "lg:w-[600px]",
  700: "lg:w-[700px]",
  800: "lg:w-[800px]",
  900: "lg:w-[900px]",
  1000: "lg:w-[1000px]",
  full: "lg:w-full",
};

export const extraLargeSizeClassMap = {
  100: "xl:w-[100px]",
  200: "xl:w-[200px]",
  300: "xl:w-[300px]",
  400: "xl:w-[400px]",
  500: "xl:w-[500px]",
  600: "xl:w-[600px]",
  700: "xl:w-[700px]",
  800: "xl:w-[800px]",
  900: "xl:w-[900px]",
  1000: "xl:w-[1000px]",
  full: "xl:w-full",
};

export const twoExtraLargeSizeClassMap = {
  100: "2xl:w-[100px]",
  200: "2xl:w-[200px]",
  300: "2xl:w-[300px]",
  400: "2xl:w-[400px]",
  500: "2xl:w-[500px]",
  600: "2xl:w-[600px]",
  700: "2xl:w-[700px]",
  800: "2xl:w-[800px]",
  900: "2xl:w-[900px]",
  1000: "2xl:w-[1000px]",
  full: "2xl:w-full",
};

export const threeExtraLargeSizeClassMap = {
  100: "3xl:w-[100px]",
  200: "3xl:w-[200px]",
  300: "3xl:w-[300px]",
  400: "3xl:w-[400px]",
  500: "3xl:w-[500px]",
  600: "3xl:w-[600px]",
  700: "3xl:w-[700px]",
  800: "3xl:w-[800px]",
  900: "3xl:w-[900px]",
  1000: "3xl:w-[1000px]",
  full: "3xl:w-full",
};

export const collectiveSizeClassMap: BreakpointMap = {
  default: defaultSizeClassMap,
  sm: smallSizeClassMap,
  md: mediumSizeClassMap,
  lg: largeSizeClassMap,
  xl: extraLargeSizeClassMap,
  "2xl": twoExtraLargeSizeClassMap,
  "3xl": threeExtraLargeSizeClassMap,
};

export type Size = keyof typeof defaultSizeClassMap;

export type BreakpointSize = {
  [key in Breakpoint]?: Size;
};
