import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import ProductOverviewTabs from "../../feature/Tabs/ProductOverviewTabs/ProductOverviewTabs";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";

export interface ISectionProductOverviewTabsProps {
  title?: string;
  theme?: Theme;
}

const SectionProductOverviewTabs: FC<ISectionProductOverviewTabsProps> = ({ title, theme = "default" }) => (
  <Section component={SectionProductOverviewTabs.name} theme={theme}>
    <Container>
      { title && (
        <>
          <Grid>
            <Column justify="center">
              <TextSubtitle
                tag="h2"
                size={1}
                align="center"
                data-testid="SectionProductOverviewTabsTitle"
              >{ title }</TextSubtitle>
            </Column>
          </Grid>
          <Spacer size="md-lg" />
        </>
      ) }
      <ProductOverviewTabs />
    </Container>
  </Section>
);

export default SectionProductOverviewTabs;
