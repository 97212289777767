import {
  Align,
  BreakpointAlign,
  BreakpointSize,
  Display,
  legacySizeCollectionMap,
  TitleSize,
} from "apps/website/components/base/Text/Text.map";
import { Justify } from "apps/website/maps/Flex.map";
import {
  BreakpointOffset,
  Offset,
} from "apps/website/components/layout/Column/Column.map";

export const sizeClassMap = {
  auto: "h-auto",
  halfScreen: "h-[50vh] h-[50dvh]",
  fullScreen: "h-[85vh] h-[85dvh]", // Fallback to VH units
};

export type Size = keyof typeof sizeClassMap;
export type TitleSizeGroup = "default" | "large";
export type SplashLayout = "default" | "secondary";

export interface ISplashLayout {
  titleAlign: Align | BreakpointAlign;
  bodyOffset: Offset | BreakpointOffset;
  bodyCopyAlign: Align | BreakpointAlign;
  ctaJustify: Justify;
}

export type SplashLayouts = {
  [key in SplashLayout]: ISplashLayout
};

export const textGroupSizeMap: Record<TitleSize, SplashTitle> = {
  default: {
    title: legacySizeCollectionMap.titleLg,
    subtitle: legacySizeCollectionMap.titleMd,
  },
  large: {
    title: legacySizeCollectionMap.titleXl,
    subtitle: { default: "lg", lg: "3xl" },
  },
  largeAlt: { // This needs to change
    title: { default: "3xl", xl: "5xl", "2xl": "6xl" },
    subtitle: legacySizeCollectionMap.titleMd,
  },
};

export const splashLayoutMap: SplashLayouts = {
  default: {
    titleAlign: "center",
    bodyOffset: { lg: 3 },
    bodyCopyAlign: "center",
    ctaJustify: "center",
  },
  secondary: {
    titleAlign: { default: "center", lg: "default" },
    bodyOffset: {},
    bodyCopyAlign: { default: "center", lg: "default" },
    ctaJustify: "default",
  },
};

export type SplashTitle = {
  [key in Display]?: BreakpointSize
};

export interface ISplashSubtitle {
  text: string;
}
