import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import {
  Direction,
} from "apps/website/components/feature/ImageAccordionList/ImageAccordionList";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";

import SectionImageCondensedList from "../../section/SectionImageCondensedList/SectionImageCondensedList";

import { IStoryBlokCondensedList, getColumns } from "./StoryBlokCondensedList";

export interface IStoryBlokImageCondensedListChild {
  title: string;
  body: string;
  image: IStoryBlokImage;
  condensed_list: [IStoryBlokCondensedList];
  direction: Direction;
}

export interface IStoryBlokImageCondensedList {
  image_condensed_list: [IStoryBlokImageCondensedListChild]
  theme: Theme;
}

export const StoryBlokImageCondensedList = ({ blok }: IStoryBlokComponent<IStoryBlokImageCondensedList>) => (
  <>
    { blok.image_condensed_list[0] && (
      <SectionImageCondensedList
        title={blok.image_condensed_list[0].title}
        body={renderStoryBlokRichText(blok.image_condensed_list[0].body)}
        image={getStoryBlokImage(blok.image_condensed_list[0].image, true, ImageMax.HALF_CONTAINER_2XL)}
        direction={blok.image_condensed_list[0].direction}
        condensedList={{
          ...blok.image_condensed_list[0].condensed_list,
          items: blok.image_condensed_list[0].condensed_list[0].items.map((item) => ({
            ...item,
            image: getStoryBlokImage(item.image, true, ImageMax.ICON_SM),
          })),
          columns: blok.image_condensed_list[0].condensed_list[0].columns
            && getColumns(blok.image_condensed_list[0].condensed_list[0].columns),
        }}
        theme={blok.theme}
        listType={blok.image_condensed_list[0].condensed_list[0].list_type}
        imageSize={blok.image_condensed_list[0].condensed_list[0].image_size}
      />
    ) }
  </>
);
