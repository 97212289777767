import { create } from "zustand";

export const EXPERIMENT_KEYS = {
  NEWSLETTER_SIGN_UP_PROMPT: "newsLetterSignupPrompt",
  FRESH_WELCOME_PAGE_SCOOP_HEALTH_CROSS_SELL_COPY: "freshWelcomePageScoopHealthCopy",
};

const experiments = {
  [EXPERIMENT_KEYS.NEWSLETTER_SIGN_UP_PROMPT]: "P48kMJUpTMC9klCCE6BxaA", // ExperimentID
  [EXPERIMENT_KEYS.FRESH_WELCOME_PAGE_SCOOP_HEALTH_CROSS_SELL_COPY]: "dbUyqkxuTRuSbPZQkKJN3Q", // ExperimentID
};

type Experiment = keyof typeof experiments;
type OptimizeVariant = "0" | "1" | "2" | "3";
type Variant = "control" | "A" | "B" | "C";
type Experiments = {
  [key in Experiment]?: Variant;
};

const transformVariant = (optimizeVariant?: OptimizeVariant): Variant => {
  const variantMap: Record<OptimizeVariant, Variant> = {
    0: "control",
    1: "A",
    2: "B",
    3: "C",
  };
  if (optimizeVariant === undefined) return "control";
  return variantMap[optimizeVariant];
};

const getActiveOptimizeVariants = () => {
  const activeExperiments: Experiments = {};
  for (const [ key, value ] of Object.entries(experiments)) {
    // @ts-ignore
    const variant: Variant = transformVariant(window.google_optimize?.get(value));
    activeExperiments[key as Experiment] = variant || "control";
  }
  return activeExperiments;
};

const getExperimentFromQuery = (query: string): Experiments => {
  if (!query) return {};
  const [ experiment, variant ] = query.split(",");
  return {
    [experiment]: variant as Variant,
  };
};

interface IUseOptimizeStore {
  experiments: Experiments
  setExperimentVariants(query?: any): void // eslint-disable-line
  setExperimentVariant(experiment: Experiment, variant: Variant): void
}

const useOptimizeStore = create<IUseOptimizeStore>((set) => ({
  experiments: { // defaults
    [EXPERIMENT_KEYS.NEWSLETTER_SIGN_UP_PROMPT]: "control",
    [EXPERIMENT_KEYS.FRESH_WELCOME_PAGE_SCOOP_HEALTH_CROSS_SELL_COPY]: "control",
  },
  setExperimentVariants: (query = undefined) => set(() => {
    // @ts-ignore
    const newExperimentVariants: Experiments = getActiveOptimizeVariants();

    const experimentQuery = getExperimentFromQuery(query);
    const experimentQueryOverride = Object.entries(experimentQuery)[0];

    if (experimentQueryOverride) {
      const [ experimentQueryKey, experimentQueryValue ] = experimentQueryOverride;
      if (experimentQueryValue) {
        newExperimentVariants[experimentQueryKey as Experiment] = experimentQueryValue;
      }
    }

    return { experiments: newExperimentVariants };
  }),
  setExperimentVariant: (experiment, variant) => set((state) => ({
    experiments: { ...state.experiments, [experiment]: variant },
  })),
}));

export default useOptimizeStore;
