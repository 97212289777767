import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { renderTableValue } from "apps/website/utils/content/table";
import Table from "apps/website/components/feature/Table/Table";
import {
  cellStyles,
  rowAltStyles,
  rowStyles,
  tableStyles,
  titleStyles,
  valueStyles,
} from "apps/website/components/feature/Table/Table.map";

// TODO: move ITableTitle
export interface ITableTableTitle {
  id: string;
  name: string;
}

export interface IColumnTableRow {
  values:(boolean | string | [string, boolean])[];
}
export interface IColumnTable {
  component?: string,
  titles: ITableTableTitle[];
  rows: IColumnTableRow[];
}

const ColumnTable: FC<IColumnTable> = ({ titles, rows, component = "ColumnTable" }) => (
  <Table component={component} className={tableStyles}>
    <tr className={rowStyles}>
      { titles.map((title) => (
        <th key={title.id} scope="col" className={titleStyles}>
          <Text tag="span" display="title" size={legacySizeCollectionMap.titleMd} whitespace={{ lg: "preWrap" }}>
            { title.name }
          </Text>
        </th>
      )) }
    </tr>
    { rows.map((row, rowIndex) => (
      <tr key={`row-${rowIndex}`} className={`${rowStyles} ${rowIndex % 2 === 0 && rowAltStyles}`}>
        { row.values.map((value, valueIndex) => (
          <td key={`value-${rowIndex}-${valueIndex}`} className={`${valueStyles} ${cellStyles}`}>
            { renderTableValue(value) }
          </td>

        )) }
      </tr>
    )) }
  </Table>
);

export default ColumnTable;
