import { FC } from "react";

import Icon from "apps/website/components/base/Icon/Icon";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";

export interface IProductOverviewTabBulletPoint {
  icon?: IconSvg;
  Body: FC;
}

export interface IProductOverviewTabProps {
  tabType: "productOverview";
  title: string;
  image: IImageProps;
  bulletPoints: IProductOverviewTabBulletPoint[];
  coreIngredients: string[]
}

interface IProductOverviewTabTagLine {
  body: string;
  icon?: IconSvg;
}

interface IProductOverviewTabTag {
  lines: IProductOverviewTabTagLine[]
}

const ProductOverviewTabTag: FC<IProductOverviewTabTag> = ({ lines }) => (
  <>
    { lines.map((line, index) => (
      <TextSubtitle
        key={ `line-${index}` }
        tag="span"
        size={ 2 }
        className={ `flex items-center bg-black text-white w-fit px-2 ${index === 0 ? "pt-1" : ""} ${index === lines.length - 1 ? "pb-1" : ""}` }
      >{ line.icon && (<Icon icon={ line.icon } className="mr-2" color="inherit" size="small" />) }{ line.body }</TextSubtitle>
    )) }
  </>
);

const ProductOverviewTab: FC<IProductOverviewTabProps> = ({
  title,
  image,
  bulletPoints,
  coreIngredients,
}) => (
  <Grid component={ ProductOverviewTab.name } gap="xl">
    <Column spans={ { lg: 6 } } className="p-[50px] bg-white p-4 relative" align="center" justify="center">
      <div className="flex justify-center items-center max-w-[468px]">
        <div className="absolute top-4 left-4 z-10 -rotate-1">
          <ProductOverviewTabTag
            lines={ [
              { body: "100% gently-" },
              { body: `steamed ${coreIngredients?.join("+")}` },
            ] }
          />
          <div className="w-24 h-24 sm:w-36 sm:h-36 md:w-48 md:h-48 lg:w-28 lg:h-28 xl:w-36 xl:h-36 2xl:w-48 2xl:h-48 -translate-y-4">
            <Icon icon="arrowSwoosh" size="fit" />
          </div>
        </div>
        <div className="p-4">
          <Image image={ image } alt={ title } className="-rotate-[30deg]" lazy={ false } /> { /** Lazy: false: stop flash on tab change */ }
        </div>
        <div className="absolute bottom-4 right-4 z-10  -rotate-1 flex flex-col">
          <div className="w-24 h-24 sm:w-36 sm:h-36 md:w-48 md:h-48 lg:w-28 lg:h-28 xl:w-36 xl:h-36 2xl:w-48 2xl:h-48 self-end translate-y-4">
            <Icon icon="arrowSwoosh" size="fit" className="-rotate-180" />
          </div>
          <ProductOverviewTabTag
            lines={ [
              { body: "KatKin Nutrient Mix", icon: "plusHeavy" },
            ] }
          />
        </div>
      </div>
    </Column>
    <Column spans={ { lg: 6 } } align="center" justify="center">
      <List className="space-y-4">
        { bulletPoints.map((BulletPoint, index) => (
          <ListItem key={ index }>
            <div className="flex">
              <div className="flex-none mr-4 w-8 h-8">
                <Icon icon={ BulletPoint.icon || "tickHeavy" } />
              </div>
              <div className="flex-1 flex flex-col">
                <BulletPoint.Body />
              </div>
            </div>
          </ListItem>
        )) }
      </List>
    </Column>
  </Grid>
);

export default ProductOverviewTab;
