import { FC, ReactNode } from "react";

import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";

interface IFieldsetDefault {
  themeClass?: string;
  className?: string;
  children: ReactNode;
  id?: string;
}

export const FieldsetDefault: FC<IFieldsetDefault> = ({ themeClass, className, children, id }) => (
  <Grid id={id} component={FieldsetDefault.name} className="container mx-auto px-4">
    <Column spans={{ lg: 6 }} offset={{ lg: 4 }} className={`${themeClass} ${className}`}>
      { children }
    </Column>
  </Grid>
);
