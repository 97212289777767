import { Theme } from "apps/website/maps/Theme.map";
import { Justify } from "apps/website/maps/Flex.map";

import { TDirection, TSize } from "../SectionArrow.map";

export type BorderTheme = Exclude<
Theme,
"dark-alt" | "brand-alt" | "header" | "form-input" | "footer" | "product-card"
>;

export interface ISectionArrowProps {
  direction?: TDirection;
  backgroundTheme?: Theme;
  arrowTheme?: BorderTheme;
  size?: TSize;
  justify?: Justify;
  className?: string;
}

export const borderClassByTheme: Record<BorderTheme, string> = {
  default: "border-b-default",
  dark: "border-b-dark", /* doesn't exist */
  light: "border-b-light", /* doesn't exist */
  color: "border-b-color", /* doesn't exist */
  brand: "border-b-brand",
  "light-grey": "border-b-light-grey",
  blue: "border-b-blue",
  pink: "border-b-pink",
  orange: "border-b-orange",
  green: "border-b-green",
  inherit: "border-b-inherit",
};
