import { FC } from "react";

import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import Image, { IImageProps } from "../../base/Image/Image";
import Spacer from "../../layout/Spacer/Spacer";
import Text from "../../base/Text/Text";
import CondensedList, {
  ICondensedListProps,
} from "../CondensedList/CondensedList";
import { AvailableTag } from "../../base/List/List.map";
import {
  ImageSize,
} from "../CondensedList/CondensedListItem/CondensedListItem.map";

export type Direction = "default" | "reverse";

export interface IImageCondensedList {
  title?: string;
  body?: string;
  condensedList: ICondensedListProps;
  image: IImageProps;
  direction?: Direction;
  listType?: AvailableTag;
  imageSize?: ImageSize;
}

const ImageCondensedList: FC<IImageCondensedList> = (
  { title, body, condensedList, image, direction, listType, imageSize },
) => {
  const order = direction === "default" ? { image: "order-first", text: "order-last" } : { image: "order-last", text: "order-first" };
  return (
    <Grid component={ImageCondensedList.name} gap="lg">
      <Column spans={{ md: 6, lg: 7 }} className={order.image}>
        <Image image={image} alt={title ?? ""} />
      </Column>
      <Column spans={{ md: 6, lg: 5 }} className={order.text}>
        { body && (
          <>
            <Text tag="div">
              { body }
            </Text>
            <Spacer size="xl" />
          </>
        ) }
        <CondensedList {...condensedList} listType={listType} imageSize={imageSize}/>
      </Column>
    </Grid>
  );
};

export default ImageCondensedList;
