import { FC, ReactNode } from "react";

import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  Align,
  BreakpointSize,
  Size,
} from "apps/website/components/base/Text/Text.map";
import Container from "apps/website/components/layout/Container/Container";
import {
  Size as ContainerSize,
} from "apps/website/components/layout/Container/Container.map";

import { FreeFlowRichTextColor } from "./FreeFlowRichText.map";

export interface IFreeFlowRichText {
  align?: Align;
  withSpacer?: boolean;
  children: ReactNode;
  size?: Size | BreakpointSize;
  color?: FreeFlowRichTextColor;
  container?: ContainerSize,
}

const FreeFlowRichText: FC<IFreeFlowRichText> = ({ align = "default", withSpacer = true, size, color, container = "default", children }) => (
  <div data-component={FreeFlowRichText.name} data-richtext-color={color}>
    <Container size={container}>
      <Text tag="div" align={align} size={size}>{ children }</Text>
      { withSpacer && (
        <Spacer size="xl" />
      ) }
    </Container>
  </div>
);

export default FreeFlowRichText;
