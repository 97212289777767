import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import SectionReviewOverview from
  "apps/website/components/section/SectionReviewOverview/SectionReviewOverview";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";

interface IStoryBlokReviewOverviewLink {
  _uid?: string;
  title: string;
  logo: IStoryBlokImage;
  link: IStoryBlokLink;
}

interface IStoryBlokReviewOverview {
  title: string;
  body: string;
  review_links: IStoryBlokReviewOverviewLink[];
  theme?: Theme;
}

export const StoryBlokReviewOverview = ({ blok }: IStoryBlokComponent<IStoryBlokReviewOverview>) => (
  <SectionReviewOverview
    title={blok.title}
    body={blok.body}
    reviewLinks={blok.review_links.map((link) => ({
      id: link._uid,
      title: link.title,
      logo: getStoryBlokImage(link.logo, true, ImageMax.HALF_CONTAINER_SM),
      to: getStoryBlokLink(link.link),
    }))}
    theme={blok.theme ?? "default"}
  />
);
