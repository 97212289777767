import { create } from "zustand";
import { persist } from "zustand/middleware";

interface FlowCats {
  flowId: string;
  catIds: string[];
}

interface ICatsStore {
  cats: FlowCats[];
  setCats(flowId: string, cats: string[] | undefined): void;
}

export const useCatsStore = create<ICatsStore>()(persist((set) => ({
  setCats: (flowId: string, newCats: string[]) => set((state) => {
    if (state.cats.find((ci) => ci.flowId === flowId)) {
      return ({
        ...state,
        cats: state.cats
          .map((ci) => (ci.flowId === flowId ? ({ ...ci, catIds: newCats }) : ci)),
      });
    }
    state.cats.push({ flowId, catIds: newCats });
    return {
      ...state,
      cats: state.cats };

  }),
  cats: [],
}), { name: "cats" }));

export default useCatsStore;
