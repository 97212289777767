import { BRANDS, BRAND_IMAGES } from "apps/website/constants/brands";

export const getBrandImage = (brand: keyof typeof BRANDS) => (
  {
    image: {
      src: BRAND_IMAGES[brand],
      width: 150,
      height: 50,
    },
    alt: BRANDS[brand],
  }
);
