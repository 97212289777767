import { FC } from "react";

import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  IFreshPlanOverviewProps,
} from "apps/website/components/feature/FreshPlanOverview/FreshPlanOverview";
import FreshPlanOverviewCarousel from "apps/website/components/feature/Carousel/FreshPlanOverviewCarousel/FreshPlanOverviewCarousel";
import {
  getSectionSize,
} from "apps/website/components/layout/Section/Section.utils";
import TextTitle from "apps/website/components/base/Text/TextTitle/TextTitle";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export interface ISectionFreshPlanOverviewCarousel {
  title?: string;
  items: IFreshPlanOverviewProps[];
  theme?: Theme;
}

const SectionFreshPlanOverviewCarousel: FC<ISectionFreshPlanOverviewCarousel> = ({ title, items, theme = "default" }) => (
  <Section component="SectionFreshPlanOverviewCarousel" data-theme={ theme } className={ themeRootClassMap[theme] } size={ getSectionSize(theme) }>
    <Container>
      <>
        { title && (
          <>
            <TextTitle size={ 1 } align="center">{ title }</TextTitle>
            <Spacer size="lg" />
          </>
        ) }
        <FreshPlanOverviewCarousel items={ items } />
      </>
    </Container>
  </Section>
);

export default SectionFreshPlanOverviewCarousel;
