import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";
import { useFlow } from "apps/website/contexts/flow";

import { IStoryBlokFreeFlow, StoryBlokFreeFlow } from "../StoryBlokFreeFlow";

export const StoryBlokFormFreeFlow = (component: IStoryBlokComponent<IStoryBlokFreeFlow>) => {

  const { flow } = useFlow();

  const formComponent: IStoryBlokComponent<IStoryBlokFreeFlow> = {
    blok: {
      ...component.blok,
      containerSize: "md",
      items: [
        ...component.blok.items.map((item) => {
          if (item.component === "free_flow_title") {
            return {
              ...item,
              text: interpolateString(
                item.text,
                getFlowFieldValuesForInterpolation(component.flowId ?? flow.slug, component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
                Interpolator.FRONTEND,
              ),
            };
          }
          if (item.component === "free_flow_raw_html") {
            return {
              ...item,
              content: interpolateString(
                `<div class="form-free-flow-raw">${item.content}</div>`,
                getFlowFieldValuesForInterpolation(component.flowId ?? flow.slug, "0", "GET_ALL_WITH_REMOVED"),
                Interpolator.FRONTEND,
              ),
            };
          }
          return {
            ...item,
          };
        }),
      ],
      richtextOptions: {
        interpolation: {
          values: getFlowFieldValuesForInterpolation(component.flowId ?? flow.slug, component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        },
        tags: {
          MARK_CODE: {
            display: "default",
          },
        },
      },
    },
  };

  return (
    <>
      <StoryBlokFreeFlow {...formComponent} />
    </>
  );
};
