import { FC, memo } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Button from "apps/website/components/base/Button/Button";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import {
  borderlessSectionBackgroundClassMap,
} from "../SectionIconTextList/Section.map";

import SectionHardcoreTruthCat from
  "./SectionHardcoreTruthCat/SectionHardcoreTruthCat";

export type ITitlePosition = "top" | "bottom";
export type TStoryBlokHardcoreTruth = "default" | "small";

export interface ISectionHardcoreTruth {
  title?: string;
  titlePosition?: ITitlePosition;
  body: string;
  to: string;
  theme?: Theme;
  size?: TStoryBlokHardcoreTruth;
}

const SectionHardcoreTruth: FC<ISectionHardcoreTruth> = ({ title, titlePosition = "top", body, to, theme = "light-grey", size = "default" }) => (
  <Section component="SectionHardcoreTruth" size="none">
    <Container
      className={`h-full relative z-10 ${themeRootClassMap[theme]} ${size !== "small" && borderlessSectionBackgroundClassMap[theme]}`}
      theme={theme}
    >
      <Spacer size="xl" customSize={size === "small" ? "h-8" : undefined}/>
      <Grid>
        <Column spans={{ lg: size === "small" ? 4 : 6 }} offset={{ lg: size === "small" ? 3 : 1 }} align="center" justify="center" className="lg:flex">
          <div className={`flex flex-col items-center ${size === "small" && "max-w-[340px]"}`}>
            <SectionHardcoreTruthCat fontSize={size === "default" ? legacySizeCollectionMap.titleLg : "3xl"} />
          </div>
        </Column>
        <Column spans={{ lg: size === "small" ? 4 : 6 }} align="center" justify="center">
          <Grid>
            <Column spans={{ default: 10, lg: 8 }} offset={{ default: 2, lg: 2 }} align="center" justify="center">
              { title && (
                <>
                  <Text
                    tag="h3"
                    display="subtitle"
                    size={legacySizeCollectionMap.titleMd}
                    align="center"
                    className={titlePosition === "bottom" ? "sr-only" : ""}
                  >
                    { title }
                  </Text>
                  { titlePosition === "top" && (
                    <Spacer size="md" />
                  ) }
                </>
              ) }
              <Text align="center" size={size === "default" ? legacySizeCollectionMap.bodyLg : "default"}>{ body }</Text>
              { (title && titlePosition === "bottom") && (
                <>
                  { /* This title should be a p - It's for display anyway - we have a h3 above */ }
                  <Text
                    tag="p"
                    size={legacySizeCollectionMap.bodyLg}
                    align="center"
                  >
                    { title }
                  </Text>
                </>
              ) }
              { to && (
                <>
                  <Spacer size="xl" />
                  <Button to={to}>Learn more<span className="sr-only"> about { title }</span></Button>
                </>
              ) }
            </Column>
          </Grid>
        </Column>
      </Grid>
      <Spacer size="xl" customSize={size === "small" ? "h-8" : undefined}/>
    </Container>
  </Section>
);

export default memo(SectionHardcoreTruth);
