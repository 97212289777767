import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokVideo,
} from "apps/website/components/page/api.types";
import SectionIconVideo from
  "apps/website/components/section/SectionIconVideo/SectionIconVideo";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokVideo } from "apps/website/utils/storyblok/video";
import {
  PlayPausePosition,
} from "apps/website/components/base/Video/Video.map";

interface IStoryBlokIconVideo {
  title: string;
  icon: IStoryBlokImage;
  video: IStoryBlokVideo;
  video_image: IStoryBlokImage;
  video_play_pause_position: PlayPausePosition;
  theme?: Theme;
  contain?: boolean;
}

export const StoryBlokIconVideo = ({ blok }: IStoryBlokComponent<IStoryBlokIconVideo>) => (
  <SectionIconVideo
    title={blok.title}
    icon={getStoryBlokImage(blok.icon, true, ImageMax.CONTAINER_MD)}
    video={{
      video: getStoryBlokVideo(blok.video),
      poster: getStoryBlokImage(blok.video_image, true, ImageMax.CONTAINER_MD).src,
    }}
    playPausePosition={blok.video_play_pause_position}
    theme={blok.theme}
    hasBorders={!blok.contain}
  />
);
