import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";

import SectionKatkinVersesCompetitor from "../../section/SectionKatkinVersesCompetitor/SectionKatkinVersesCompetitor";
import {
  IKatkinVersesCompetitorItem,
} from "../../feature/KatkinVersesCompetitor/KatkinVersesCompetitor";

interface IStoryBlokKatkinVsCompetitorItem {
  title: string;
  image: IStoryBlokImage;
  tag_title: string;
  tag_theme: Theme;
  footer_title: string;
  footer_body: string;
}

export interface IStoryBlokKatkinVsCompetitor {
  title: string;
  body: string;
  theme: Theme
  items: [{ items: [IStoryBlokKatkinVsCompetitorItem, IStoryBlokKatkinVsCompetitorItem] }];
}

export const StoryBlokKatkinVsCompetitor = ({ blok }: IStoryBlokComponent<IStoryBlokKatkinVsCompetitor>) => (
  <SectionKatkinVersesCompetitor
    title={ blok.title }
    body={ renderStoryBlokRichText(blok.body) }
    items={ blok.items[0].items.map((item) => ({
      title: item.title,
      tag: {
        theme: item.tag_theme,
        text: item.tag_title,
      },
      image: getStoryBlokImage(item.image),
      footer: {
        title: item.footer_title,
        body: renderStoryBlokRichText(item.footer_body),
      },
    })) as [IKatkinVersesCompetitorItem, IKatkinVersesCompetitorItem] }
    theme={ blok.theme }
  />
);
