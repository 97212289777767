import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import SectionSocialFeed from
  "apps/website/components/section/SectionSocialFeed/SectionSocialFeed";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { ITitle } from "apps/website/components/page/component.types";

export interface IStoryBlokSocial {
  icon: "facebook" | "instagram" | "youtube";
  cta: [IStoryBlokCta]
}

export interface IStoryBlokSocialFeed {
  title: ITitle;
  body: string;
  socials: IStoryBlokSocial[];
  theme: Theme
  images: IStoryBlokImage[];
  number_of_lines?: number;
  show_spacer?: boolean;

}

export const StoryBlokSocialFeed = ({ blok }: IStoryBlokComponent<IStoryBlokSocialFeed>) => (
  <SectionSocialFeed
    title={blok.title}
    body={renderStoryBlokRichText(blok.body)}
    socials={blok.socials.map((social) => (
      {
        icon: social.icon,
        cta: getStoryBlokCta(social.cta[0]),
      }
    ))}
    images={blok.images.map((image) => getStoryBlokImage(image, true, ImageMax.HALF_CONTAINER_MD))}
    theme={blok.theme}
    numberOfLines={blok.number_of_lines}
    showSpacer={blok.show_spacer}
  />
);
