import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import SectionHoverList from
  "apps/website/components/section/SectionHoverList/SectionHoverList";
import { AvailableTag } from "apps/website/components/base/List/List.map";

interface IStoryBlokHoverListItem {
  title: string;
  body: string;
  image: IStoryBlokImage;
}

interface IStoryBlokHoverList {
  title: string;
  items: IStoryBlokHoverListItem[];
  theme?: Theme;
  list_type?: AvailableTag;
  ctas?: IStoryBlokCta[];
}

export const StoryBlokHoverList = ({ blok }: IStoryBlokComponent<IStoryBlokHoverList>) => (
  <SectionHoverList
    title={blok.title}
    items={blok.items.map((item) => (
      {
        ...item,
        image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_2XL),
      }
    ))}
    theme={blok.theme}
    listType={blok.list_type}
    ctas={getStoryBlokCtas(blok.ctas ?? [])}
  />
);
