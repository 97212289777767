import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Text from "../../base/Text/Text";
import Logo from "../../base/Icon/Logo/Logo";
import Section from "../../layout/Section/Section";

import { LOGO_DISPLAY_MAP, TDisplay } from "./SectionLogo.map";

export interface ISectionLogoProps {
  theme: Theme;
  display?: TDisplay;
}

const SectionLogo: FC<ISectionLogoProps> = ({ theme, display = "default" }) => (
  <Section component={ SectionLogo.name } theme={ theme } size="none" className={ LOGO_DISPLAY_MAP[display] }>
    <Text tag="div" className="-mt-[12%] relative z-10" color="secondary">
      <Logo />
    </Text>
  </Section>
);

export default SectionLogo;
