import { FC, ReactNode } from "react";

import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";

interface IFieldsetContainer {
  themeClass?: string;
  className?: string;
  children: ReactNode;
  id?: string;
}

export const FieldsetBackground: FC<IFieldsetContainer> = ({ themeClass, className, children, id }) => (
  <Grid id={id} component={FieldsetBackground.name} className={`container mx-auto relative ${themeClass} ${className}`}>
    <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
      { children }
    </Column>
  </Grid>
);
