import { storyblokEditable, StoryblokComponent } from "@storyblok/react";

// @ts-ignore
const Article = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid}>
    { /* eslint-disable-next-line */ }
    { blok.body?.map((nestedBlok: any) => (
      <div {...storyblokEditable(nestedBlok)} key={nestedBlok._uid} >
        <StoryblokComponent blok={nestedBlok}/>
      </div>
    )) }
  </div>
);

export default Article;
