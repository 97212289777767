import { FC, ReactNode } from "react";
import Head from "next/head";

import {
  DEFAULT_OG_IMAGE,
  DEFAULT_PAGE_DESCRIPTION,
} from "apps/website/constants/website";
import { IImageProps } from "apps/website/components/base/Image/Image";
import { makePageTitle } from "apps/website/utils/website/head";

export interface ISeo {
  title: string;
  description?: string;
  og_title?: string;
  og_description?: string;
  og_image?: IImageProps;
  twitter_title?: string;
  twitter_description?: string;
  twitter_image?: IImageProps;
}

export interface IHead {
  seo?: ISeo;
  hideFromSearch?: boolean;
  fallbackTags?: ISeo;
  children?: ReactNode;
  lastBuilt?: string;
}

const DefaultHead: FC<IHead> = ({
  seo,
  hideFromSearch = false,
  lastBuilt,
  fallbackTags,
  children,
}) => (
  <Head>
    <title>{ seo && makePageTitle(seo) }</title>
    <link rel="icon" href="/images/favicons/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
    <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#231f20" />
    <meta name="msapplication-TileColor" content="#231f20" />
    <meta name="theme-color" content="#231f20"></meta>

    <meta name="description" content={seo?.description || fallbackTags?.description || DEFAULT_PAGE_DESCRIPTION}/>

    { /* Opengraph Tags */ }
    <meta name="og:title" content={seo?.og_title || fallbackTags?.og_title || (seo && makePageTitle(seo))}/>
    <meta name="og:description" content={seo?.og_description || fallbackTags?.og_description || DEFAULT_PAGE_DESCRIPTION }/>
    <meta name="og:image" content={seo?.og_image?.src || fallbackTags?.og_image?.src || DEFAULT_OG_IMAGE}/>

    { /* Twitter Tags */ }
    <meta name="twitter:title" content={seo?.twitter_title || fallbackTags?.twitter_title || (seo && makePageTitle(seo))}/>
    <meta name="twitter:description" content={seo?.twitter_description || fallbackTags?.twitter_description || seo?.description || DEFAULT_PAGE_DESCRIPTION}/>
    <meta name="twitter:image" content={seo?.twitter_image?.src || fallbackTags?.twitter_image?.src || DEFAULT_OG_IMAGE}/>

    { lastBuilt && (
      <meta name="lastbuilt" content={lastBuilt}/>
    ) }

    { hideFromSearch && (
      <meta name="robots" content="noindex" />
    ) }

    { children }
  </Head>
);

export default DefaultHead;
