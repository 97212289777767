import {
  IStoryBlokComponent,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import SectionAccordionList from "apps/website/components/section/SectionAccordionList/SectionAccordionList";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

import Spacer from "../../layout/Spacer/Spacer";
import FreeFlowRichText from "../../feature/Freeflow/FreeFlowRichText/FreeFlowRichText";
import CondensedList from "../../feature/CondensedList/CondensedList";
import { IAccordion } from "../../feature/Accordion/Accordion";

import { IStoryBlokAdvancedAccordion } from "./StoryBlokAccordion";
import {
  IStoryBlokFreeFlowRichText,
  IStoryBlokFreeFlowTitle,
} from "./StoryBlokFreeFlow";
import { IStoryBlokCondensedList } from "./StoryBlokCondensedList";
import { IStoryBlokSpacer } from "./StoryBlokSpacer";

export interface IStoryBlokAccordionSection {
  title: string;
  accordions: IStoryBlokAdvancedAccordion[];
  theme?: Theme;
  ctas?: IStoryBlokCta[];
}

const StoryBlokAccordionListContent = (
  { content }: { content: (
    IStoryBlokFreeFlowTitle |
    IStoryBlokFreeFlowRichText |
    IStoryBlokCondensedList |
    IStoryBlokSpacer)[]
  },
) => (
  <>
    { content.map((contentPart) => (
      <>
        { contentPart.component === "section_spacer" && (
          <Spacer size={contentPart.size} />
        ) }
        { contentPart.component === "free_flow_rich_text" && (
          <>
            <FreeFlowRichText
              key={contentPart._uid}
              align={contentPart.align}
              withSpacer={!contentPart.disable_spacer}
            >
              { renderStoryBlokRichText(contentPart.content) }
            </FreeFlowRichText>
          </>
        ) }
        { contentPart.component === "condensed_list" && (
          <>
            <CondensedList
              key={contentPart._uid}
              items={contentPart.items.map((item) => ({
                ...item,
                image: getStoryBlokImage(item.image, true, ImageMax.ICON_SM),
              }))}
              imageSize={contentPart.image_size}
              listType={contentPart.list_type}
            />
          </>
        ) }
      </>
    ))
    }
  </>
);

export const StoryBlokAccordionList = ({ blok }: IStoryBlokComponent<IStoryBlokAccordionSection>) => {

  const sectionAccordionListItems: IAccordion[] = blok.accordions.map((accordion) => ({
    title: accordion.title,
    state: accordion.is_open ? "open" : "closed",
    theme: accordion.theme,
    type: accordion.design,
    Content: <StoryBlokAccordionListContent content={accordion.content} />,
  }));
  return (

    <SectionAccordionList
      title={blok.title}
      theme={blok.theme}
      ctas={getStoryBlokCtas(blok.ctas ?? [])}
      items={sectionAccordionListItems}
    />
  );
};
