import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import HardcoreTruth, {
  ITitlePosition,
  TStoryBlokHardcoreTruth,
} from "apps/website/components/section/SectionHardcoreTruth/SectionHardcoreTruth";

export interface IStoryBlokHardcoreTruth {
  title: string;
  title_position: ITitlePosition;
  body: string;
  link: IStoryBlokLink;
  theme: Theme
  size: TStoryBlokHardcoreTruth;
}

export const StoryBlokHardcoreTruth = ({ blok }: IStoryBlokComponent<IStoryBlokHardcoreTruth>) => (
  <HardcoreTruth
    title={blok.title}
    titlePosition={blok.title_position}
    body={blok.body}
    to={blok.link.url}
    theme={blok.theme}
    size={blok.size || "default"}
  />
);
