import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import { IImageProps } from "apps/website/components/base/Image/Image";
import {
  Align,
  AvailableTag as TitleTag,
} from "apps/website/components/base/Text/Text.map";
import { Breakpoint } from "apps/website/maps/Breakpoint.map";
import { BreakpointDirection, Direction } from "apps/website/maps/Flex.map";
import { Theme } from "apps/website/maps/Theme.map";

// One card tile
export interface IInstructionCardItem {
  image: IImageProps;
  title: string;
  body?: string;
  index?: number;
}

// The actual props passed to the React component
export interface IInstructionCardItemProps extends IInstructionCardItem {
  titleTag: TitleTag;
  showArrow?: boolean;
  align?: Align;
  theme?: Theme;
}

export type PartClass = Record<Direction, Record<Breakpoint, string[]>>;
export type IconMap = Record<Direction, IconSvg>;
export type IconClassMap = Record<Direction, string>;
export type HiddenClassMap = Record<Direction, string>;

export const iconMap: IconMap = {
  row: "arrowThinDown",
  column: "arrowThinRight",
};

export const imageWrapperClasses: PartClass = {
  row: {
    default: [ "relative", "w-36", "min-h-[100px]" ],
    sm: [ "sm:relative", "sm:w-36", "min-h-[100px]" ],
    md: [ "md:relative", "sm:w-36", "min-h-[100px]" ],
    lg: [ "lg:relative", "lg:w-48", "min-h-[100px]" ],
    xl: [ "xl:relative", "xl:w-48", "min-h-[100px]" ],
    "2xl": [ "2xl:relative", "2xl:w-48", "min-h-[100px]" ],
    "3xl": [ "3xl:relative", "3xl:w-48", "min-h-[100px]" ],
  },
  column: {
    default: [ "w-full max-w-full" ],
    sm: [ "sm:w-full" ],
    md: [ "md:w-full" ],
    lg: [ "lg:w-full" ],
    xl: [ "xl:w-full" ],
    "2xl": [ "2xl:w-full" ],
    "3xl": [ "3xl:w-full" ],
  },
};

export const reversedImageWrapperClasses: PartClass = {
  row: {
    default: [ "order-last" ],
    sm: [ "sm:order-last" ],
    md: [ "md:order-last" ],
    lg: [ "lg:order-last" ],
    xl: [ "xl:order-last" ],
    "2xl": [ "2xl:order-last" ],
    "3xl": [ "3xl:order-last" ],
  },
  column: {
    default: [ "order-none" ],
    sm: [ "sm:order-none" ],
    md: [ "md:order-none" ],
    lg: [ "lg:order-none" ],
    xl: [ "xl:order-none" ],
    "2xl": [ "2xl:order-none" ],
    "3xl": [ "3xl:order-none" ],
  },
};

export const reversedTextClasses: PartClass = {
  row: {
    default: [ "pl-0" ],
    sm: [ "sm:pl-0" ],
    md: [ "md:pl-0" ],
    lg: [ "lg:pl-0" ],
    xl: [ "xl:pl-0" ],
    "2xl": [ "2xl:pl-0" ],
    "3xl": [ "3xl:pl-0" ],
  },
  column: {
    default: [ "pl-4" ],
    sm: [ "sm:pl-4" ],
    md: [ "md:pl-4" ],
    lg: [ "lg:pl-4" ],
    xl: [ "xl:pl-4" ],
    "2xl": [ "2xl:pl-4" ],
    "3xl": [ "3xl:pl-4" ],
  },
};

export const titleClasses: PartClass = {
  row: {
    default: [ "flex", "items-center" ],
    sm: [ "sm:flex", "sm:items-center" ],
    md: [ "md:flex", "md:items-center" ],
    lg: [ "lg:flex", "lg:items-center" ],
    xl: [ "xl:flex", "xl:items-center" ],
    "2xl": [ "2xl:flex", "2xl:items-center" ],
    "3xl": [ "3xl:flex", "3xl:items-center" ],
  },
  column: {
    default: [ "block" ],
    sm: [ "sm:block" ],
    md: [ "md:block" ],
    lg: [ "lg:block" ],
    xl: [ "xl:block" ],
    "2xl": [ "2xl:block" ],
    "3xl": [ "3xl:block" ],
  },
};

export const bodyWrapperClasses: PartClass = {
  row: {
    default: [ "flex", "flex-col", "w-full", "flex-1", "pl-0" ],
    sm: [ "sm:flex", "sm:flex-col", "sm:w-full", "sm:flex-1", "sm:pl-0" ],
    md: [ "md:flex", "md:flex-col", "md:w-full", "md:flex-1", "md:pl-0" ],
    lg: [ "lg:flex", "lg:flex-col", "lg:w-full", "lg:flex-1", "lg:pl-0" ],
    xl: [ "xl:flex", "xl:flex-col", "xl:w-full", "xl:flex-1", "xl:pl-0" ],
    "2xl": [ "2xl:flex", "2xl:flex-col", "2xl:w-full", "2xl:flex-1", "2xl:pl-0" ],
    "3xl": [ "3xl:flex", "3xl:flex-col", "3xl:w-full", "3xl:flex-1", "3xl:pl-0" ],
  },
  column: {
    default: [ "block" ],
    sm: [ "sm:block" ],
    md: [ "md:block" ],
    lg: [ "lg:block" ],
    xl: [ "xl:block" ],
    "2xl": [ "2xl:block" ],
    "3xl": [ "3xl:block" ],
  },
};
export const iconClasses: PartClass = {
  row: {
    default: [ "bottom-0", "left-1/2", "translate-y-[calc(100%+0.5rem)]", "-translate-x-1/2", "right-[unset]", "top-[unset]" ],
    sm: [ "sm:bottom-0", "sm:left-1/2", "sm:translate-y-[calc(100%+0.5rem)]", "sm:-translate-x-1/2", "sm:right-[unset]", "sm:top-[unset]" ],
    md: [ "md:bottom-0", "md:left-1/2", "md:translate-y-[calc(100%+0.5rem)]", "md:-translate-x-1/2", "md:right-[unset]", "md:top-[unset]" ],
    lg: [ "lg:bottom-0", "lg:left-1/2", "lg:translate-y-[calc(100%+0.5rem)]", "lg:-translate-x-1/2", "lg:right-[unset]", "lg:top-[unset]" ],
    xl: [ "xl:bottom-0", "xl:left-1/2", "xl:translate-y-[calc(100%+0.5rem)]", "xl:-translate-x-1/2", "xl:right-[unset]", "xl:top-[unset]" ],
    "2xl": [ "2xl:bottom-0", "2xl:left-1/2", "2xl:translate-y-[calc(100%+0.5rem)]", "2xl:-translate-x-1/2", "2xl:right-[unset]", "2xl:top-[unset]" ],
    "3xl": [ "3xl:bottom-0", "3xl:left-1/2", "3xl:translate-y-[calc(100%+0.5rem)]", "3xl:-translate-x-1/2", "3xl:right-[unset]", "3xl:top-[unset]" ],
  },
  column: {
    default: [ "right-0", "top-1/2", "translate-x-[calc(100%+0.5rem)]", "-translate-y-1/2", "left-[unset]" ],
    sm: [ "sm:right-0", "sm:top-1/2", "sm:translate-x-[calc(100%+0.5rem)]", "sm:-translate-y-1/2", "sm:left-[unset]" ],
    md: [ "md:right-0", "md:top-1/2", "md:translate-x-[calc(100%+0.5rem)]", "md:-translate-y-1/2", "md:left-[unset]" ],
    lg: [ "lg:right-0", "lg:top-1/2", "lg:translate-x-[calc(100%+0.5rem)]", "lg:-translate-y-1/2", "lg:left-[unset]" ],
    xl: [ "xl:right-0", "xl:top-1/2", "xl:translate-x-[calc(100%+0.5rem)]", "xl:-translate-y-1/2", "xl:left-[unset]" ],
    "2xl": [ "2xl:right-0", "2xl:top-1/2", "2xl:translate-x-[calc(100%+0.5rem)]", "2xl:-translate-y-1/2", "2xl:left-[unset]" ],
    "3xl": [ "3xl:right-0", "3xl:top-1/2", "3xl:translate-x-[calc(100%+0.5rem)]", "3xl:-translate-y-1/2", "3xl:left-[unset]" ],
  },
};

export const listClasses: PartClass = {
  row: {
    default: [ "flex-row", "space-x-12", "space-y-[unset]" ],
    sm: [ "sm:flex-row", "sm:space-x-12", "sm:space-y-[unset]" ],
    md: [ "md:flex-row", "md:space-x-12", "md:space-y-[unset]" ],
    lg: [ "lg:flex-row", "lg:space-x-12", "lg:space-y-[unset]" ],
    xl: [ "xl:flex-row", "xl:space-x-12", "xl:space-y-[unset]" ],
    "2xl": [ "2xl:flex-row", "2xl:space-x-12", "2xl:space-y-[unset]" ],
    "3xl": [ "3xl:flex-row", "3xl:space-x-12", "3xl:space-y-[unset]" ],
  },
  column: {
    default: [ "flex-col", "space-y-12", "space-x-[unset]" ],
    sm: [ "sm:flex-col", "sm:space-y-12", "sm:space-x-[unset]" ],
    md: [ "md:flex-col", "md:space-y-12", "md:space-x-[unset]" ],
    lg: [ "lg:flex-col", "lg:space-y-12", "lg:space-x-[unset]" ],
    xl: [ "xl:flex-col", "xl:space-y-12", "xl:space-x-[unset]" ],
    "2xl": [ "2xl:flex-col", "2xl:space-y-12", "2xl:space-x-[unset]" ],
    "3xl": [ "3xl:flex-col", "3xl:space-y-12", "3xl:space-x-[unset]" ],
  },
};

export const spacerClasses: PartClass = {
  row: {
    default: [ "hidden" ],
    sm: [ "sm:hidden" ],
    md: [ "md:hidden" ],
    lg: [ "lg:hidden" ],
    xl: [ "xl:hidden" ],
    "2xl": [ "2xl:hidden" ],
    "3xl": [ "3xl:hidden" ],
  },
  column: {
    default: [ "block" ],
    sm: [ "sm:block" ],
    md: [ "md:block" ],
    lg: [ "lg:block" ],
    xl: [ "xl:block" ],
    "2xl": [ "2xl:block" ],
    "3xl": [ "3xl:block" ],
  },
};

export const flipDirection = (currentDirection: Direction) => (currentDirection === "row" ? "column" : "row");

export const getCardLayoutDirection = (
  listDirection: Direction | BreakpointDirection,
): Direction | BreakpointDirection => {
  if (typeof listDirection === "string") return flipDirection(listDirection);
  const cardLayoutDirection: Partial<{ [key in Breakpoint]: Direction }> = {};
  Object.entries(listDirection).forEach(([ key, value ]) => {
    cardLayoutDirection[key as Breakpoint] = flipDirection(value);
  });

  return cardLayoutDirection;
};

export const getPartClassesFromDirection = (
  partClasses: PartClass,
  direction: Direction | BreakpointDirection,
): string => {
  if (typeof direction === "string") return partClasses[direction].default.join(" ");

  let classes = "";
  Object.entries(direction).forEach(([ key, value ]) => {
    const baseClasses = partClasses[value as Direction][key as Breakpoint];

    classes += ` ${baseClasses.join(" ")}`;

  });

  return classes.trim();
};

export const getHiddenClasses = (
  direction: Direction | BreakpointDirection,
): HiddenClassMap => {
  if (typeof direction === "string") {
    return {
      column: direction === "column" ? "hidden" : "block",
      row: direction === "row" ? "hidden" : "block",
    };
  }

  let columnClasses = "";
  let rowClasses = "";
  Object.entries(direction).forEach(([ key, value ]) => {
    const prefix = key === "default" ? "" : `${key}:`;

    columnClasses += ` ${value === "column" ? `${prefix}hidden` : `${prefix}block`}`;
    rowClasses += ` ${value === "row" ? `${prefix}hidden` : `${prefix}block`}`;

  });

  return {
    column: columnClasses,
    row: rowClasses,
  };
};
