import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  IAccordion,
} from "apps/website/components/feature/Accordion/Accordion";
import AccordionList from
  "apps/website/components/feature/Accordion/AccordionList/AccordionList";

export type Direction = "default" | "reverse";

export interface IImageAccordionList {
  title: string;
  body?: string;
  accordionList: IAccordion[];
  image: IImageProps;
  direction?: Direction;
}

const ImageAccordionList: FC<IImageAccordionList> = ({ title, body, accordionList, image, direction }) => {
  const order = direction === "default" ? { image: "order-first", text: "order-last" } : { image: "order-last", text: "order-first" };
  return (
    <Grid component="ImageAccordionList" gap="lg">
      <Column spans={{ md: 6, lg: 7 }} className={order.image}>
        <Image image={image} alt={title} />
      </Column>
      <Column spans={{ md: 6, lg: 5 }} className={order.text}>
        { body && (
          <>
            <Text tag="div">
              { body }
            </Text>
            <Spacer size="md" />
          </>
        ) }
        <AccordionList items={accordionList} />
      </Column>
    </Grid>
  );
};

export default ImageAccordionList;
