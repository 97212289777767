import { FC } from "react";

import Image, { IImageProps } from "../../base/Image/Image";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";

export interface IMicroGallery {
  preText: string;
  title: string;
  body: string;
  images: [IImageProps, IImageProps, IImageProps, IImageProps];
}

const MicroGallery: FC<IMicroGallery> = ({ preText, title, body, images }) => (
  <Grid component={MicroGallery.name}>
    <Column>
      <Text align="center" size={legacySizeCollectionMap.titleSm} display="subtitle">{ preText }</Text>
      <Spacer size="md" />
      <Grid>
        { images.map((image, index) => (
          <Column spans={3} key={`${index}-${image.src}`}>
            <div className="relative aspect-square">
              <Image image={image} alt={image.alt || title} cover/>
            </div>
          </Column>
        )) }
      </Grid>
      { (title || body) && (
        <>
          <Spacer size="lg" />
          { title && (
            <Text align="center" size={legacySizeCollectionMap.titleMd} display="subtitle">{ title }</Text>
          ) }
          { body && (
            <Text align="center">{ body }</Text>
          ) }
        </>
      ) }
    </Column>
  </Grid>
);

export default MicroGallery;
