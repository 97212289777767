export enum EMenuState {
  OPEN,
  CLOSED,
}

export enum EMenuItemType {
  ACTION,
  LINK,
}

export enum EMenuItemStyle {
  DEFAULT,
  BASIC,
}

interface IMenuItem {
  type: EMenuItemType;
  title: string;
  style?: EMenuItemStyle;
  description?: string;
}
export interface IMenuItemAction extends IMenuItem {
  type: EMenuItemType.ACTION;
  action(): Promise<void> | void;
}

export interface IMenuItemLink extends IMenuItem {
  type: EMenuItemType.LINK;
  url: string;
}

export function isMenuItemAction(
  menuItem: IMenuItem,
): menuItem is IMenuItemAction {
  return menuItem.type === EMenuItemType.ACTION;
}

export function isMenuItemLink(menuItem: IMenuItem): menuItem is IMenuItemLink {
  return menuItem.type === EMenuItemType.LINK;
}

export type TMenuItemResource = IMenuItemAction | IMenuItemLink;
export type TMenuItemCollection = readonly TMenuItemResource[];

export interface IMenuDetails {
  state: EMenuState;
  title: string;
  items: TMenuItemCollection;
}

export interface IPopoverMenuStore {
  state: EMenuState;
  setState(state: EMenuState): void;
  title: string;
  setTitle(title: string): void;
  items: TMenuItemCollection;
  setItems(menuItems: TMenuItemCollection): void;
  setMenuDetails(menuDetails: IMenuDetails): void;
  getMenuDetails(): IMenuDetails;
  resetMenuDetails(delay?: number): void;
}

export interface IMenu {
  title: string;
  items: TMenuItemCollection;
}

export enum EMenu {
  MANAGE_PLAN,
}

export type TMenuMap = Record<EMenu, IMenu>;
