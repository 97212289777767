import { FC } from "react";

import SummaryFuture, {
  ISummaryFuture,
} from "apps/website/components/form/Summary/SummaryFuture/SummaryFuture";
import Column from "apps/website/components/layout/Column/Column";
import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";

interface ISectionSummaryFuture {
  theme: Theme;
  title: string;
  summary: ISummaryFuture;
}

export const SectionSummaryFuture: FC<ISectionSummaryFuture> = ({
  title,
  summary,
  theme,
}) => (
  <Section component={SectionSummaryFuture.name} theme={theme}>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleMd} align="center">
            { title }
          </Text>
          <Spacer size="lg" />
        </>
      ) }
      <Grid>
        <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
          <SummaryFuture
            {...summary}
          />
        </Column>
      </Grid>
    </Container>
  </Section>
);
