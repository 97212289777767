import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import IconTextList from
  "apps/website/components/feature/IconTextList/IconTextList";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import {
  IIconTextItem,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem";
import {
  Columns,
  IconWidth,
  TitleDisplay,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem.map";

import { ILink } from "../../base/Button/Button.map";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import Button from "../../base/Button/Button";

import { borderlessSectionBackgroundClassMap } from "./Section.map";

export interface ISectionIconTextList {
  title?: string;
  titleDisplay?: TitleDisplay;
  items: IIconTextItem[];
  theme?: Theme;
  listType?: AvailableTag;
  hasBorders?: boolean;
  columns: Columns;
  iconWidth?: IconWidth;
  cta?: ILink;
}

const SectionIconTextList: FC<ISectionIconTextList> = ({
  title,
  titleDisplay = "default",
  items,
  theme,
  listType,
  columns,
  hasBorders = true,
  iconWidth = "default",
  cta,
}) => {
  const getTitleDisplay = titleDisplay === "default" ? "title" : titleDisplay;
  return (
    <Section component={SectionIconTextList.name} theme={hasBorders ? theme : "default"} size={hasBorders ? "xl" : "none"}>
      <Container theme={theme} className={`${!hasBorders && borderlessSectionBackgroundClassMap[theme ?? "default"]} ${themeRootClassMap[theme ?? "default"]}`}>
        { !hasBorders && (
          <Spacer size="xl" />
        ) }
        { title && (
          <>
            <Text tag="h2" display={getTitleDisplay || "title"} size={titleDisplay === "subtitle" ? legacySizeCollectionMap.titleMd : legacySizeCollectionMap.titleLg} align="center">{ title }</Text>
            <Spacer size={titleDisplay === "subtitle" ? "lg" : "2xl"} />
          </>
        ) }
        <IconTextList items={items} listType={listType} columns={columns} iconWidth={iconWidth}/>
        { !hasBorders && (
          <Spacer size="xl" />
        ) }
      </Container>
      { cta ? (
        <>
          <Spacer size="xl" />
          <Container>
            <Grid>
              <Column justify="center" align="center">
                <Button { ...cta }>{ cta?.name }</Button>
              </Column>
            </Grid>
          </Container>
        </>
      ) : null }
    </Section>
  );
};

export default SectionIconTextList;
