import React from "react";

import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { Theme } from "apps/website/maps/Theme.map";
import SectionTrustPilotReviewWall from
  "apps/website/components/section/SectionTrustPilotReviewWall/SectionTrustPilotReviewWall";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Layout } from "apps/website/components/feature/Review/Review.map";

import {
  IStoryBlokReviews,
  getExternalReviewsFromIStoryBlokReviews,
} from "./StoryBlokCarousel";

export interface IStoryBlokStickyNoteListItem {
  title: string;
  body: string
  theme: Theme;
}

export interface IStoryBlokTrustPilotReviewWall {
  title?: string;
  footer: string;
  reviews: IStoryBlokReviews[];
  layout?: Layout;
  theme?: Theme;
}

export const StoryBlokTrustPilotReviewWall = ({ blok }: IStoryBlokComponent<IStoryBlokTrustPilotReviewWall>) => (
  <SectionTrustPilotReviewWall
    title={blok.title}
    footer={renderStoryBlokRichText(blok.footer)}
    reviews={getExternalReviewsFromIStoryBlokReviews(blok.reviews)}
    layout={blok.layout}
    theme={blok.theme}
  />
);
