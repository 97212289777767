import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import { ITagListItemProps } from "../TagList/TagListItem/TagListItem";
import TagList from "../TagList/TagList";
import { FontSize, fontSizeMap } from "../ImageText/ImageText.map";
import { AvailableTag } from "../../base/Text/Text.map";

export type Direction = "default" | "reverse";

export interface IImageTagListProps {
  title: string;
  body?: string;
  subtitle?: string;
  tagList: ITagListItemProps[];
  image: IImageProps;
  direction?: Direction;
  fontSize?: FontSize;
  titleTag?: AvailableTag;
}

const ImageTagList: FC<IImageTagListProps> = ({ title, subtitle, body, tagList, image, direction, fontSize = "default", titleTag = "h2" }) => {
  const order = direction === "default" ? { image: "order-first", text: "order-last" } : { image: "order-last", text: "order-first" };
  return (
    <Grid component={ImageTagList.name} gap="lg">
      <Column spans={{ lg: 6 }} className={order.image}>
        <Image image={image} alt={title} />
      </Column>
      <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
      <Column spans={{ lg: 5 }} className={order.text}>
        { title && (
          <>
            <Text tag={titleTag} display="title" size={fontSizeMap[fontSize].title} data-testid="ImageTagListTitle">{ title }</Text>
            <Spacer size={fontSize === "default" ? "xl" : "lg"} />
          </>
        ) }
        { subtitle && (
          <>
            <Text tag={titleTag === "h1" ? "h2" : "h3"} display="subtitle" size={fontSizeMap[fontSize].subtitle} data-testid="ImageTagListSubtitle">{ subtitle }</Text>
            <Spacer size="lg" />
          </>
        ) }
        { body && (
          <>
            <Text tag="div" data-testid="ImageTagListBody">
              { body }
            </Text>
            <Spacer size="md" />
          </>
        ) }
        <TagList items={tagList} />
      </Column>
    </Grid>
  );
};

export default ImageTagList;
