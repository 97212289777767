import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import SectionIconTextList from
  "apps/website/components/section/SectionIconTextList/SectionIconTextList";
import {
  Columns,
  IconWidth,
  TitleSize,
  TitleDisplay,
} from "apps/website/components/feature/IconTextList/IconTextListItem/IconTextListItem.map";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";

export interface IStoryBlokIconTextListItems {
  items: IStoryBlokIconTextListItem[];
  columns: Columns;
}

export interface IStoryBlokIconTextListItem {
  image: IStoryBlokImage;
  title: string;
  title_size?: TitleSize;
  body: string;
  cta?: [IStoryBlokCta];
}

export interface IStoryBlokIconTextList {
  title_display?: TitleDisplay;
  title?: string;
  items: [IStoryBlokIconTextListItems];
  theme: Theme
  list_type: AvailableTag;
  contain: boolean;
  icon_width?: IconWidth;
}

export const StoryBlokIconTextList = ({ blok }: IStoryBlokComponent<IStoryBlokIconTextList>) => (
  <SectionIconTextList
    title={blok.title}
    titleDisplay={blok.title_display || "default"}
    items={blok.items[0]?.items.map((item) => ({
      image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_SM),
      title: item.title,
      titleSize: item.title_size || "default",
      body: item.body,
      cta: item.cta?.[0] ? getStoryBlokCta(item.cta[0]) : undefined,
    }))}
    listType={blok.list_type}
    theme={blok.theme}
    hasBorders={!blok.contain}
    columns={blok.items[0]?.columns}
    iconWidth={blok.icon_width || "default"}
  />
);
