import React, { FC } from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import cx from "apps/website/utils/misc/cx";

import { dividerColorMap } from "./constants";
import { ISectionDividerProps } from "./types";

const SectionDivider: FC<ISectionDividerProps> = ({ backgroundTheme = "default", dividerColor = "mid-grey", backgroundClassName, dividerClassName, "data-testid": testID }) => (
  <div data-component={ SectionDivider.name } data-testid={testID} className={cx(`w-full ${themeRootClassMap[backgroundTheme]} py-2`, backgroundClassName)}>
    <hr data-testid={`${testID}-line`} className={cx(`border-0 h-px ${dividerColorMap[dividerColor]}`, dividerClassName)} />
  </div>
);

export default SectionDivider;
