import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import ArticleCardCarousel from
  "apps/website/components/feature/Carousel/ArticleCardCarousel/ArticleCardCarousel";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  IArticleCard,
} from "apps/website/components/feature/Card/ArticleCard/ArticleCard";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface ISectionArticleCardCarousel {
  title?: string;
  body?: string;
  items: IArticleCard[];
  theme?: Theme;
}

const SectionArticleCardCarousel: FC<ISectionArticleCardCarousel> = ({ title, body, items, theme = "default" }) => (
  <Section component="SectionArticleCardCarousel" data-theme={theme} className={themeRootClassMap[theme]}>
    <Container>
      <>
        { title && (
          <Grid>
            <Column spans={{ lg: 8 }} offset={{ lg: 3 }} >
              <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">{ title }</Text>
              <Spacer size="lg" />
            </Column>
          </Grid>
        ) }
        { body && (
          <Grid>
            <Column spans={{ lg: 8 }} offset={{ lg: 3 }}>
              <Text align="center" size={legacySizeCollectionMap.bodyLg}>{ body }</Text>
              <Spacer size="xl" />
            </Column>
          </Grid>
        ) }
        <ArticleCardCarousel items={items} />
      </>
    </Container>
  </Section>
);

export default SectionArticleCardCarousel;
