import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import SectionLabelText from
  "apps/website/components/section/SectionLabelText/SectionLabelText";

interface IStoryBlokLabelText {
  title: string;
  body: string;
  label_prefix?: string;
  label: string;
  label_suffix?: string;
  theme?: Theme;
}

export const StoryBlokLabelText = ({ blok }: IStoryBlokComponent<IStoryBlokLabelText>) => (
  <SectionLabelText
    title={blok.title}
    body={blok.body}
    labelPrefix={blok.label_prefix}
    label={blok.label}
    labelSuffix={blok.label_suffix}
    theme={blok.theme}
  />
);
