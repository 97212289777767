import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import ProductCard, {
  IProductCard,
} from "apps/website/components/feature/Card/ProductCard/ProductCard";
import Text from "apps/website/components/base/Text/Text";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";

import { Offset } from "../../layout/Column/Column.map";
import { CardCtaAction } from "../../feature/Card/Card";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface IProductList {
  title?: string;
  subtitle?: string;
  subtitleDisplay?: Display;
  theme?: Theme;
  items: IProductCard[];
  ctaAction?: CardCtaAction;
  cta?: ILink;
}

const SectionProductList: FC<IProductList> = (
  { title, subtitle, subtitleDisplay = "subtitle", theme = "default", items, cta, ctaAction },
) => {

  const offsetMap: Record<number, Offset> = {
    1: 6,
    2: 4,
  };

  const offset: Offset = offsetMap[items.length];

  return (
    <Section component="SectionProductList" theme={ theme } size={ getSectionSize(theme) }>
      <Container>
        { title && (
          <>
            <Text tag="h2" display="title" size={ legacySizeCollectionMap.titleLg } align="center">
              { title }
            </Text>
            <Spacer size="xl" />
          </>
        ) }
        { subtitle && (
          <Grid>
            <Column spans={ { lg: 10 } } offset={ { lg: 2 } }>
              <Text
                display={ subtitleDisplay }
                size={ subtitleDisplay === "subtitle" ?
                  legacySizeCollectionMap.titleMd :
                  { default: "default", lg: "md" }
                }
                align="center"
                whitespace={ { lg: "preWrap" } }
              >{ subtitle }</Text>
              <Spacer size="xl" />
            </Column>
          </Grid>
        ) }
        <Grid>
          <>
            { items.map((product, index) => (
              <Column
                key={ product.title } spans={ { default: ctaAction === "link" ? 12 : 6, lg: 3 } }
                offset={ { lg: index === 0 ? offset : undefined } }
                className={ items.length === 1 ? "translate-x-[calc(-25%+16px)]" : undefined }
              >
                <ProductCard { ...product } />
              </Column>
            )) }
            { cta && (
              <Column align="center">
                <Spacer size="xl" />
                <Button
                  to={ cta.to }
                  design={ cta.design }
                  color={ cta.color }
                  data-testid={ cta.testId }
                >{ cta.name }</Button>
              </Column>
            ) }
          </>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionProductList;
