import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionArrow from "../../section/SectionDownArrow/SectionArrow";
import { TDirection } from "../../section/SectionDownArrow/SectionArrow.map";
import { BorderTheme } from "../../section/SectionDownArrow/shared/types";

export interface IStoryBlokSectionArrow {
  direction?: TDirection;
  backgroundTheme?: Theme;
  arrowTheme?: BorderTheme;
}

export const StoryBlokArrow = ({ blok }: IStoryBlokComponent<IStoryBlokSectionArrow>) => (
  <SectionArrow
    direction={ blok.direction }
    backgroundTheme={ blok.backgroundTheme }
    arrowTheme={ blok.arrowTheme }
  />
);
