import { FC, useMemo } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { LIST_FORMATTER } from "apps/website/utils/misc/arrays";
import { pluralise } from "apps/website/utils/misc/strings";

import Icon from "../../base/Icon/Icon";
import TextBody from "../../base/Text/TextBody/TextBody";

export interface ITimeToCompleteProps {
  hours?: number;
  minutes?: number;
  isApprox?: boolean;
  theme?: Theme;
}

export const getTimeToComplete = (hours: number, minutes: number): string => {
  const timeArray: string[] = [];
  if (!!hours && hours > 0) timeArray.push(`${hours} ${pluralise("hour", "hours", hours)}`);
  if (!!minutes && minutes > 0) timeArray.push(`${minutes} ${pluralise("minute", "minutes", minutes)}`);
  return LIST_FORMATTER.format(timeArray);
};

const TimeToComplete: FC<ITimeToCompleteProps> = ({ hours = 0, minutes = 0, isApprox = true, theme = "light" }) => {
  const timeToComplete = useMemo((): string => getTimeToComplete(hours, minutes), [ hours, minutes ]);

  return (
    <div
      data-component={ TimeToComplete.name }
      data-theme={ theme }
      className={ `rounded-full flex space-x-2 lg:space-x-4 items-center w-fit ${themeRootClassMap[theme]} ${theme !== "default" ? "p-2 px-4" : null}`}>
      <div className="h-4 w-4 lg:w-6 lg:h-6">
        <Icon icon="clock" size="fit" />
      </div>
      <TextBody tag="span" size={ 2 }>{ isApprox ? "Approx. " : null }{ timeToComplete }</TextBody>
    </div>
  );
};

export default TimeToComplete;
