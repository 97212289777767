import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import TagCluster, { IClusterTag } from "../../feature/TagCluster/TagCluster";
import Container from "../../layout/Container/Container";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";
import {
  borderlessSectionBackgroundClassMap,
} from "../SectionIconTextList/Section.map";

export interface ISectionTagClusterProps {
  title?: string;
  theme: Theme;
  tags: IClusterTag[];
  hasBorders?: boolean;
}

const SectionTagCluster: FC<ISectionTagClusterProps> = ({ title, theme, tags, hasBorders }) => (
  <Section component={SectionTagCluster.name} theme={hasBorders ? theme : "default"} size={hasBorders ? "xl" : "none"}>
    <Container
      className={`${(!hasBorders && theme) ? `${themeRootClassMap[theme]} ${borderlessSectionBackgroundClassMap[theme]}` : undefined}`}
      theme={!hasBorders ? theme : undefined}
      padding={theme === "default" ? "default" : "large"}
    >
      { title && (
        <>
          <Text tag="h2" display="title" size={legacySizeCollectionMap.titleMd} align="center">
            { title }
          </Text>
          <Spacer size="lg" />
        </>
      ) }
      <TagCluster tags={tags} />
      { !hasBorders && (
        <Spacer size="xl" />
      ) }
    </Container>
  </Section>
);

export default SectionTagCluster;
