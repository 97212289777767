import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

import Image, { IImageProps } from "../../base/Image/Image";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";

export interface IQuoteCallout {
  quote: string;
  author: string;
  image: IImageProps;
  imageTheme?: Theme;
  theme?: Theme;
}

const QuoteCallout: FC<IQuoteCallout> = ({ quote, author, image, theme = "light", imageTheme }) => (
  <div data-component={QuoteCallout.name} data-theme={theme} className={`w-full p-2 ${themeRootClassMap[theme]}`}>
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-center mr-4">
        <figure>
          <Text tag="blockquote" align="center" size={legacySizeCollectionMap.bodySm} className="pb-0">&ldquo;{ quote }&rdquo;</Text>
          <Spacer size="md" />
          <Text tag="figcaption" align="center" size={{ default: "3xs", md: "2xs" }} display="subtitle">{ author }</Text>
        </figure>
      </div>
      <div className={"items-center justify-center relative aspect-[3/4] w-16 flex-none"}>
        <Image image={image} alt={image.alt ?? ""} className={themeRootClassMap[imageTheme ?? "default"]}/>
        <Image image={{
          src: "/images/features/team/frame.png",
          height: 232,
          width: 183,
        }} alt=""
        className="absolute top-0 left-0"
        />
      </div>
    </div>
  </div>
);

export default QuoteCallout;
