export const rotationMap = {
  [-12]: "-rotate-12",
  [-6]: "-rotate-6",
  [-3]: "-rotate-3",
  [-2]: "-rotate-2",
  [-1]: "-rotate-1",
  0: "",
  1: "rotate-1",
  2: "rotate-2",
  3: "rotate-3",
  6: "rotate-6",
  12: "rotate-12",
} as const;

export type AvailableRotation = keyof typeof rotationMap;
