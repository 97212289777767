import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import { DividerColor } from "../../section/SectionDivider/types";
import SectionDivider from "../../section/SectionDivider/SectionDivider";
import Container from "../../layout/Container/Container";

export interface IStoryBlokSectionDivider {
  background_theme?: Theme;
  divider_color?: DividerColor;
}

export const StoryBlokDivider = ({ blok }: IStoryBlokComponent<IStoryBlokSectionDivider>) => (
  <Container>
    <SectionDivider
      backgroundTheme={ blok.background_theme }
      dividerColor={ blok.divider_color }
    />
  </Container>
);
