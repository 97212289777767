import { FC, ReactNode, useMemo } from "react";

export type DiscountCodeMatchRule = "equalTo" | "notEqualTo" | "contains";

export interface ISectionDiscountCodeContentProps {
  discountCode: string;
  codeToMatch: string;
  codeMatchRule: DiscountCodeMatchRule;
  children: ReactNode;
}

const SectionDiscountCodeContent: FC<ISectionDiscountCodeContentProps> = (
  { discountCode, codeToMatch, codeMatchRule, children },
) => {

  const showContent = useMemo((): boolean => {
    if (codeMatchRule === "equalTo" && discountCode === codeToMatch) return true;
    if (codeMatchRule === "notEqualTo" && discountCode !== codeToMatch) return true;
    if (codeMatchRule === "contains" && discountCode?.includes(codeToMatch)) return true;
    return false;
  }, [ codeToMatch, discountCode, codeMatchRule ]);

  return (
    <>
      { showContent && (
        <div data-component={SectionDiscountCodeContent.name}>
          { children }
        </div>
      ) }
    </>
  );
};

export default SectionDiscountCodeContent;
