import {
  TMenuMap,
  EMenuItemStyle,
  EMenuItemType,
  EMenu,
  IMenuDetails,
  EMenuState,
} from "./usePopoverMenuStore.types";

export const INITIAL_STATE: IMenuDetails = {
  state: EMenuState.CLOSED,
  title: "",
  items: [],
};

export const MENUS: TMenuMap = {
  [EMenu.MANAGE_PLAN]: {
    title: "Your plan. your way",
    // @ts-ignore
    items: [
      {
        type: EMenuItemType.LINK,
        title: "Edit delivery frequency",
        description: "Change how often you receive fresh food boxes.",
        style: EMenuItemStyle.DEFAULT,
        url: "", // Leave blank for now
      },
      {
        type: EMenuItemType.LINK,
        title: "Update portion sizes",
        description: "Increase or decrease daily calories.",
        style: EMenuItemStyle.DEFAULT,
        url: "", // Leave blank for now
      },
      {
        type: EMenuItemType.LINK,
        title: "Change cat details",
        description: "Update info like age, weight + allergies.",
        style: EMenuItemStyle.DEFAULT,
        url: "", // Leave blank for now
      },
      {
        type: EMenuItemType.LINK,
        title: "Amend recipe selection",
        description: "Mix up recipes. Or try something new.",
        style: EMenuItemStyle.DEFAULT,
        url: "", // Leave blank for now
      },
      {
        type: EMenuItemType.LINK,
        title: "Manage extras",
        description: "Add tasty treats + meal toppers.",
        style: EMenuItemStyle.DEFAULT,
        url: "", // Leave blank for now
      },
      {
        type: EMenuItemType.LINK,
        title: "Thinking of cancelling?",
        style: EMenuItemStyle.BASIC,
        url: "", // Leave blank for now
      },
    ] as const,
  },
} as const;
