import { useEffect, useState } from "react";

import {
  IStoryBlokComponent,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";
import { DisplayState } from "@/constants/state";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import { IStoryBlokFakeLoading } from "../StoryBlokFakeLoading";
import SectionFakeLoading from "../../../section/SectionFakeLoading/SectionFakeLoading";

export interface IStoryBlokFormFakeLoading extends IStoryBlokFakeLoading {
  continue_to_path: IStoryBlokLink;
  continue_to_text: string;
}

export const StoryBlokFormFakeLoading = ({ blok }: IStoryBlokComponent<IStoryBlokFormFakeLoading>) => {

  const { chainQueryParamsToUrl } = useQueryParams();
  const [ loadingTimeComplete, setLoadingTimeComplete ] = useState(false);
  const [ linkState, setLinkState ] = useState(DisplayState.READY);

  useEffect(() => {
    setLinkState(DisplayState.READY);
  }, []);

  return (
    <>
      <SectionFakeLoading
        loadingText={blok.loading_text}
        tickInterval={(blok.tick_interval ?? 0) * 1000}
        loadingTime={(blok.loading_time ?? 0) * 1000}
        onTimerReady={() => setLoadingTimeComplete(true)}
      />
      <div className={`fixed bottom-0 left-0 w-full p-4 z-30 transform transition-all ease-in-out duration-300 ${loadingTimeComplete ? "translate-y-0 opacity-100" : "translate-y-[100%] opacity-0"} ${themeRootClassMap.brand}`}>
        <Container>
          <Grid>
            <Column
              spans={{ default: 12, xl: 6 }}
              offset={{ default: 1, xl: 4 }}
              align={"center"}
            >
              <Button
                to={chainQueryParamsToUrl(getStoryBlokLink(blok.continue_to_path))}
                state={linkState}
                onClick={() => setLinkState(DisplayState.PROCESSING)}
              >
                { blok.continue_to_text }
              </Button>
            </Column>
          </Grid>
        </Container>
      </div>
    </>
  );
};
