import React from "react";

import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import StickyNoteListSection from
  "apps/website/components/section/SectionStickyNoteList/SectionStickyNoteList";
import { AvailableTag } from "apps/website/components/base/List/List.map";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import {
  ReduceGap,
} from "apps/website/components/feature/StickyNoteList/StickyNoteList.map";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";

export interface IStoryBlokStickyNoteListItem {
  title: string;
  body: string
  theme: Theme;
  image: IStoryBlokImage;
  reduce_gap: ReduceGap
}

export interface IStoryBlokStickyNoteList {
  title: string;
  sticky_notes: IStoryBlokStickyNoteListItem[];
  list_type: AvailableTag;
  cta?: [IStoryBlokCta];
}

export const StoryBlokStoryBlokStickyNoteList = ({ blok }: IStoryBlokComponent<IStoryBlokStickyNoteList>) => (
  <StickyNoteListSection
    title={blok.title}
    items={blok.sticky_notes.map((item) => ({
      title: item.title,
      body: renderStoryBlokRichText(item.body),
      theme: item.theme,
      reduceGap: item.reduce_gap,
      image: item.image ? getStoryBlokImage(item.image) : undefined,
    }))}
    tag={blok.list_type}
    cta={blok.cta?.length ? getStoryBlokCta(blok.cta[0]) : undefined}
  />
);
