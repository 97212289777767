import { FC } from "react";

import SocialFeedCarousel from
  "apps/website/components/feature/Carousel/SocialFeedCarousel/SocialFeedCarousel";
import Button from "apps/website/components/base/Button/Button";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Icon from "apps/website/components/base/Icon/Icon";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme } from "apps/website/maps/Theme.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import {
  makeTitleFromArray,
  replaceWordsWithSticker,
} from "apps/website/utils/content/text";
import { ITitle } from "apps/website/components/page/component.types";
import useChunks from "apps/website/hooks/useChunks";

export interface ISocial {
  icon: IconSvg;
  cta?: ILink;
}

export interface ISectionSocialFeed {
  title: ITitle;
  body: string;
  socials: ISocial[];
  images: IImageProps[];
  theme: Theme;
  numberOfLines?: number;
  showSpacer?: boolean;
}

const SectionSocialFeed: FC<ISectionSocialFeed> = ({ title, body, socials, images, theme = "default", numberOfLines = 3, showSpacer = true }) => {

  const completeTitle = replaceWordsWithSticker(makeTitleFromArray(title.lines), title?.stickerSize ?? "", theme);
  const imageChunks = useChunks(images, Math.ceil(images.length / (numberOfLines > 0 ? numberOfLines : 1)));

  return (
    <Section component="SectionSocialFeed" theme={theme} size="none">
      <Container>
        <Grid>
          { completeTitle ? (
            <Column>
              <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
                <span dangerouslySetInnerHTML={{ __html: completeTitle }} />
              </Text>
              <Spacer size="md" />
            </Column>
          ) : <></> }
          { body ? (<Column>
            <Text tag="div" align="center" size={legacySizeCollectionMap.bodyLg} whitespace={{ lg: "preWrap" }}>{ body }</Text>
            <Spacer size="xl" />
          </Column>) : <></> }
          { socials ? (<Column spans={{ lg: 10 }} offset={{ lg: 2 }} direction="row" justify="center" align="center" className="space-y-4 lg:space-x-4 lg:space-y-0 flex-wrap">
            { socials.map((social) => (
              <div className="flex space-x-4 w-full lg:w-auto" key={social.cta?.to}>
                <Icon icon={social.icon} />
                <Button
                  to={social.cta?.to}
                  design={social.cta?.design}
                  color={social.cta?.color}
                  className="flex-1"
                >
                  { social.cta?.name }
                </Button>
              </div>
            )) }
          </Column>) : <> </> }
        </Grid>
      </Container>
      { showSpacer && <Spacer size="2xl" /> }
      <div className="flex flex-row flex-wrap space-y-4">
        {
          imageChunks && imageChunks.length > 0 && imageChunks.map((chunk, index) => (
            <SocialFeedCarousel items={chunk} key={index} reverse={index % 2 !== 0} />
          ))
        }
      </div>
    </Section>
  );
};

export default SectionSocialFeed;
