import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionTrustPilotFeaturedReview from "../../section/SectionTrustPilotFeaturedReview/SectionTrustPilotFeaturedReview";

import {
  getExternalReviewsFromIStoryBlokReview,
  IStoryBlokReviews,
} from "./StoryBlokCarousel";

interface IStoryBlokReviewTrustpilotFeature {
  review: IStoryBlokReviews;
  stars_prefix: string;
  stars_suffix: string;
  stars_body: string;
  footer_title: string;
  footer_body: string;
  theme: Theme,
}

export const StoryBlokReviewTrustpilotFeature = ({ blok }: IStoryBlokComponent<IStoryBlokReviewTrustpilotFeature>) => (
  <SectionTrustPilotFeaturedReview
    review={getExternalReviewsFromIStoryBlokReview(blok.review.content)}
    starsPrefix={blok.stars_prefix}
    starsSuffix={blok.stars_suffix}
    starsBody={blok.stars_body}
    footerTitle={blok.footer_title}
    footerBody={blok.footer_body}
    theme={blok.theme}
  />
);
