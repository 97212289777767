import { FC, useMemo } from "react";

import { slugify } from "apps/website/utils/content/text";

import List from "../../base/List/List";

import ConditionalCheckListItem, {
  IConditionalCheckLisItemProps,
} from "./ConditionalCheckListItem/ConditionalCheckListItem";

export interface IConditionalCheckListProps {
  data: Record<string, string | string[]>;
  items: IConditionalCheckLisItemProps[];
  max?: number;
}

const ConditionalCheckList: FC<IConditionalCheckListProps> = ({ data, items, max = 2 }) => {

  const filteredItems = useMemo((): IConditionalCheckLisItemProps[] => {
    const conditionalItems: IConditionalCheckLisItemProps[] = [];
    items.forEach((mappedItem) => {
      if (mappedItem.condition.key === "" && mappedItem.condition.value === "") {
        conditionalItems.push(mappedItem);
      }
      const dataKeyMatch = data[mappedItem.condition.key];
      const splitConditionValue: string[] = mappedItem.condition.value.split(",");
      if (!dataKeyMatch) return;
      if (
        Array.isArray(dataKeyMatch) &&
        dataKeyMatch.some((dataKeyMatchItem) => mappedItem.condition.value.includes(dataKeyMatchItem))
      ) {
        conditionalItems.push(mappedItem);
      } else if (typeof dataKeyMatch === "string" && splitConditionValue.includes(dataKeyMatch)) {
        conditionalItems.push(mappedItem);
      }
    });
    return conditionalItems.slice(0, max);
  }, [ data, items, max ]);

  return (<>
    <List component={ConditionalCheckList.name}>
      { filteredItems.map((item) => (
        <ConditionalCheckListItem key={`${item.condition.key}-${item.condition.value}-${slugify(item.text)}`} {...item} />
      )) }
    </List>
  </>
  );
};

export default ConditionalCheckList;
