import { FC } from "react";

import Image, { IImage } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { renderTableValue } from "apps/website/utils/content/table";
import Table from "apps/website/components/feature/Table/Table";
import {
  cellStyles,
  rowAltStyles,
  rowStyles,
  tableStyles,
  titleStyles,
  valueStyles,
} from "apps/website/components/feature/Table/Table.map";

// TODO: move ITableTitle
export interface ITableTitle {
  id: string;
  name: string;
  image?: IImage;
}

export interface IColumnRowTableRow {
  title: string;
  values: (boolean | string)[];
}

export interface IColumnRowTable {
  component?: string,
  columnTitles: ITableTitle[];
  rows: IColumnRowTableRow[];
  highlightColumn?: number;
}

const ColumnRowTable: FC<IColumnRowTable> = ({ columnTitles, rows, component = "ColumnRowTable", highlightColumn }) => (
  <Table component={component} className={tableStyles}>
    <tr className={rowStyles}>
      <td></td>
      { columnTitles.map((title, index) => (
        <th key={title.id} scope="col" className={`${titleStyles} ${index > 2 && "hidden lg:table-cell"} ${highlightColumn === index && "relative bg-white border-x-4 border-solid border-black"}`}>
          { title.image ? (
            <>
              <Image image={title.image.image} alt={title.image.alt} />
              <span className="sr-only">{ title.name }</span>
            </>
          ) : (
            <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleSm}>
              { title.name }
            </Text>
          ) }
          { highlightColumn === index && (
            <>
              <div className="bg-white absolute w-[calc(100%+8px)] h-[20px] -top-[18px] -left-[4px] border-x-4 border-t-4 border-black border-solid" />
              <div className="bg-white absolute w-full h-[3px] -bottom-[2px] left-0" />
            </>
          ) }
        </th>
      )) }
    </tr>
    { rows.map((row, rowIndex) => (
      <tr key={row.title} className={`${rowStyles} ${rowIndex % 2 === 0 && rowAltStyles}`}>
        <th scope="row" className={`text-left ${cellStyles}`}>
          <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleSm}>
            { row.title }
          </Text>
        </th>
        { row.values.map((value, valueIndex) => (
          <td key={`value-${rowIndex}-${valueIndex}`} className={`${valueStyles} ${cellStyles} ${valueIndex > 2 && "hidden lg:table-cell"} ${highlightColumn === valueIndex && "relative bg-white border-x-4 border-solid border-black"}`}>
            { renderTableValue(value, highlightColumn === valueIndex) }
            { highlightColumn === valueIndex && (
              <>
                <div className="bg-white absolute w-full h-[3px] -bottom-[2px] left-0" />
                { rowIndex + 1 === rows.length && (
                  <div className="bg-white absolute w-[calc(100%+8px)] h-[20px] -bottom-[18px] -left-[4px] border-x-4 border-b-4 border-black border-solid" />
                ) }
              </>
            ) }
          </td>
        )) }
      </tr>
    )) }
  </Table>
);

export default ColumnRowTable;
