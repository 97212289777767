import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import {
  ImageMax,
  getStoryBlokAdvancedImages,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import { Theme } from "apps/website/maps/Theme.map";
import {
  Direction,
} from "apps/website/components/feature/ImageTextList/ImageTextListItem/ImageTextListItemRow";
import {
  IStoryBlokAdvancedImages,
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import ImageText from "apps/website/components/section/SectionImageText/SectionImageText";
import {
  Size,
  FontSize,
  Layout,
} from "apps/website/components/feature/ImageText/ImageText.map";
import { AvailableTag } from "apps/website/components/base/Text/Text.map";

export interface IStoryBlokHardcoreTruth { // TODO: extraxt this
  body: string;
  link: {
    url: string
  }
}

export interface IStoryBlokImageTextProps {
  title: string;
  title_tag: AvailableTag;
  font_size?: FontSize;
  subtitle?: string;
  body: string;
  footer: string;
  ctas: [IStoryBlokCta];
  image: IStoryBlokImage;
  advanced_images?: IStoryBlokAdvancedImages;
  direction: Direction;
}

export interface IStoryBlokImageText {
  theme: Theme;
  layout: Layout;
  image_text: [IStoryBlokImageTextProps];
  size: Size;
  title_tag: AvailableTag; // Legacy, removed from StoryBlok now
  with_logo?: boolean;
}

export const StoryBlokImageText = ({ blok }: IStoryBlokComponent<IStoryBlokImageText>) => (
  <ImageText
    title={ blok.image_text[0]?.title }
    titleTag={ blok.image_text[0]?.title_tag || blok.title_tag || "h2" }
    fontSize={ blok.image_text[0]?.font_size || "default" }
    size={ blok.size || "auto" }
    subtitle={ blok.image_text[0]?.subtitle }
    body={ renderStoryBlokRichText(blok.image_text[0]?.body) }
    footer={ blok.image_text[0]?.footer }
    cta={ blok.image_text[0].ctas.map((c) => getStoryBlokCta(c)) }
    image={ getStoryBlokImage(blok.image_text[0]?.image, true, blok.layout === "secondary" ? ImageMax.HALF_SCREEN : ImageMax.HALF_CONTAINER_2XL) }
    advancedImages={ getStoryBlokAdvancedImages(blok.image_text[0]?.advanced_images) }
    disableImageLazyLoading={ blok.image_text[0]?.advanced_images?.disableLazyLoading }
    theme={ blok.theme }
    layout={ blok.layout }
    direction={ blok.image_text[0]?.direction }
    withLogo={ blok.with_logo || false }
  />
);
