import { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import { Theme } from "apps/website/maps/Theme.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import QuoteCallout, {
  IQuoteCallout,
} from "apps/website/components/feature/QuoteCallout/QuoteCallout";

import Container from "../../layout/Container/Container";
import QuoteCalloutSecondary from "../../feature/QuoteCallout/QuoteCalloutSecondary/QuoteCalloutSecondary";

export type TQuoteCalloutStyle = "default" | "secondary";
export interface ISectionQuoteCallout {
  theme?: Theme;
  style?: TQuoteCalloutStyle;
  quote: IQuoteCallout
}

const SectionQuoteCallout: FC<ISectionQuoteCallout> = ({ theme = "light-grey", quote, style = "default" }) => (
  <Section component={SectionQuoteCallout.name} theme={theme} size="md">
    <Container>
      <Grid>
        <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
          { style === "secondary" ? (
            <QuoteCalloutSecondary {...quote} />
          ) : (
            <QuoteCallout {...quote} />
          ) }
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionQuoteCallout;
