import { FC, memo, ReactNode } from "react";

import { ILink } from "apps/website/components/base/Button/Button.map";
import Text from "apps/website/components/base/Text/Text";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { AvailableTag } from "apps/website/components/base/Text/Text.map";
import Image, {
  IAdvancedImages,
  IImageProps,
} from "apps/website/components/base/Image/Image";

import { Direction } from "../ImageAccordionList/ImageAccordionList";

import {
  FontSize,
  fontSizeMap,
  Layout,
  orderMap,
  Size,
  sizeClassMap,
} from "./ImageText.map";

export interface IImageText {
  title: string;
  titleTag?: AvailableTag;
  fontSize?: FontSize;
  subtitle?: string;
  body: string | ReactNode;
  footer?: string;
  image: IImageProps;
  advancedImages?: IAdvancedImages;
  disableImageLazyLoading?: boolean;
  cta?: (ILink | undefined)[];
  direction?: Direction;
  layout?: Layout;
  size?: Size;
}

const ImageText: FC<IImageText> = ({
  title,
  titleTag = "h2",
  fontSize = "default",
  subtitle,
  body,
  footer,
  image,
  advancedImages,
  disableImageLazyLoading = false,
  cta,
  direction = "default",
  layout = "default",
  size = "auto",
}) => (
  <div className={`${layout === "secondary" && "flex items-center"} ${sizeClassMap[size]}`}>
    { (title && layout === "default") && (
      <Grid>
        <Column>
          <Text tag={titleTag} display="title" size={fontSizeMap[fontSize].title} align="center">{ title }</Text>
          <Spacer size={fontSize === "default" ? "xl" : "lg"} />
        </Column>
      </Grid>
    ) }
    <Grid className="h-full w-full">
      <Column spans={{ lg: 6 }} className={`relative ${orderMap[direction].image}`}>
        <div className={`relative ${layout === "secondary" && "block lg:hidden"}`}>
          <Image
            image={image} alt={image.alt || title}
            advancedImages={advancedImages}
            lazy={!disableImageLazyLoading}
          />
        </div>
        <div className={`absolute h-full lg:hidden w-screen left-1/2 -translate-x-1/2 transform ${[ "default", "tertiary" ].includes(layout) && "hidden"}`}>
          <Image
            image={image} alt={image.alt || title}
            advancedImages={advancedImages}
            lazy={!disableImageLazyLoading}
            cover
          />
        </div>
      </Column>
      <Column spans={{ lg: 1 }} className="hidden lg:flex">&nbsp;</Column>
      <Column spans={{ lg: 5 }} justify="center" className={`transition-all ease-in-out duration-1000 ${orderMap[direction].text}`}>
        { (title && [ "secondary", "tertiary" ].includes(layout)) && (
          <>
            { [ "reverse", "reverseDesktop", "reverseMobile" ].includes(direction) && (
              <Spacer size="xl" className="lg:hidden"/>
            ) }
            <Text tag={titleTag} display="title" size={fontSizeMap[fontSize].title}>{ title }</Text>
            <Spacer size={fontSize === "default" ? "xl" : "lg"} />
          </>
        ) }
        { subtitle && (
          <>
            <Text tag={titleTag === "h1" ? "h2" : "h3"} display="subtitle" size={fontSizeMap[fontSize].subtitle}>{ subtitle }</Text>
            <Spacer size="md" />
          </>
        ) }
        <Text tag="div" size={fontSizeMap[fontSize].body}>{ body }</Text>
        { cta && cta.map((c) => (
          <>
            <Spacer size="xl" />
            <Button
              {...c}
              data-testid={c?.testId}
            >{ c?.name }</Button>
          </>
        )) }
        { footer && (
          <>
            <Spacer size={fontSize === "default" ? "xl" : "lg"} />
            <Text>{ footer }</Text>
          </>
        ) }
        <Spacer size="xl" className="lg:hidden"/>
      </Column>
    </Grid>
  </div>
);

export default memo(ImageText);
