import { FC, memo } from "react";

import {
  ReduceGap,
} from "apps/website/components/feature/StickyNoteList/StickyNoteList.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";

export interface IStickyNoteListItem {
  title: string;
  body: string;
  image?: IImageProps;
  theme: Theme;
  reduceGap?: ReduceGap;
}

const StickyNoteListItem: FC<IStickyNoteListItem> = ({ title, body, theme, image }) => (
  <div data-component="StickyNoteListItem" data-theme={theme} className={`p-12 ${themeRootClassMap[theme]}`}>
    <Grid>
      <Column>
        { image && (
          <>
            <Image image={{ ...image }} alt={title} />
            <Spacer size="lg" />
          </>
        ) }
        <Text tag="h3" display="title" size={legacySizeCollectionMap.titleMd} align="center">{ title }</Text>
        <Spacer size="md" />
        <Text tag="div" size={legacySizeCollectionMap.bodyLg} align="center">{ body }</Text>
      </Column>
    </Grid>
  </div>
);

export default memo(StickyNoteListItem);
