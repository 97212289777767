import { FC, ReactNode } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import {
  Size as ContainerSize,
} from "apps/website/components/layout/Container/Container.map";
import { Theme } from "apps/website/maps/Theme.map";

export interface ISectionFreeFlow {
  children: ReactNode;
  size?: Size;
  theme?: Theme;
  containerSize?: ContainerSize,
}

const SectionFreeFlow: FC<ISectionFreeFlow> = ({ children, size = "xl", theme = "default", containerSize = "default" }) => (
  <Section component={SectionFreeFlow.name} theme={theme} size={size}>
    <Container size={containerSize || "default"}>
      { children }
    </Container>
  </Section>
);

export default SectionFreeFlow;
