import { EProduct } from "@/constants/products";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";

import {
  IProductOverviewTabBulletPoint,
} from "./ProductOverviewTab/ProductOverviewTab";

type TProductOverviewTabs = Partial<
Record<EProduct, IProductOverviewTabBulletPoint[]>
>;

const DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS: IProductOverviewTabBulletPoint[] =
  [
    {
      Body: () => (
        <TextBody>
          Gently-cooked to protect nutrients + preserve moisture
        </TextBody>
      ),
    },
    {
      Body: () => <TextBody>Vet-formulated to be complete + balanced</TextBody>,
    },
  ];

export const PRODUCT_OVERVIEW_TABS_BULLET_POINTS: TProductOverviewTabs = {
  [EProduct.CLUCK]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">chicken</strong> thigh, liver +
          heart. Plus KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.GOBBLE]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">turkey</strong> thigh, liver +
          gizzards. Plus traces of chicken and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.QUACK]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">duck</strong> meat, gizzards and
          liver. Plus chicken heart, fish oil and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.MOOO]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">beef</strong> trim, heart and liver.
          Plus sunflower oil, fish oil and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.BAAA]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">lamb</strong> heart, trim and liver.
          Plus traces of beef, fish oil and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.OINK]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">pork</strong> shoulder, trim and
          liver – plus fish oil and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
  [EProduct.SPLASH]: [
    {
      Body: () => (
        <TextBody>
          Made with fresh, human-quality{ " " }
          <strong className="font-display">whitefish</strong>,{ " " }
          <strong className="font-display">salmon</strong> and{ " " }
          <strong className="font-display">turkey</strong> liver, plus sunflower
          oil and KatKin&apos;s nutrient mix
        </TextBody>
      ),
    },
    ...DEFAULT_PRODUCT_OVERVIEW_TABS_BULLET_POINTS,
  ],
};
