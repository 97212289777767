import { useDiscountStore } from "@./state";

import {
  StoryBlokDiscountCodeContent,
  IStoryBlokDiscountCodeContentWithCode,
} from "../StoryBlokDiscountCodeContent";

export const StoryBlokFormDiscountCodeContent = ({ flowId, blok }: IStoryBlokDiscountCodeContentWithCode) => {
  const { discountCodes } = useDiscountStore();
  const formComponent: IStoryBlokDiscountCodeContentWithCode = {
    flowId,
    blok,
    discountCode: discountCodes.find((code) => code.flowId === flowId)?.discountCode,
  };

  return (<StoryBlokDiscountCodeContent {...formComponent} />);
};
