import { FC, memo } from "react";

import Text from "apps/website/components/base/Text/Text";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  AvailableTag,
  BreakpointSize,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import InstructionCardsList, {
  IInstructionCardsListProps,
} from "../../feature/InstructionCardsList/InstructionCardsList";

export interface ISectionInstructionCardsList extends IInstructionCardsListProps {
  title?: string;
  titleTag?: AvailableTag;
  titleSize?: BreakpointSize;
  theme?: Theme;
}

const SectionInstructionCardsList: FC<ISectionInstructionCardsList> = ({
  "data-testid": testId,
  title,
  titleTag = "h2",
  titleSize = legacySizeCollectionMap.titleLg,
  theme = "default",
  ...props
}) => (
  <Section
    data-testid={testId}
    component={SectionInstructionCardsList.name}
    theme={theme}
  >
    <Container>
      { title && (
        <Grid>
          <Column>
            <Text
              tag={titleTag}
              display="title"
              size={titleSize}
              align="center"
            >{ title }</Text>
            <Spacer size={"xl"} />
          </Column>
        </Grid>
      ) }
      <InstructionCardsList
        {...props}
        arrows={props.listTag === "ol"}
      />
    </Container>
  </Section>
);

export default memo(SectionInstructionCardsList);
