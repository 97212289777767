import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { Theme } from "apps/website/maps/Theme.map";

import SectionComparisonCardList from "../../section/SectionComparisonCardList/SectionComparisonCardList";
import {
  IComparisonCardProps,
} from "../../feature/ComparisonCardList/ComparisonCard/ComparisonCard";
import { IconSvg } from "../../base/Icon/Icon.map";

interface IStoryBlokSimpleListItem {
  title: string;
  icon?: IconSvg;
}

interface IStoryBlokStoryBlokComparisonCard {
  title: string;
  image: IStoryBlokImage;
  tag: string;
  list_items: IStoryBlokSimpleListItem[]
  override_theme?: Theme;
}

export interface IStoryBlokStoryBlokComparisonCardList {
  title?: string;
  theme?: Theme;
  cards: [IStoryBlokStoryBlokComparisonCard, IStoryBlokStoryBlokComparisonCard]
}

export const StoryBlokStoryBlokComparisonCardList = (
  { blok }: IStoryBlokComponent<IStoryBlokStoryBlokComparisonCardList>,
) => (
  <SectionComparisonCardList
    title={blok.title}
    theme={blok.theme}
    cards={blok.cards.map((card, cardIndex) => ({
      image: getStoryBlokImage(card.image, true, ImageMax.HALF_CONTAINER_MD),
      theme: card.override_theme ? card.override_theme : cardIndex === 0 ? "green" : "dark",
      title: card.title,
      tag: card.tag ? { theme: "pink", children: card.tag } : undefined,
      listItems: card.list_items.map((item) => ({
        title: item.title,
        icon: item.icon ? item.icon : cardIndex === 0 ? "tickHeavy" : "crossHeavy",
      })),
    })) as [IComparisonCardProps, IComparisonCardProps]}
  />
);
