import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Button from "apps/website/components/base/Button/Button";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

export interface IAdvert {
  theme?: Theme;
  title: string;
  body?: string;
  cta?: ILink;
  image: IImageProps
}

const Advert: FC<IAdvert> = ({ title, body, image, cta, theme = "default" }) => (
  <div data-component={Advert.name} data-theme={theme}>
    <Grid component="Advert" gap="none" className={themeRootClassMap[theme]}>
      <Column spans={{ lg: 6 }} className="relative">
        <Image image={image} alt={title} />
        <div className="hidden lg:block xl:hidden h-full w-full"> { /* TODO: make this optional */ }
          <Image image={image} alt={title} cover/>
        </div>
      </Column>
      <Column spans={{ lg: 6 }} justify="center" align="center" className={"px-8"}>
        <Spacer size="2xl" />
        <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">{ title }</Text>
        <Spacer size="md" />
        <Text align="center">{ body }</Text>
        { cta && (
          <>
            <Spacer size="lg" />
            <Button to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId}>{ cta.name }</Button>
          </>
        ) }
        <Spacer size="2xl" />
      </Column>
    </Grid>
  </div>
);

export default Advert;
