import { FC } from "react";

import Image, { IImageProps } from "../../base/Image/Image";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import TextTitle from "../../base/Text/TextTitle/TextTitle";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";

interface IStep {
  image: IImageProps;
  title: string;
  body: string;
}

export type TStepOptionImageSize = "default" | "220px";

interface IStepOptions {
  imageSize?: TStepOptionImageSize;
}

export interface IStepByStepProps {
  hero?: IImageProps;
  title: string;
  steps: IStep[];
  options?: IStepOptions;
}

const STEP_BY_STEP_IMAGE_SIZE_CLASS_MAP: Record<TStepOptionImageSize, string> = {
  default: "",
  "220px": "max-w-[220px]",
};

const StepByStep: FC<IStepByStepProps> = (props) => (
  <Grid component={ StepByStep.name }>
    <>
      { props.hero?.src && (
        <Column className="pb-6 lg:pb-16">
          <Image image={ props.hero } alt={ props.title } data-testid="StepByStepImage" />
        </Column>
      ) }
      <Column>
        <TextTitle size={ 1 } align="center" data-testid="StepByStepTitle">{ props.title }</TextTitle>
        <Spacer size="lg" />
      </Column>
      <Column>
        <Grid tag="ol">
          { props.steps.map((step, index) => (
            <Column key={ step.title } tag="li" spans={ { lg: 4 } } align="center" justify="center">
              <div className={ STEP_BY_STEP_IMAGE_SIZE_CLASS_MAP[props.options?.imageSize || "default"] }>
                <Image image={ step.image } alt={ step.title } data-testid="StepByStepItemImage" />
              </div>
              <Spacer size="md" />
              <div className="flex justify-center items-center">
                <span className="h-6 w-6 inline-flex flex-none justify-center items-center rounded-full bg-black text-white text-xs mr-2 font-display">{ index + 1 }</span>
                <TextSubtitle tag="h3" size={ 2 } align="center" data-testid="StepByStepItemTitle">{ step.title }</TextSubtitle>
              </div>
              <Spacer size="md" />
              <TextBody tag="div" size={ 2 } align="center" data-testid="StepByStepItemBody">{ step.body }</TextBody>
            </Column>
          )) }
        </Grid>
      </Column>
    </>
  </Grid>
);

export default StepByStep;
