import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Spacer from "../../layout/Spacer/Spacer";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import AddProductCardPreviewList, {
  IAddProductCardPreviewListProps,
} from "../../feature/AddProductCardPreviewList/AddProductCardPreviewList";
import { getSectionSize } from "../../layout/Section/Section.utils";

export type TitleDisplay = "default" | "subtitle";

export interface ISectionAddProductCardPreviewListProps extends IAddProductCardPreviewListProps {
  title?: string;
  titleDisplay?: TitleDisplay;
  theme: Theme;
}

const SectionAddProductCardPreviewList: FC<ISectionAddProductCardPreviewListProps> = ({
  products, title, titleDisplay, theme,
}) => (
  <Section component={ SectionAddProductCardPreviewList.name } theme={ theme } size={ getSectionSize(theme) }>
    { title && (
      <Container>
        <Text tag="h2" display={ titleDisplay || "title" } size={ titleDisplay === "subtitle" ? legacySizeCollectionMap.titleMd : legacySizeCollectionMap.titleLg } align="center">
          { title }
        </Text>
        <Spacer size={ titleDisplay === "subtitle" ? "lg" : "2xl" } />
      </Container>
    ) }
    <Container>
      <AddProductCardPreviewList products={ products } />
    </Container>
  </Section>
);

export default SectionAddProductCardPreviewList;
