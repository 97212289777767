import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Accordion, {
  IAccordion,
} from "apps/website/components/feature/Accordion/Accordion";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Button from "apps/website/components/base/Button/Button";

import { ILink } from "../../base/Button/Button.map";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionAccordionSection extends IAccordion {
  theme?: Theme;
  ctas?: ILink[];
  sectionTitle: string;
}

const SectionAccordion: FC<ISectionAccordionSection> = ({ title, sectionTitle, Content, type, theme = "default", ctas, bottomAnimation }) => {

  const AccordionContent = () => Content;
  return (

    <Section component={ SectionAccordion.name } theme={ theme } size={ getSectionSize(theme) }>
      { sectionTitle && (
        <Container>
          <Text tag="h2" display="title" size={ legacySizeCollectionMap.titleLg } align="center">
            { sectionTitle }
          </Text>
          <Spacer size="xl" />
        </Container>
      ) }
      <Container>
        <Accordion
          title={ title }
          Content={ <AccordionContent /> }
          type={ type }
          bottomAnimation={ bottomAnimation }
        />
        { !!ctas?.length && (
          <>
            <Spacer size="xl" />
            <div className="flex justify-center">
              { ctas.map((cta) => (
                <Button key={ cta.id } to={ cta.to } color={ cta.color } design={ cta.design } data-testid={ cta.testId } className="mx-2">
                  { cta.name }
                </Button>
              )) }
            </div>
          </>
        ) }
      </Container>
    </Section>
  );
};

export default SectionAccordion;
