import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  IRichTextOptions,
  renderStoryBlokRichText,
} from "apps/website/utils/storyblok/text";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

import SectionMicroGallery from "../../section/SectionMicroGallery/SectionMicroGallery";
import { IImageProps } from "../../base/Image/Image";

export interface IStoryBlokMicroGallery {
  pre_text: string;
  title: string;
  body: string;
  theme: Theme
  images: [IStoryBlokImage, IStoryBlokImage, IStoryBlokImage, IStoryBlokImage];
  richtextOptions?: IRichTextOptions;
}

export const StoryBlokMicroGallery = ({ blok }: IStoryBlokComponent<IStoryBlokMicroGallery>) => (
  <SectionMicroGallery
    preText={blok.pre_text}
    images={
      blok.images.map((image) => getStoryBlokImage(image, true, ImageMax.HALF_CONTAINER_SM)) as [
        IImageProps, IImageProps, IImageProps, IImageProps,
      ]
    }
    title={blok.title}
    body={renderStoryBlokRichText(blok.body, blok.richtextOptions)}
    theme={blok.theme}
  />
);
