import { create } from "zustand";
import { persist } from "zustand/middleware";

interface FlowCustomer {
  flowId: string;
  customerId: string;
}
interface ICustomerStore {
  customerIds: FlowCustomer[];
  setCustomerId(flowId: string, customerId: string | undefined): void;
}

export const useCustomerStore = create<ICustomerStore>()(persist((set) => ({
  setCustomerId: (flowId: string, newCustomerId: string) => set((state) => {
    if (state.customerIds.find((ci) => ci.flowId === flowId)) {
      return ({
        ...state,
        customerIds: state.customerIds
          .map((ci) => (ci.flowId === flowId ? ({ ...ci, customerId: newCustomerId }) : ci)),
      });
    }
    state.customerIds.push({ flowId, customerId: newCustomerId });
    return {
      ...state,
      customerIds: state.customerIds };
  }),
  customerIds: [],
}), { name: "customer-id" }));

export default useCustomerStore;
