import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Button from "apps/website/components/base/Button/Button";
import { ILink } from "apps/website/components/base/Button/Button.map";
import AccordionList, {
  IAccordionList,
} from "apps/website/components/feature/Accordion/AccordionList/AccordionList";

export interface ISectionAccordionListSection extends IAccordionList {
  title?: string;
  theme?: Theme;
  ctas?: ILink[];
}

const SectionAccordionList: FC<ISectionAccordionListSection> = ({ title, items, hasBorderTop, theme = "default", ctas }) => (
  <Section component={SectionAccordionList.name} theme={theme} size={theme === "default" || theme === "light-grey" ? "none" : "xl"}>
    { title && (
      <Container>
        <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">
          { title }
        </Text>
        <Spacer size="xl" />
      </Container>
    ) }
    <Container>
      <AccordionList
        hasBorderTop={hasBorderTop}
        items={items}
      />
      { !!ctas?.length && (
        <>
          <Spacer size="xl" />
          <div className="flex justify-center">
            { ctas.map((cta) => (
              <Button key={cta.id} to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} className="mx-2">
                { cta.name }
              </Button>
            )) }
          </div>
        </>
      ) }
    </Container>
  </Section>
);

export default SectionAccordionList;
