import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import BrandComparisonTable from
  "apps/website/components/feature/Table/BrandComparisonTable/BrandComparisonTable";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import Image, { IImageProps } from "../../base/Image/Image";
import TextBody from "../../base/Text/TextBody/TextBody";

export interface ISectionBrandComparisonTable {
  title?: string;
  subtitle?: string;
  body?: string;
  bodyRichtext?: string;
  bodyImage?: IImageProps;
  theme?: Theme;
  source?: string;
  highlightKatKin?: boolean;
}

const SectionBrandComparisonTable: FC<ISectionBrandComparisonTable> = (
  { title, subtitle, body, theme, source, highlightKatKin, bodyRichtext, bodyImage },
) => (
  <Section component="SectionBrandComparisonTable" theme={ theme }>
    <Container>
      { title && (
        <>
          <Text tag="h2" display="title" size={ legacySizeCollectionMap.titleLg } align="center">
            { title }
          </Text>
        </>
      ) }
      <Grid>
        <Column>
          { subtitle && (
            <>
              <Spacer size="xl" />
              <Text display="subtitle" size={ legacySizeCollectionMap.titleMd } align="center" whitespace={ { lg: "preWrap" } }>{ subtitle }</Text>
            </>
          ) }
          { bodyRichtext ? (
            <>
              <Spacer size="md" />
              <Grid gap="lg">
                <Column direction="row" align="center" className="space-x-4">
                  { bodyImage && (
                    <div className="w-[95px] h-[95px] w-[120px] h-[120px] rounded-full overflow-hidden relative flex-none">
                      <Image image={ bodyImage } alt={ bodyRichtext } cover objectPosition="center" />
                    </div>
                  ) }
                  <TextBody tag="div">{ bodyRichtext }</TextBody>
                </Column>
              </Grid>
              <Spacer size="lg" />
            </>
          ) : (
            <>
              { body && (
                <>
                  <Spacer size="md" />
                  <Text size={ legacySizeCollectionMap.bodyLg } align="center" whitespace={ { lg: "preWrap" } }>{ body }</Text>
                </>
              ) }
              <Spacer size="2xl" />
            </>
          ) }

        </Column>
      </Grid>
      <BrandComparisonTable highlightKatKin={ highlightKatKin } />
      { source && (
        <>
          <Spacer size="md" />
          <Grid>
            <Column>
              <Text>
                <Text tag="span" display="subtitle">Source:</Text>
                &nbsp;{ source }
              </Text>
            </Column>
          </Grid>
        </>
      ) }
    </Container>
  </Section >
);

export default SectionBrandComparisonTable;
