import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import { ITitle } from "apps/website/components/page/component.types";
import Splash, {
  TSectionSplashStyle,
} from "apps/website/components/section/SectionSplash/SectionSplash";
import {
  IAdvancedImages,
  IImageProps,
} from "apps/website/components/base/Image/Image";
import {
  ISplashSubtitle,
  Size,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";

export interface ISectionImageSplash {
  title: ITitle;
  subtitle?: ISplashSubtitle;
  body?: string;
  ctas?: ILink[];
  theme?: Theme;
  image: IImageProps;
  advancedImages?: IAdvancedImages;
  disableImageLazyLoading?: boolean;
  containImage?: boolean;
  size?: Size;
  hasBorders?: boolean;
  style?: TSectionSplashStyle;
}

const SectionImageSplash: FC<ISectionImageSplash> = ({ title, subtitle, body, ctas, image, advancedImages, disableImageLazyLoading = false, theme = "brand-alt", containImage = true, size = "auto", hasBorders = false, style = "default" }) => (
  <Splash
    component="SectionImageSplash"
    title={title}
    subtitle={subtitle}
    body={body}
    theme={theme}
    containImage={containImage}
    image={image}
    advancedImages={advancedImages}
    disableImageLazyLoading={disableImageLazyLoading}
    ctas={ctas}
    size={size}
    hasBorders={hasBorders}
    style={style}
  />
);

export default SectionImageSplash;
