import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";
import Container from "../../layout/Container/Container";
import KatkinVersesCompetitor, {
  IKatkinVersesCompetitorProps,
} from "../../feature/KatkinVersesCompetitor/KatkinVersesCompetitor";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";

export interface ISectionKatkinVersesCompetitorProps extends IKatkinVersesCompetitorProps {
  title: string;
  body: string;
  theme: Theme;
}

const SectionKatkinVersesCompetitor: FC<ISectionKatkinVersesCompetitorProps> = ({ title, body, items, theme = "default" }) => (
  <Section component={ SectionKatkinVersesCompetitor.name } theme={ theme } size={ getSectionSize(theme) }>
    <Container>
      <TextSubtitle size={ 1 } align="center" data-testid="SectionKatkinVersesCompetitorTitle">{ title }</TextSubtitle>
      <Spacer size="lg" />
      <TextBody tag="div" size={ 1 } align="center" data-testid="SectionKatkinVersesCompetitorBody">{ body }</TextBody>
      <Spacer size="lg" />
    </Container>
    <Container size="sm">
      <KatkinVersesCompetitor items={ items } />
    </Container>
  </Section>
);

export default SectionKatkinVersesCompetitor;
