import { useMemo } from "react";

type Chunk = <T>(array: T[], chunkSize: number) => T[][];

const useChunks: Chunk = (arr, size) => {
  const chunk: Chunk = (array, chunkSize) => {
    const chunks = [];
    for (let index = 0; index < array.length; index += chunkSize) chunks.push(array.slice(index, index + chunkSize));
    return chunks;
  };

  return useMemo(() => chunk(arr, size), [ arr, size ]);
};

export default useChunks;
