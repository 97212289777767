import {
  BreakpointOffset,
  BreakpointSpan,
} from "../../layout/Column/Column.map";

interface IIconCalloutWidth {
  spans: BreakpointSpan;
  offset: BreakpointOffset;
}

export const iconCalloutWidthMap: Record<string, IIconCalloutWidth> = {
  default: {
    spans: { lg: 6 },
    offset: { lg: 4 },
  },
  full: {
    spans: {},
    offset: {},
  },
} as const;

export type TIconCalloutWidth = keyof typeof iconCalloutWidthMap;
