"use client";

import { FC } from "react";
import { useSearchParams } from "next/navigation";

import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { useNewFormsServiceStore } from "@./state";

import { IStoryBlokStickyCTA, StoryBlokStickyCTA } from "../StoryBlokStickyCTA";

interface IStoryBlokFormCancellationFlowRouter {
  flowId: string;
  disabled?: boolean;
}

export const StoryBlokFormCancellationFlowRouter: FC<IStoryBlokFormCancellationFlowRouter> = ({ flowId }) => {

  const { getActiveFieldValue } = useNewFormsServiceStore();
  const searchParams = useSearchParams();

  const getFlowContinuePath = (
    primaryReason: string | undefined = undefined,
    secondaryReason: string | undefined = undefined,
    tertiaryReason: string | undefined = undefined,
  ): string => {
    if (primaryReason === "PASSED_AWAY") return "/forms/cancellation-passed-away";

    if (primaryReason === "NOT_EATING") {
      if (secondaryReason === "NOT_ENTHUSIASTIC_ENOUGH") return "/forms/cancellation-cat-not-eating-not-enthusiastic";
      if (secondaryReason === "LEFTOVER_FOOD") return "/forms/cancellation-cat-not-eating-leftovers";
      if (secondaryReason === "WONT_EAT_AT_ALL") return "/forms/cancellation-cat-not-eating-not-at-all";
      if (secondaryReason === "WENT_OFF_FOOD") return "/forms/cancellation-cat-not-eating-gone-off-food";
      if (secondaryReason === "NEW_FOOD_24") return "/forms/cancellation-cat-not-eating-gone-off-food";
      if (secondaryReason === "LIKED_SOME_RECIPES") return "/forms/cancellation-cat-not-eating-eats-some-recipes";
    }

    if (primaryReason === "HEALTH_REASONS") {
      if (secondaryReason === "VOMITING_OR_LOOSE_STOOLS") return "/forms/cancellation-health-changed-upset-stomach";
      if (secondaryReason === "REQUIRE_DIFFERENT_KIND_OF_FOOD") return "/forms/cancellation-health-changed-required-different-food";
    }

    if (primaryReason === "NEW_PACKAGING") return "/forms/cancellation-packaging";

    if (primaryReason === "LIMITED_FREEZER_SPACE") {
      if (secondaryReason === "ACCUMULATION_OVER_TIME") return "/forms/cancellation-freezer-space-accumulation";
      if (secondaryReason === "NO_MORE_ROOM") return "/forms/cancellation-freezer-space-lack-of";
    }

    if (primaryReason === "PRODUCT_CHANGE_24") return "cancellation-unhappy-with-changes-in-the-product";

    if (primaryReason === "UNHAPPY_WITH_SERVICE") {
      if (secondaryReason === "UNHAPPY_SERVICE") return "/forms/cancellation-unhappy-with-service";
      if (secondaryReason === "UNHAPPY_DPD") return "/forms/cancellation-unhappy-with-service";
      if (secondaryReason === "UNHAPPY_QUALITY") return "/forms/cancellation-unhappy-with-service";
      if (secondaryReason === "UNHAPPY_OTHER") {
        if (tertiaryReason?.length) return "/forms/cancellation-unhappy-with-service";
      }
    }

    if (primaryReason === "TOO_EXPENSIVE") {
      if (secondaryReason === "OVER_MY_BUDGET") return "/forms/cancellation-too-expensive-over-my-budget";
      if (secondaryReason === "OTHER_BRANDS_CHEAPER") return "/forms/cancellation-too-expensive-default";
      if (secondaryReason === "EXPENSIVE_WORTH") return "/forms/cancellation-too-expensive-default";
      if (secondaryReason === "DELIVERYCOST") return "/forms/cancellation-too-expensive-delivery-charge";
    }

    if (primaryReason === "COMING_BACK") {
      if (secondaryReason === "GOING_ON_HOLIDAY") return "/forms/cancellation-coming-back-holiday";
      if (secondaryReason === "HAVE_ENOUGH_FOR_NOW") return "/forms/cancellation-coming-back-freezer-stocked";
      if (secondaryReason === "COMINGBACK_MULTIFEEDING") return "/forms/cancellation-coming-back-multi-feeding";
    }

    if (primaryReason === "SUB_DOESNT_WORK") {
      if (secondaryReason === "WANT_TO_TRY_FIRST_BOX") return "/forms/cancellation-sub-doesn-t-work-try-before-commiting";
      if (secondaryReason === "PREFER_TO_BUY_WHEN_I_NEED_IT") return "/forms/cancellation-sub-doesn-t-work-buy-when-needed";
      if (secondaryReason === "NEED_LESS_FOOD_THAN_SUBSCRIPTION") return "/forms/cancellation-sub-doesn-t-work-need-less-food";
    }

    return "";
  };

  const params = (): string => {
    const cancellationReasons = {
      cancellationPrimary: getActiveFieldValue(flowId, "cancellationPrimary")?.data.submitValue,
      cancellationSecondary: getActiveFieldValue(flowId, "cancellationSecondary")?.data.submitValue,
      cancellationTertiary: getActiveFieldValue(flowId, "cancellationTertiary")?.data.submitValue,
      catName: getActiveFieldValue(flowId, "catName")?.data.submitValue,
      catSex: getActiveFieldValue(flowId, "catSex")?.data.submitValue,
      catId: searchParams.get("catId"),
    };

    const queryString: string[] = [];

    Object.entries({
      ...cancellationReasons,
      returnToPath: `/forms/cancellation?catId=${searchParams.get("catId")}`,
    }).forEach(([ key, value ]) => {
      if (value) {
        queryString.push(`${key}=${value}`);
      }
    });

    return `?${queryString.join("&")}`;

  };

  const link = {
    url: `${getFlowContinuePath(
      getActiveFieldValue(flowId, "cancellationPrimary")?.data.submitValue as string || "",
      getActiveFieldValue(flowId, "cancellationSecondary")?.data.submitValue as string || "",
      getActiveFieldValue(flowId, "cancellationTertiary")?.data.submitValue as string || "",
    )}${params()}`,
  };

  const formComponent: IStoryBlokComponent<IStoryBlokStickyCTA> = {
    blok: {
      text: "string",
      cta: [ {
        _uid: "cancellationFlowRouter",
        name: "Continue",
        link,
        color: "default",
        design: "default",
        keep_query_params: true,
      } ],
      show_trustpilot_rating: false,
      disabled: !link.url.length,
    },
  };

  return (
    <>
      <StoryBlokStickyCTA {...formComponent} />
    </>
  );
};
