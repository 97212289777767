import { Justify } from "apps/website/maps/Flex.map";
import { Theme } from "apps/website/maps/Theme.map";
import {
  getStoryBlokAdvancedImages,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import { getStoryBlokVideo } from "apps/website/utils/storyblok/video";
import {
  IStoryBlokAdvancedImages,
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
  IStoryBlokVideo,
} from "apps/website/components/page/api.types";
import { ITitle } from "apps/website/components/page/component.types";
import ColorSplash from
  "apps/website/components/section/SectionSplash/SectionColorSplash/SectionColorSplash";
import HeroSplash from
  "apps/website/components/section/SectionSplash/SectionHeroSplash/SectionHeroSplash";
import ImageSplash from
  "apps/website/components/section/SectionSplash/SectionImageSplash/SectionImageSplash";
import TextSplash from
  "apps/website/components/section/SectionSplash/SectionTextSplash/SectionTextSplash";
import VideoSplash from
  "apps/website/components/section/SectionSplash/SectionVideoSplash/SectionVideoSplash";
import PreCancellationSplash, {
  TPrecancellationSubscriptionState,
} from "apps/website/components/section/SectionSplash/SectionPreCancellationSplash/SectionPreCancellationSplash";
import {
  Size,
  SplashLayout,
} from "apps/website/components/section/SectionSplash/SectionSplash.map";
import { ILink } from "apps/website/components/base/Button/Button.map";

import { TSectionSplashStyle } from "../../section/SectionSplash/SectionSplash";

interface IStoryBlokSplash {
  title: ITitle;
  subtitle?: string;
  body?: string;
  theme?: Theme;
  ctas?: IStoryBlokCta[];
  size?: Size;
  has_borders?: boolean;
  show_logo?: boolean;
  justify?: Justify;
  layout?: SplashLayout;
  logo_background?: Theme;
}

interface IStoryBlokImageSplash extends IStoryBlokSplash {
  image:IStoryBlokImage;
  advanced_images: IStoryBlokAdvancedImages;
  style?: TSectionSplashStyle;
}

interface IStoryBlokVideoSplash extends IStoryBlokImageSplash {
  video: IStoryBlokVideo;
}

type IStoryBlokPreCancellationSplash = {
  subscription_state: TPrecancellationSubscriptionState
} & Omit<IStoryBlokImageSplash, "title">;

export const StoryBlokColorSplash = ({ blok }: IStoryBlokComponent<IStoryBlokSplash>) => (
  <ColorSplash
    title={blok.title}
    subtitle={{ text: blok.subtitle ?? "" }}
    body={blok.body}
    theme={blok.theme}
    ctas={blok.ctas?.map((cta) => ({ ...getStoryBlokCta(cta) })).filter((o) => !!o).map((a) => a as ILink)}
    size={blok.size}
    hasBorders={blok.has_borders}
  />
);

export const StoryBlokTextSplash = ({ blok }: IStoryBlokComponent<IStoryBlokSplash>) => (
  <TextSplash
    title={blok.title}
    subtitle={{ text: blok.subtitle ?? "" }}
    body={blok.body}
    theme={blok.theme}
    ctas={blok.ctas?.map((cta) => ({ ...getStoryBlokCta(cta) })).filter((o) => !!o).map((a) => a as ILink)}
  />
);

export const StoryBlokImageSplash = ({ blok }: IStoryBlokComponent<IStoryBlokImageSplash>) => (
  <ImageSplash
    title={blok.title}
    subtitle={{ text: blok.subtitle ?? "" }}
    body={blok.body}
    theme={blok.theme}
    ctas={blok.ctas?.map((cta) => ({ ...getStoryBlokCta(cta) })).filter((o) => !!o).map((a) => a as ILink)}
    size={blok.size}
    image={getStoryBlokImage(blok.image)}
    advancedImages={getStoryBlokAdvancedImages(blok.advanced_images)}
    disableImageLazyLoading={blok.advanced_images.disableLazyLoading}
    hasBorders={blok.has_borders}
    style={blok.style}
  />
);

export const StoryBlokHeroSplash = ({ blok }: IStoryBlokComponent<IStoryBlokImageSplash>) => (
  <HeroSplash
    title={blok.title}
    subtitle={{ text: blok.subtitle ?? "" }}
    theme={blok.theme}
    ctas={blok.ctas?.map((cta) => ({ ...getStoryBlokCta(cta) })).filter((o) => !!o).map((a) => a as ILink)}
    image={getStoryBlokImage(blok.image)}
    advancedImages={getStoryBlokAdvancedImages(blok.advanced_images)}
    disableImageLazyLoading={blok.advanced_images?.disableLazyLoading}
    justify={blok.justify}
    withLogo={blok.show_logo}
    layout={blok.layout}
    logoBackground={blok.logo_background}
  />
);

export const StoryBlokVideoSplash = ({ blok }: IStoryBlokComponent<IStoryBlokVideoSplash>) => (
  <VideoSplash
    title={blok.title}
    subtitle={{ text: blok.subtitle ?? "" }}
    theme={blok.theme}
    ctas={blok.ctas?.map((cta) => ({ ...getStoryBlokCta(cta) })).filter((o) => !!o).map((a) => a as ILink)}
    video={getStoryBlokVideo(blok.video)}
    videoPoster={getStoryBlokImage(blok.image).src}
  />
);

export const StoryBlokPreCancellationSplash = ({ blok }: IStoryBlokComponent<IStoryBlokPreCancellationSplash>) => (
  <PreCancellationSplash
    theme={blok.theme}
    image={getStoryBlokImage(blok.image)}
    subscriptionState={blok.subscription_state}
  />
);
