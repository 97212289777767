import { create } from "zustand";

import { IMenuDetails, IPopoverMenuStore } from "./usePopoverMenuStore.types";
import { INITIAL_STATE } from "./usePopoverMenuStore.constants";

export const usePopoverMenuStore = create<IPopoverMenuStore>()((set, get) => ({
  state: INITIAL_STATE.state,
  setState: (state) => set(() => ({ state })),
  title: INITIAL_STATE.title,
  setTitle: (title) => set(() => ({ title })),
  items: INITIAL_STATE.items,
  setItems: (items) => set(() => ({ items })),
  setMenuDetails: (menuDetails: IMenuDetails) => set(() => ({ ...menuDetails })),
  getMenuDetails: () => ({
    state: get().state,
    title: get().title,
    items: get().items,
  }),
  resetMenuDetails: (delay = 0) => {
    get().setState(INITIAL_STATE.state);
    setTimeout(() => {
      get().setTitle(INITIAL_STATE.title);
      get().setItems(INITIAL_STATE.items);
    }, delay);
  },
}));

export default usePopoverMenuStore;
