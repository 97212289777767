import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import SectionNewsletterSignUp from
  "apps/website/components/section/SectionNewsletterSignUp/SectionNewsletterSignUp";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

export interface IStoryBlokNewsletterSignUp {
  title: string;
  complete_title: string;
  body: string;
  complete_body: string;
  complete_icon: IStoryBlokImage;
  button_text: string;
  theme: Theme
}

export const StoryBlokNewsletterSignUp = ({ blok }: IStoryBlokComponent<IStoryBlokNewsletterSignUp>) => (
  <SectionNewsletterSignUp
    title={blok.title}
    completeTitle={blok.complete_title}
    body={blok.body}
    completeBody={blok.complete_body}
    completeIcon={getStoryBlokImage(blok.complete_icon, true, ImageMax.HALF_CONTAINER_SM)}
    buttonText={blok.button_text}
    theme={blok.theme}
  />
);
