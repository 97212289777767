import { Theme } from "apps/website/maps/Theme.map";
import { IStoryBlokComponent } from "apps/website/components/page/api.types";

import SectionTimeToComplete from "../../section/SectionTimeToComplete/SectionTimeToComplete";

export interface IStoryBlokTimeToComplete {
  hours?: number;
  minutes?: number;
  is_approx?: boolean;
  theme?: Theme;
}

export interface IStoryBlokSectionTimeToComplete {
  theme?: Theme;
  time_to_complete: [IStoryBlokTimeToComplete]
}

export const StoryBlokTimeToComplete = ({ blok }: IStoryBlokComponent<IStoryBlokSectionTimeToComplete>) => (
  <>
    <SectionTimeToComplete
      theme={ blok.theme || "default" }
      timeToComplete={{
        hours: blok.time_to_complete[0].hours || 0,
        minutes: blok.time_to_complete[0].minutes || 0,
        isApprox: blok.time_to_complete[0].is_approx ?? true,
        theme: blok.time_to_complete[0].theme || "light",
      }}
    />
  </>
);
