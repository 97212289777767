import { FC, ReactNode } from "react";

import { FieldsetComponentWidth } from "@forms/schema";
import {
  Theme,
  ThemeWithRepeatable,
  themeRootClassMap,
} from "apps/website/maps/Theme.map";

import { FieldsetDefault } from "./fieldsets/FieldsetDefault";
import { FieldsetContainer } from "./fieldsets/FieldsetContainer";
import { FieldsetBackground } from "./fieldsets/FieldsetBackground";
import { FieldsetBackgroundScreen } from "./fieldsets/FieldsetBackgroundScreen";

const repeatableThemes: Theme[] = [ "green", "orange", "pink", "blue", "brand" ];

interface IFieldsetDefault {
  id?: string;
  width?: FieldsetComponentWidth;
  index?: number;
  theme?: ThemeWithRepeatable;
  className?: string;
  children: ReactNode;
}
const LAYOUT_MAP = {
  default: FieldsetDefault,
  container: FieldsetContainer,
  backgroundContainer: FieldsetBackground,
  backgroundScreen: FieldsetBackgroundScreen,
};

export const Fieldset: FC<IFieldsetDefault> = ({ id, width = "default", theme = "default", index = 0, className, children }) => {

  const Component = LAYOUT_MAP[width];

  return (
    <Component id={id} themeClass={themeRootClassMap[(theme === "repeatable" ? repeatableThemes[index] : theme) as Theme]} className={className}>
      { children }
    </Component>
  );
};
