import { FC } from "react";

import Tag, { ITag } from "apps/website/components/base/Tag/Tag";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Text from "apps/website/components/base/Text/Text";
import {
  Align,
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface ITagListItemProps {
  tag: ITag;
  text: string;
  display?: Display;
  align?: Align;
}

const TagListItem: FC<ITagListItemProps> = ({ tag, text, display = "subtitle", align = "center" }) => (
  <ListItem component={TagListItem.name} align={display === "default" ? "start" : "center"}>
    <Tag {...tag} size="medium-secondary" align={align} className={`-rotate-1 flex-none w-[80px] lg:w-[120px] ${tag.align === "center" ? "justify-center mr-2 text-center items-center" : ""}`} data-testid="TagListItemTag" />
    <Text data-testid="TagListItemText" display={display} size={legacySizeCollectionMap.titleSm}>{ text }</Text>
  </ListItem>
);

export default TagListItem;
