import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

import SectionQuoteCallout, {
  TQuoteCalloutStyle,
} from "../../section/SectionQuoteCallout/SectionQuoteCallout";

export interface IStoryBlokQuoteCalloutQuote {
  quote: string;
  author: string;
  image: IStoryBlokImage;
  theme: Theme
  image_theme: Theme;
  style: TQuoteCalloutStyle;
}

export interface IStoryBlokQuoteCallout {
  quote: [IStoryBlokQuoteCalloutQuote];
  theme: Theme
}

export const StoryBlokQuoteCallout = ({ blok }: IStoryBlokComponent<IStoryBlokQuoteCallout>) => (
  <SectionQuoteCallout
    quote={{
      quote: blok.quote[0].quote,
      author: blok.quote[0].author,
      image: getStoryBlokImage(blok.quote[0].image, true, ImageMax.HALF_CONTAINER_SM),
      theme: blok.quote[0].theme,
      imageTheme: blok.quote[0].image_theme,
    }}
    style={blok.quote[0].style}
    theme={blok.theme}
  />
);
