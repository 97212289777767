import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import TeamMemberCarousel from
  "apps/website/components/feature/Carousel/TeamMemberCarousel/TeamMemberCarousel";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { Theme } from "apps/website/maps/Theme.map";
import {
  ITeamMember,
} from "apps/website/components/feature/TeamMember/TeamMember";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface ISectionTeamMemberCarousel {
  title?: string;
  subtitle?: string;
  body?: string;
  theme?: Theme;
  items: ITeamMember[]
}

const SectionTeamMemberCarousel: FC<ISectionTeamMemberCarousel> = ({ title, subtitle, body, theme = "blue", items }) => (
  <Section component="SectionTeamMemberCarousel" theme={theme}>
    <Container>
      <>
        { title && (
          <>
            <Text tag="h2" display="title" size={legacySizeCollectionMap.titleLg} align="center">{ title }</Text>
            <Spacer size="xl" />
          </>
        ) }
        { subtitle && (
          <>
            <Grid>
              <Column>
                <Text tag="h3" display="subtitle" size={legacySizeCollectionMap.bodyLg} align="center" whitespace={{ lg: "preWrap" }}>{ subtitle }</Text>
                <Spacer size="lg" />
              </Column>
            </Grid>
          </>
        ) }
        { body && (
          <Grid>
            <Column>
              <Text align="center" whitespace={{ lg: "preWrap" }} size={legacySizeCollectionMap.bodyLg}>{ body }</Text>
              <Spacer size="xl" />
            </Column>
          </Grid>
        ) }
        <TeamMemberCarousel items={items} />
      </>
    </Container>
  </Section>
);

export default SectionTeamMemberCarousel;
