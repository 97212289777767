import { FC, memo } from "react";
import clsx from "clsx";

import {
  AvailableTag as TitleTag,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { Theme } from "apps/website/maps/Theme.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Icon from "apps/website/components/base/Icon/Icon";
import { BreakpointDirection, Direction } from "apps/website/maps/Flex.map";

import {
  titleClasses,
  bodyWrapperClasses,
  getHiddenClasses,
  getPartClassesFromDirection,
  iconClasses,
  iconMap,
  imageWrapperClasses,
  reversedImageWrapperClasses,
  spacerClasses,
  reversedTextClasses,
} from "../InstructionCardList.map";

export interface IInstructionCardItem {
  titleTag?: TitleTag;
  image: IImageProps;
  title: string;
  body?: string;
  index?: number;
  showArrow?: boolean;
  theme?: Theme;
  direction?: Direction | BreakpointDirection;
  reverse?: boolean;
}
const InstructionCardItem: FC<IInstructionCardItem> = ({
  showArrow = false,
  image,
  title,
  titleTag = "h3",
  body,
  index,
  direction = "column",
  theme,
  reverse = false,
}) => (
  <ListItem
    direction={direction}
    component={InstructionCardItem.name}
    key={title}
    className="flex relative flex-1"
    theme={theme}
  >
    <div className={`${getPartClassesFromDirection(imageWrapperClasses, direction)} ${reverse && getPartClassesFromDirection(reversedImageWrapperClasses, direction)}`}>
      { image?.src && (
        <>
          <Image image={image} alt={title} className={getHiddenClasses(direction).row} />
          <Image image={image} alt={title} cover={true} className={getHiddenClasses(direction).column}/>
        </>
      ) }
    </div>
    <Spacer size="md" className={getPartClassesFromDirection(spacerClasses, direction)}/>
    <div className={clsx(
      theme !== "default" ? "p-4" : "",
      getPartClassesFromDirection(bodyWrapperClasses, direction),
    )}>
      <Text
        tag={titleTag}
        display={"title"}
        size={legacySizeCollectionMap.titleSm}
        className={`flex-1 px-4 w-full ${getPartClassesFromDirection(titleClasses, direction)} ${reverse && getPartClassesFromDirection(reversedTextClasses, direction)}`}
        align={direction === "row" ? "center" : "default"}
        whitespace={{ lg: "preWrap" }}
      >
        { index ? `${index}. ${title}` : title }
      </Text>

      { body && (
        <>
          <Spacer size="md" />
          <Text
            tag="div"
            size={legacySizeCollectionMap.base}
            whitespace={{ lg: "preWrap" }}
            className={`flex-auto pt-0 px-4 ${reverse && getPartClassesFromDirection(reversedTextClasses, direction)}`}
          >
            { body }
          </Text>
        </>
      ) }
    </div>
    <Spacer size="md" className={getPartClassesFromDirection(spacerClasses, direction)}/>

    { showArrow && (
      <>
        <Icon
          icon={iconMap.row}
          size="2xsmall"
          className={clsx(
            "absolute transform",
            getHiddenClasses(direction).column,
            getPartClassesFromDirection(iconClasses, direction),
          )}
        />
        <Icon
          icon={iconMap.column}
          size="small"
          className={clsx(
            "absolute transform",
            getHiddenClasses(direction).row,
            getPartClassesFromDirection(iconClasses, direction),
          )}
        />
      </>
    ) }
  </ListItem>
);

export default memo(InstructionCardItem);
