import { FC } from "react";

import Tag, { ITag } from "../../base/Tag/Tag";
import { Span } from "../../layout/Column/Column.map";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";

export interface IClusterTag extends ITag {
  spans?: Span;
}

export interface ITagClusterProps {
  tags: IClusterTag[];
}

const TagCluster: FC<ITagClusterProps> = ({ tags }) => (
  <Grid component={TagCluster.name}>
    { tags.map((tag, index) => (
      <Column key={`tag-${index}}`} spans={tag.spans}>
        <Tag
          {...tag}
          size="cluster-default"
          display="subtitle"
          className={`w-full justify-center items-center ${(!!tag.spans && tag.spans !== 12) ? index % 2 === 0 ? "-rotate-1" : "rotate-1" : ""}`}
        />
      </Column>
    )) }
  </Grid>
);

export default TagCluster;
