import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import SectionBrandComparisonTable from
  "apps/website/components/section/SectionBrandComparisonTable/SectionBrandComparisonTable";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";

export interface IStoryBlokBrandComparisonTable {
  title: string;
  subtitle: string;
  body: string;
  theme: Theme
  source?: string;
  highlight_katkin?: boolean;
  body_image: IStoryBlokImage;
  body_richtext: string;
}

export const StoryBlokBrandComparisonTable = ({ blok }: IStoryBlokComponent<IStoryBlokBrandComparisonTable>) => (
  <SectionBrandComparisonTable
    title={ blok.title }
    subtitle={ blok.subtitle }
    body={ blok.body }
    theme={ blok.theme }
    source={ blok.source ? renderStoryBlokRichText(blok.source, { unwrapResolvers: true, links: { display: "default" } }) : undefined }
    highlightKatKin={ blok.highlight_katkin }
    bodyRichtext={ renderStoryBlokRichText(blok.body_richtext) }
    bodyImage={ getStoryBlokImage(blok.body_image, true, 120) }
  />
);
