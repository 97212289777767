import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";
import StandAloneReview, {
  IStandAloneReviewProps,
} from "../../feature/StandAloneReview/StandAloneReview";
import Container from "../../layout/Container/Container";

export interface ISectionStandAloneReviewProps {
  theme: Theme;
  review: IStandAloneReviewProps;
}

const SectionStandAloneReview: FC<ISectionStandAloneReviewProps> = (props) => (
  <Section component={ SectionStandAloneReview.name } theme={ props.theme } size={ getSectionSize(props.theme) }>
    <Container className="py-4">
      <StandAloneReview { ...props.review } />
    </Container>
  </Section>
);

export default SectionStandAloneReview;
