import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  StoryBlokCarouselArticleCards,
} from "apps/website/components/storyblok/sections/StoryBlokCarousel";

import {
  IStoryBlokArticleCardsCarousel,
} from "../../../../types/article/IStoryBlokArticleCardsCarousel";

export const StoryBlokArticlesCarouselRelatedArticles = ({
  blok,
}: IStoryBlokComponent<IStoryBlokArticleCardsCarousel>) => (
  <StoryBlokCarouselArticleCards blok={blok} />
);
