import { FC, memo } from "react";
import clsx from "clsx";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Image, {
  IAdvancedImages,
  IImageProps,
} from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Section from "apps/website/components/layout/Section/Section";
import Button from "apps/website/components/base/Button/Button";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import { borderlessSectionWidths } from "../SectionIconTextList/Section.map";

export type Direction = "default" | "reverse";
export type TSplitSplashSize = "default" | "fullWidth";

export interface ISectionSplitSplash {
  title: string;
  image: IImageProps;
  advancedImages?: IAdvancedImages;
  cta?: ILink;
  direction?: Direction;
  theme?: Theme;
  size?: TSplitSplashSize;
}

const SectionSplitSplash: FC<ISectionSplitSplash> = (
  { title, image, advancedImages, cta, direction = "default", theme = "light-grey", size = "default" },
) => {

  const order = direction === "default" ? { image: "order-first", text: "order-last" } : { image: "order-last", text: "order-first" };
  return (
    <Section component="SectionSplitSplash" size="none">
      <div
        data-theme={ theme }
        className={ clsx(
          "mx-auto w-full",
          `${size === "default" && `${borderlessSectionWidths.md} ${borderlessSectionWidths.lg} ${borderlessSectionWidths.xl}`} ${themeRootClassMap[theme]}`,
        ) }
      >
        <Grid gap="none">
          <Column spans={ { lg: 6 } } className={ `relative ${order.image}` }>
            <div className="hidden lg:block">
              <Image image={ image } alt={ image.alt || title } advancedImages={ advancedImages } cover />
            </div>
            <div className="block lg:hidden">
              <Image image={ image } alt={ image.alt || title } advancedImages={ advancedImages } />
            </div>
          </Column>
          <Column spans={ { lg: 6 } } justify="center" align="center" className={ `py-4 px-4 lg:px-10 xl:px-12 ${order.text}` }>
            <Spacer size="3xl" />
            <Text tag="h2" display="title" size={ legacySizeCollectionMap.titleXl } align="center" whitespace={ { lg: "preWrap" } }>{ title }</Text>
            { cta && (
              <>
                <Spacer size="2xl" />
                <Button
                  to={ cta.to }
                  color={ cta.color }
                  design={ cta.design }
                  data-testid={ cta.testId }
                >{ cta.name }</Button>
              </>
            ) }
            <Spacer size="3xl" />
          </Column>
        </Grid>
      </div>
    </Section>
  );
};

export default memo(SectionSplitSplash);
