import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

import SectionCondensedReviewList, {
  TTitleDisplay,
} from "../../section/SectionCondensedReviewList/SectionCondensedReviewList";

import { IStoryBlokIconTextListItems } from "./StoryBlokIconTextList";

interface IStoryBlokCondensedReview {
  avatar: IStoryBlokImage,
  body: string;
  theme?: Theme;
  author: string;
  hide_trustpilot_stars?: boolean;
}

interface IStoryBlokCondensedReviews {
  items: IStoryBlokCondensedReview[];
}

interface IStoryBlokSectionCondensedReviewList {
  title: string;
  title_display?: TTitleDisplay;
  icon_text_list: [IStoryBlokIconTextListItems];
  reviews: [IStoryBlokCondensedReviews];
  theme?: Theme;
}

export const StoryBlokCondensedReviewList = ({ blok }: IStoryBlokComponent<IStoryBlokSectionCondensedReviewList>) => (
  <SectionCondensedReviewList
    title={ blok.title }
    titleDisplay={ blok.title_display || "default" }
    theme={ blok.theme }
    iconTextList={ {
      items: blok.icon_text_list[0]?.items.map((item) => ({
        image: getStoryBlokImage(item.image, true, ImageMax.HALF_CONTAINER_SM),
        title: item.title,
      })),
      columns: blok.icon_text_list[0].columns,
    } }
    reviews={ {
      items: blok.reviews[0].items.map((item) => ({
        ...item,
        avatar: getStoryBlokImage(item.avatar, true, ImageMax.ICON_MD),
        hideTrustPilotStars: item.hide_trustpilot_stars || false,
      })),
    } }
  />
);
