import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";

import {
  SectionSummaryFuture,
} from "../../section/SectionSummaryFuture/SectionSummaryFuture";

import {
  IStoryBlokCondensedListItem,
  getStoryBlokCondensedListItems,
} from "./StoryBlokCondensedList";

interface IStoryBlokSummaryFuture {
  image: IStoryBlokImage;
  header_title: string;
  header_body_struck_out?: string;
  header_body: string;
  footer_title: string;
  footer_body_struck_out?: string;
  footer_body: string;
  footer_extra_info: string;
  list_label: string;
  list_items: IStoryBlokCondensedListItem[];
  multicat_list_items?: IStoryBlokCondensedListItem[];
}

export interface IStoryBlokSectionSummaryFuture {
  theme: Theme
  title: string;
  summary: [IStoryBlokSummaryFuture]
}

export const StoryBlokSummaryFuture = ({ blok }: IStoryBlokComponent<IStoryBlokSectionSummaryFuture>) => {
  const summary = blok.summary[0];
  return (
    <SectionSummaryFuture
      title={blok.title}
      theme={blok.theme}
      summary={{
        image: getStoryBlokImage(summary.image, true, ImageMax.CONTAINER_XL),
        headerTitle: summary.header_title,
        headerBodyStruckOut: summary.header_body_struck_out,
        headerBody: summary.header_body,
        footerTitle: summary.footer_title,
        footerBody: summary.footer_body,
        footerBodyStruckOut: summary.footer_body_struck_out,
        footerExtraInfo: summary.footer_extra_info,
        listLabel: summary.list_label,
        listItems: getStoryBlokCondensedListItems(summary.list_items),
        multicatListItems: getStoryBlokCondensedListItems(summary.multicat_list_items ?? []),
      }}
    />
  );
};
