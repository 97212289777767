import { FC } from "react";

import List from "../../base/List/List";

import TagListItem, { ITagListItemProps } from "./TagListItem/TagListItem";

export interface ITagListProps {
  items: ITagListItemProps[];
}

const TagList: FC<ITagListProps> = ({ items }) => (
  <List component={TagList.name} className="space-y-2">
    <>
      { items.map((item) => (
        <TagListItem key={`${item.tag.children}-${item.text}`} {...item} align="center"/>
      )) }
    </>
  </List>
);

export default TagList;
