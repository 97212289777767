import { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import { Theme } from "apps/website/maps/Theme.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import IconCallout, {
  IIconCalloutProps,
} from "apps/website/components/feature/IconCallout/IconCallout";
import Container from "apps/website/components/layout/Container/Container";

import {
  TIconCalloutWidth,
  iconCalloutWidthMap,
} from "./SectionIconCallout.map";

export interface ISectionIconCallout {
  width?: TIconCalloutWidth;
  theme?: Theme;
  callout: IIconCalloutProps
}

const SectionIconCallout: FC<ISectionIconCallout> = ({ theme = "light", callout, width = "default" }) => (
  <Section component={SectionIconCallout.name} theme={theme} size="md">
    <Container>
      <Grid>
        <Column spans={iconCalloutWidthMap[width].spans} offset={iconCalloutWidthMap[width].offset}>
          <IconCallout {...callout} />
        </Column>
      </Grid>
    </Container>
  </Section>
);

export default SectionIconCallout;
